import {
  Alert,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  Grow,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Popper,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import Tabs from "@mui/material/Tabs";
import ShareIcon from "@mui/icons-material/Share";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import React, { useEffect, useRef, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import CandidateInfo from "./candidateInfo";
import { Container } from "@mui/material";
import CandidateEditableProfile from "./CandidateEditableProfile";
import { Padding } from "@mui/icons-material";
import axios from "axios";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import Header from "./header";
import JobAssociated from "./JobAssociated";
import Cookies from "js-cookie";
import AddToJobBtn from "./addToJobBtn";
import DeleteCanidateDrawer from "./deleteCandidateDrawer";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// # REACT_APP_BASEURL = https://companydev.jobeze.com/backend
// import {REACT_APP_BASEURL} from "../"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const OpenDrawer = () => {
  const { id } = useParams();
  const [newvalues, setnewvalue] = useState(0);
  const [value, setValue] = useState(0);
  const [candidatedetails, setcandidatedetails] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const resumeRef = useRef(null);
  const openDrawerId = useParams();

  const [openAddtoJob, setOpenAddtoJob] = useState(false);
  const [addToJobCandidateids, setAddToJobCandidateids] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [renderKey, setRenderKey] = useState(0);
  const IsEmployer = useSelector((state) => state.user.isEmployer);
  const role = useSelector((state) => state.user.role);
  const nav = useNavigate();

  const handleAddToJobOpen = () => {
    setOpenAddtoJob(true);
  };

  const handleViewResumeClick = () => {
    setValue(0);
    if (resumeRef.current) {
      resumeRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // const fetchingData = () => {
  //   let payload = {
  //     jobId: id,
  //   };
  //   axios
  //     .get(`/candidate-job-associated/${id}`, {
  //       headers: {
  //         authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
  //       },
  //     })
  //     .then((response) => {
  //       setJobAssociatedData(response.data.message);
  //       // console.log("144", response.data.message);
  //     });

  //   // setJobAssociatedData(response.data.message);
  //   // console.log("144", response);
  // };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    axios
      .post(
        `candidate/details/${id}?isEditableProfile=false`,
        {
          jobId: id,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        setValue(newvalues);
        setcandidatedetails(response.data.message);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        scrollToTop();
      });
  }, [id]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const copyToClipboard = async () => {
    const x = process.env.REACT_APP_FRONTEND_URL;

    try {
      await navigator.clipboard.writeText(`${x}/Manage-Candidates/${id}`);
      setSnackbarMessage("Copied");
      setAlertSeverity("success");
      setSnackbarOpen(true);

      // Close the Snackbar after a delay (e.g., 3 seconds)
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 1500); // Adjust the delay as needed
    } catch (err) {
      setSnackbarMessage("Failed to copy the link.");
      setAlertSeverity("error");
      setSnackbarOpen(true);
    }
  };

  let tabIndex = -1;
  let customTabIndex = -1;
  const PermissionStatus = useSelector((state) => state.user.permissions);
  const addCandidatePermission = PermissionStatus["100"];

  //delete candidate functionality from here starting
  const [open, setOpen] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);

  const handleAcknowledgeChange = (event) => {
    setAcknowledged(event.target.checked);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    // Implement your delete functionality here
    try {
      axios.get(`/delete-candidate/${openDrawerId?.id}`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      setSnackbarMessage("Candidate Deleted Successfully");
      setAlertSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => {
        nav("/Manage-Candidates?page=1");
      }, 800);
    } catch (err) {
      console.log(err);
      setSnackbarMessage("Candidate Deletion Failed");
      setAlertSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setOpen(false);
      setAcknowledged(false);
    }
  };
  const handleBack = () => {
    const previousUrl = sessionStorage.getItem("manageCandidatepreviousUrl");
    if (previousUrl) {
      // Parse the URL
      const url = new URL(previousUrl, window.location.origin); // Ensure URL is absolute
      const path = url.pathname; // Extract path
      const queryParams = url.search; // Extract query parameters

      // Navigate using path and query parameters
      nav(`${path}${queryParams}`);

      // Clean up
      sessionStorage.removeItem("manageCandidatepreviousUrl");
    } else {
      nav("/Manage-Candidates?page=1");
    }
  };

  return (
    <Box className="pageWrapper">
      <FormControl
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box sx={{ display: "flex", gap: 3, alignItems: "start" }}>
            <Box onClick={handleBack}>
              <ArrowBackIosIcon sx={{ mt: 3.4, cursor: "pointer" }} />
            </Box>
            <Typography variant="body1">
              {candidatedetails.profile_path ? (
                <img
                  src={candidatedetails.profile_path}
                  style={{
                    borderRadius: "16px",
                    width: "100px",
                    height: "100px",
                  }}
                  alt="avatar"
                />
              ) : (
                <Avatar
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: "16px",
                  }}
                />
              )}
            </Typography>
            <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
              <Typography variant="h4" sx={{ wordWrap: "break-word" }}>
                {candidatedetails.name ? candidatedetails.name : "NA"}
              </Typography>
              <Typography
                variant="h6"
                sx={{ wordWrap: "break-word" }}
                gutterBottom
              >
                {candidatedetails.current_employer
                  ? candidatedetails.current_employer
                  : "NA"}
              </Typography>
              <Typography
                variant="body1"
                color={"text.secondary"}
                className="fw-500"
                gutterBottom
                sx={{ wordWrap: "break-word" }}
              >
                {candidatedetails.current_loc
                  ? candidatedetails?.current_loc[0] &&
                    candidatedetails.current_loc[0]?.label
                  : "NA"}
              </Typography>
              <Typography
                variant="body1"
                color={"text.secondary"}
                className="fw-500"
                gutterBottom
              >
                Created By:{" "}
                <span style={{ color: "black" }}>
                  {/* {selectedRowData?.entered_by} */}
                  {candidatedetails.entered_by}
                </span>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  my: 2,
                }}
              >
                <Typography variant="body1" color={"text.primary"}>
                  <a
                    href={"mailto:" + candidatedetails.email}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button
                      size="small"
                      className="fw-700"
                      variant="text"
                      startIcon={<EmailOutlinedIcon />}
                    >
                      Email
                    </Button>
                  </a>
                </Typography>
                {candidatedetails?.cover_letter !== null &&
                  candidatedetails?.cover_letter?.trim() !== "" && (
                    <Typography variant="body1" color={"text.primary"}>
                      <a href="#coverletterscrol">
                        <Button
                          size="small"
                          className="fw-700"
                          variant="text"
                          startIcon={<InsertDriveFileOutlinedIcon />}
                        >
                          Cover Letter
                        </Button>
                      </a>
                    </Typography>
                  )}

                <Typography variant="body1" color={"text.primary"}>
                  <a href="#resumescrol">
                    <Button
                      id="viewResumeButton"
                      size="small"
                      className="fw-700"
                      variant="text"
                      startIcon={<InsertDriveFileOutlinedIcon />}
                      onClick={handleViewResumeClick}
                    >
                      View Resume
                    </Button>
                  </a>
                </Typography>
                <Typography variant="body1" color={"text.primary"}>
                  <a
                    href={
                      candidatedetails.linkedIn
                        ? candidatedetails.linkedIn
                        : null
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button
                      size="small"
                      className="fw-700"
                      variant="text"
                      startIcon={<LinkOutlinedIcon />}
                    >
                      Linkedin
                    </Button>
                  </a>
                </Typography>
                {/*  */}

                <Typography variant="body1" color={"text.primary"}>
                  <a>
                    <Button
                      size="small"
                      className="fw-700"
                      variant="text"
                      onClick={copyToClipboard}
                      startIcon={<ShareIcon />}
                    >
                      Share Profile
                    </Button>
                  </a>
                  <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Alert
                      onClose={handleSnackbarClose}
                      severity={alertSeverity}
                      variant="filled"
                    >
                      {snackbarMessage}
                    </Alert>
                  </Snackbar>
                </Typography>

                {/* <Typography variant="body1" color={"text.primary"}>
                
                <ShareIcon
                  onClick={copyToClipboard}
                  sx={{ color: "rgba(0, 0, 0, 0.6)", mx: 2, cursor: "pointer" }}
                />
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={4000}
                  onClose={handleSnackbarClose}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <Alert
                    onClose={handleSnackbarClose}
                    severity={alertSeverity}
                    variant="filled"
                  >
                    {snackbarMessage}
                  </Alert>
                </Snackbar>

              </Typography> */}
                {/*  */}
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ paddingTop: 2 }}>
            {addCandidatePermission?.add === 1 && (
              <Button
                variant="contained"
                size="large"
                sx={{ marginRight: 2 }}
                // disabled={!isCheckboxSelected}
                onClick={handleAddToJobOpen}
              >
                Add to Job
              </Button>
            )}
            {candidatedetails.entered_by !== "Jobeze Admin" &&
              (candidatedetails?.can_edit &&
              addCandidatePermission?.delete === 1) ? (
                <>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleOpen}
                    startIcon={<DeleteOutlineOutlinedIcon />}
                  >
                    Delete Candidate
                  </Button>
                  <DeleteCanidateDrawer
                    open={open}
                    handleClose={handleClose}
                    handleDelete={handleDelete}
                    candidatename={candidatedetails.name}
                    acknowledged={acknowledged}
                    handleAcknowledgeChange={handleAcknowledgeChange}
                  />
                </>
              ):<></>}
          </Box>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            className="fw-700"
            aria-label="basic tabs example"
          >
            <Tab label="Candidate Info" {...a11yProps(++tabIndex)} />

            {/* {!Shortlisted &&
              !activitydisable &&
              window.location.pathname !== "/searchCandidate" && (
                <Tab label="Activities" {...a11yProps(++tabIndex)} />
              )} */}
            <Tab label="Internal Profile" {...a11yProps(++tabIndex)} />
            {/* {!Shortlisted &&
              responsehide &&
              window.location.pathname !== "/searchCandidate" && (
                <Tab label="Response" {...a11yProps(++tabIndex)} />
              )} */}
            <Tab label="Jobs Associated" {...a11yProps(++tabIndex)} />
          </Tabs>
        </Box>
        <div ref={resumeRef}>
          <CustomTabPanel value={value} index={++customTabIndex}>
            <CandidateInfo candidatedetails={candidatedetails} />
          </CustomTabPanel>
        </div>
        <CustomTabPanel value={value} index={++customTabIndex}>
          <CandidateEditableProfile
            candidateId={openDrawerId?.id}
            // candidateId={candidatedetails.candidate_id}
            // onClose={onClose}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={++customTabIndex}>
          {/* <DataGrid rows={row} columns={columns} sx={{ margin: "20px 0" }} /> */}

          {/*  */}
          <Box sx={{ mt: 3, width: "100%", display: "flex" }}>
            <JobAssociated />
            {/* <DataGrid
                rows={jobAssociatedData}
                columns={columns}
                // onRowClick={HandleRowClick}
              /> */}
          </Box>
          {/*  */}
        </CustomTabPanel>
      </FormControl>
      <AddToJobBtn
        openAddtoJob={openAddtoJob}
        setRenderKey={setRenderKey}
        setOpenAddtoJob={setOpenAddtoJob}
        addToJobCandidateids={[Number(id)]}
        setSelectedRows={setSelectedRows}
        setAddToJobCandidateids={setAddToJobCandidateids}
      />
    </Box>
  );
};
export default OpenDrawer;
