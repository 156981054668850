import { Box, Container, Typography } from "@mui/material";
import { handleDate } from "../utiles/handler";
import { useEffect, useState } from "react";
import axios from "axios";

const BranchInfo = ({ branchInfo }) => {
  const [states, setStates] = useState([]);

  const fetchStates = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_JOBEZEURL + "/state-city/list",
        {}
      );
      setStates(response?.data?.message || []);
    } catch (error) {
      console.error("Error fetching state-city data", error);
    }
  };

  useEffect(() => {
    fetchStates();
  }, []);

  return (
    <Box sx={{ display: "flex" }} flexDirection={"column"} gap={2}>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          Name
        </Typography>
        <Typography sx={{ textTransform: "capitalize" }}>
          {branchInfo?.name || "NA"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          City
        </Typography>
        <Typography sx={{ textTransform: "capitalize" }}>
          {branchInfo?.city || "NA"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          State
        </Typography>
        <Typography sx={{ textTransform: "capitalize" }}>
          {branchInfo?.state || "NA"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          Contact Number
        </Typography>
        <Typography>
          {branchInfo?.phone ? "+1 " + branchInfo?.phone : "NA"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography
          fontWeight={500}
          width={{ xs: 200, sm: 150, md: 120, lg: 120 }}
          sx={{ color: "gray" }}
        >
          Address
        </Typography>
        <Typography>
          {branchInfo && branchInfo.addressLine1 ? (
            <>
              {branchInfo.addressLine1}
              {branchInfo.addressLine2 && `, ${branchInfo.addressLine2}`}
              {", "}
              {branchInfo.city}, {branchInfo.state}
              {branchInfo.zip && ` (${branchInfo.zip})`}
            </>
          ) : (
            "NA"
          )}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          Created On
        </Typography>
        <Typography>
          {branchInfo.date_created
            ? handleDate(branchInfo?.date_created, "MM/DD/YYYY")
            : "NA"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        // mt={branchInfo?.notes ? "-16px" : "0px"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          Additional Info
        </Typography>
        <Typography>
          {branchInfo ? (
            branchInfo?.notes ? (
              <span dangerouslySetInnerHTML={{ __html: branchInfo?.notes }} />
            ) : (
              "NA"
            )
          ) : (
            "NA"
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default BranchInfo;
