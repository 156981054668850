import { Chip, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";

const CustomChips = ({ chipItems, handleClick, selectedChip }) => {
  const nav = useNavigate();

  const getTitle = () => {
    switch (selectedChip) {
      case "Candidates":
        return "Candidates";
      case "Jobs":
        return "Total Jobs";
      default:
        return "";
    }
  };

  const titleColor = (selectedChip === "Candidates" || selectedChip === "Jobs") ? '#006CB7' : 'inherit';

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      spacing={3}
      alignItems="center"
      sx={{
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "10px 20px",
      }}
    >
      <ArrowBackIosIcon onClick={() => nav("/mydashboard")} />
      <Typography variant="body1" sx={{
        color: "#737791",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      }}>Back</Typography>
      <Divider orientation="vertical" flexItem />
      <Typography variant="h6" sx={{
        color: titleColor,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      }}>
        <span style={{ color: "#737791" }}>Dashboard / </span> {" "}{getTitle()}
      </Typography>

    </Stack>
  );
};

export default CustomChips;
