import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grow,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import SearchIcon from "@mui/icons-material/Search";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddCandidateDrawer from "./addCandidateDrawer";
import CandidateDetails from "./candidateDetails";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { generate_filter_condition } from "../utiles/filter";
import _debounce from "lodash/debounce";
import { onGlobalFliterFunc } from "../utiles/onFilterChange";
import AdvanceFilter from "./advanceFilter";
import Cookies from "js-cookie";



// const baseURL = process.env.REACT_APP_BASEURL;
const statusColors = {
  Applied: "primary",
  "Employer Applied": "primary",
  Shortlisted: "success",
  "Tech Evaluation": "warning",
  "Manager Evaluation": "info",
  "HR Round": "info",
  "Offer Released": "success",
  Withdrawn: "error",
  Rejected: "error",
};

const verifiedColors = {
  1: "success",
  0: "error",
};
const columns = [
  // {
  //   field: "id",
  //   headerName: "ID",
  //   width: 90,
  // },
  {
    field: "fullname",
    headerName: "Full Name",
    filterable: false,
    width: 240,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        title={params.row.fullname}
      >
        {" "}
        {params.row.fullname || "NA"}{" "}
      </Typography>
    ),
  },
  {
    field: "entered_by",
    headerName: "Created By",
    width: 200,
    filterable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === "contains" ||
        operator.value === "equals" ||
        operator.value === "isAnyOf"
    ),
  },
  {
    filterable: false,
    field: "created_at",
    headerName: "Date Created",
    width: 200,
    renderCell: (params) =>
      new Date(params.row.created_at).toLocaleDateString("en-US"),
  },
  {
    field: "current_location",
    headerName: "Location",
    filterable: false,
    width: 200,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        title={params.row.current_location}
      >
        {params.row.current_location || "NA"}
      </Typography>
    ),
  },
  {
    field: "date_created",
    headerName: "Shortlisted On",
    filterable: false,
    width: 200,
    renderCell: (params) =>
      new Date(params.row.date_created).toLocaleDateString("en-US"),
  },
  {
    field: "isVerified",
    headerName: "Verfication Status",
    filterable: false,
    width: 200,
    renderCell: (params) => (
      <Typography>
        <Chip
          size="small"
          label={params.row.isVerified ? "Verified" : "Unverified"}
          variant="outlined"
          color={verifiedColors[params.row.isVerified] || "default"}
        />
      </Typography>
    ),
  },

  {
    field: "last_active",
    headerName: "Last Active On",
    filterable: false,
    width: 150,
    // sortable: false,
    renderCell: (params) => (
      <Typography
        title={params.row.last_active}
        variant="body2"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {params.row.last_active || "NA"}
      </Typography>
    ),
  },
];

const rows = [
  {
    id: 1,
    title: "Job Title",
    type: "Job Type",
    status: "Status",
    date_created: "Created",
    count: "Application",
    count2: "Last Activity",
  },
  {
    id: 2,
    title: "Job Title",
    type: "Job Type",
    status: "Status",
    date_created: "Created",
    count: "Application",
    count2: "Last Activity",
  },
  {
    id: 3,
    title: "Job Title",
    type: "Job Type",
    status: "Status",
    date_created: "Created",
    count: "Application",
    count2: "Last Activity",
  },
  {
    id: 4,
    title: "Job Title",
    type: "Job Type",
    status: "Status",
    date_created: "Created",
    count: "Application",
    count2: "Last Activity",
  },
  {
    id: 5,
    title: "Job Title",
    type: "Job Type",
    status: "Status",
    date_created: "Created",
    count: "Application",
    count2: "Last Activity",
  },
  {
    id: 6,
    title: "Job Title",
    type: "Job Type",
    status: "Status",
    date_created: "Created",
    count: "Application",
    count2: "Last Activity",
  },
];

const Applicants = (props) => {
  const PermissionStatus = useSelector((state) => state.user.permissions);
  const addCandidatePermission = PermissionStatus["100"];
  const { id } = useParams();
  const [candidatedrawerflag, setcandidatedrawerflag] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [applicants, setApplicantsdata] = useState([]);
  const getToken = useSelector((state) => state.user.token);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [status, setstatus] = useState([]);
  const [isApplicanOpen, setApplicanOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [candidateid, setCandidateId] = useState([]);
  const [checkboxSelected, setcheckboxSelected] = useState(true);
  const [verifiedstatus, setverifiedstatus] = useState("");
  const [refreshagain, setrefreshagain] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
  const InActiveStatus = ["Cancelled", "Closed", "Full"];
  const [opennew, setOpennew] = React.useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 18,
  });
  const [rerenderKey, setRerenderKey] = useState(0);
  const [searchTermPrev, setSearchTermPrev] = useState("");
  const [queryOptions, setQueryOptions] = useState({});
  const [filterOptions, filtersetQueryOptions] = React.useState({
    filterModel: null,
  });
  const [datemodified, setdatemodified] = useState("");
  const [candidateIdDate, setCandidateIdDate] = useState([]);
  const [showLoader, setShowLoader] = useState(false);


  // advance filter function variables start
  const [FilterHitting, setFilterHitting] = useState(false);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [question, setQuestion] = useState([]);
  const [quesAnsArrayFilter, setQuesAnsArrayFilter] = useState([]);
  const [current, setCurrent] = useState([]);
  //const [myArray, setArray] = useState([]);
  const [textFields, setTextFields] = useState({});
  const [radioValues, setRadioValues] = useState({});
  const [operatorValues, setOperatorValues] = useState({});
  const [checkboxValues, setCheckboxValues] = useState({});
  const [checkedCount, setCheckedCount] = useState(0);
  const [isReset, setIsReset] = useState(false);


  //
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [createdby, setCreatedby] = useState("");
  const [newData, setNewData] = useState([]);
  const [cancelData, setCancelData] = useState(false);
  const [username, setUsername] = useState("");
  {/* Student Data Random */ }
  const getUserData = async () => {

    const candidateOwnerList = await axios.get(`active-users`, {
      headers: {
        authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"))
      },
    })
    setCandidateList(candidateOwnerList.data.message)
    setUsername("Select User Name");
  }
  useEffect(() => {
    getUserData()
  }, [])

  const handleSelectionData = (event, value, reason) => {
    setSelectedOptions(value);
    setSelectedCount(value.length);

    const usernames = value?.map((item) => item.name);
    const filterCondition = generate_filter_condition("and candidate_info.entered_by", "isAnyOf", usernames)

    if(usernames.length > 0) {
      setCreatedby(filterCondition)
    }
    else {
      setCreatedby("");
    }
    if (reason === "clear") {
      setCreatedby("");
      setCancelData(true);
    }
    if (reason === "removeOption") {
      // setCreatedby("");
      setCancelData(true);
    }
  };

  const handleCheckboxChange = (e, id, index) => {
    const checked = e.target.checked;
    setCheckboxValues((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    let tempArr = [...quesAnsArrayFilter];
    tempArr[index].isChecked = checked;
    if (checked) {
      setQuesAnsArrayFilter(tempArr);
    } else {
      tempArr[index].ans_text = "";
      tempArr[index].errorText = null;
      const updatedTextFields = { ...textFields };
      updatedTextFields[id] = "";
      setTextFields(updatedTextFields);
      const updatedRadioValues = { ...radioValues };
      updatedRadioValues[id] = "";
      setRadioValues(updatedRadioValues);
      setQuesAnsArrayFilter(tempArr);
    }
    const countChecked = quesAnsArrayFilter.filter(
      (item, index) => item.isChecked
    ).length;
    setCheckedCount(countChecked);
    // if (!checked) {
    //   setTextFields({});
    //   setRadioValues({});
    // }
  };

  const handleOperatorValuesChange = (e, id, index) => {
    const { value } = e.target;
    setOperatorValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    let tempArr = [...quesAnsArrayFilter];
    tempArr[index].operator = value;
    setQuesAnsArrayFilter(tempArr);
  };

  const temp = (value, type) => {
    if (type === "tel") {
      if (value.length === 0) {
        return "This field is required";
      } else if (value.trim().length >= 13) {
        return "Maximum 12 digits allowed";
      } else {
        return null;
      }
    }
  };

  // const handleTextFieldChange = (e, id, index) => {
  //   const { value, type } = e.target;
  //   const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
  //   setTextFields((prevState) => ({
  //     ...prevState,
  //     [id]: capitalizedValue,
  //   }));

  //   let tempArr = [...quesAnsArrayFilter];
  //   if (tempArr[index].isChecked === true) {
  //     tempArr[index].ans_text = capitalizedValue;
  //     tempArr[index].operator = quesAnsArrayFilter[index].operator
  //       ? quesAnsArrayFilter[index].operator
  //       : "=";
  //     tempArr[index].errorText = temp(capitalizedValue, type);
  //   } else {
  //     tempArr[index].errorText = null;
  //     tempArr[index].operator = "";
  //     tempArr[index].ans_text = "";
  //   }
  //   setQuesAnsArrayFilter(tempArr);
  // };

  // const handleRadioChange = (e, id, index) => {
  //   const { value } = e.target;
  //   // console.log(e, "value");
  //   setRadioValues((prevState) => ({
  //     ...prevState,
  //     [id]: value,
  //   }));
  //   let tempArr = [...quesAnsArrayFilter];

  //   if (tempArr[index].isChecked === true) {
  //     tempArr[index].operator = quesAnsArrayFilter[index].operator
  //       ? quesAnsArrayFilter[index].operator
  //       : "";
  //     tempArr[index].errorText = null;
  //     tempArr[index].ans_text = value;
  //   } else {
  //     tempArr[index].errorText = null;
  //     tempArr[index].operator = "=";
  //     tempArr[index].ans_text = "";
  //   }
  //   setQuesAnsArrayFilter(tempArr);
  // };

  const handleAdvanceFilterOpen = () => {
    // if (isAppliedAdvanceFilter) {
    //   let temp = [...quesAnsArrayFilter]
    //   setQuesAnsArrayFilter(temp);
    //   console.log(quesAnsArrayFilter, "temp");
    //   setAdvanceFilter(true);
    // }
    setNewData(selectedOptions);
    let mytemp = current.map((q) => {
      //   let temp = [...quesAnsArrayFilter]
      return { ...q }
    })
    const updatedValues = {};
    const updatedText = {};
    const updatedRadioValues = {};
    const updatedOperator = {};
    mytemp.forEach(question => {
      updatedText[question.question_id] = question.ans_text;
      updatedRadioValues[question.question_id] = question.ans_text;
      updatedOperator[question.question_id] = question.operator;
      if (question.isChecked !== undefined) {
        updatedValues[question.question_id] = question.isChecked;
      }
    });
    setOperatorValues(prevOperatorValues => ({
      ...prevOperatorValues,
      ...updatedOperator
    }))
    setRadioValues(prevRadioValues => ({
      ...prevRadioValues,
      ...updatedRadioValues
    }));
    setTextFields((prevState) => ({
      ...prevState,
      ...updatedText,
    }));
    setCheckboxValues(prevCheckboxValues => ({
      ...prevCheckboxValues,
      ...updatedValues
    }));
    //   setQuesAnsArrayFilter(temp);
    setQuesAnsArrayFilter([...mytemp]);
    //   console.log(quesAnsArrayFilter, "temp");
    //   setAdvanceFilter(true);
    // }
    if (cancelData) {
      setSelectedCount(newData.length);
    }
    setAdvanceFilter(true);
  };

  const handleAdvanceFilterClose = () => {



    setAdvanceFilter(false);

    //

    selectedOptions.length > 0 || cancelData ? setSelectedOptions(newData) : setSelectedOptions([]);
    // setrefreshagain(!refreshagain);
  };

  const handleFilterReset = () => {
    setTextFields({});
    setRadioValues({});
    setCheckboxValues({});
    setCheckedCount();
    const a = quesAnsArrayFilter.map((question) => {
      return {
        type_id: question.type_id,
        question_id: question.question_id,
        ans_text: "",
        errorText: null,
        operator: "=",
        isChecked: false,
      };
    });
    // console.log(a, "a");
    setQuesAnsArrayFilter(a);
    setIsReset(true);
    setCurrent(a);
    setAdvanceFilter(false);
    setFilterHitting(false);
    setrefreshagain(!refreshagain);

    //
    setSelectedOptions([]);
    setCreatedby("");
  };

  const validate = () => {
    let isValid = true;
    let updateArray = [...quesAnsArrayFilter];

    updateArray.forEach((item, index) => {
      // console.log(item.isChecked, "item");
      if (item.type_id === 2) {
        if (item.isChecked === true) {
          if (item.ans_text === "") {
            item.errorText = "This field is required";
            isValid = false;
          } else if (item.ans_text.length > 12) {
            item.errorText = "Maximum 12 digits allowed";
            isValid = false;
          } else {
            item.errorText = null;
          }
        }
      } else if (item.type_id === 3) {
        if (item.isChecked === true) {
          if (item.ans_text === "") {
            item.errorText = "Please select an option";
            isValid = false;
          } else {
            item.errorText = null;
          }
        }
      } else {
        item.errorText = null;
      }
    });
    setQuesAnsArrayFilter(updateArray);
    // console.log(isValid, "***********")
    return isValid;
  };

  const handleAdvanceFilterApply = () => {
    setAdvanceFilter(!validate());
    let error = 0;
    let updateArray = [...quesAnsArrayFilter];
    updateArray.forEach((item, index) => {
      if (item.isChecked) {
        if (item.type_id === 2) {
          if (item.ans_text === "") {
            error = 1;
            item.errorText = "This field is required";
          } else {
            item.errorText = null;
          }
        } else if (item.type_id === 3) {
          if (item.ans_text === "") {
            error = 1;
            item.errorText = "Please select an option";
          } else {
            item.errorText = null;
          }
        }
      }
    });
    if (!error) {
      let mytemp = quesAnsArrayFilter.map((q) => {
        return { ...q }
      })
      const countChecked = quesAnsArrayFilter.filter((item, index) => item.isChecked).length;
      //setCheckedCount(countChecked);
      if (selectedOptions.length || newData.length > 0) {
        setCheckedCount(countChecked + 1);
      }
      else {
        setCheckedCount(countChecked);
      }
      //
      setCurrent([...mytemp])
      setFilterHitting(true);
      setFilterHitting(true);
      setrefreshagain(!refreshagain);
      setrefreshagain(!refreshagain);
    }
    // handleSnackbarOpen(snackbarMessage => "Filter Applied");
  };

  // const questionsrequired = props.questionrequired

  const handleSortModelChange = React.useCallback((sortModel) => {
    // Here you save the data you need from the sort model
    // console.log('mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm', sortModel, "2222222");
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  // const onFilterChange = React.useCallback((filterModel) => {
  //   // Here you save the data you need from the filter model
  //   setQueryOptions({ filterModel: { ...filterModel } });
  // })

  // console.log("filterOptions",filterOptions.filterCondition)

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // console.log("********************************",props.questionrequired)
  // console.log("datemodified",datemodified)
  const handleSnackbarOpen = (message) => {
    // console.log("Snackbar message:", message);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };



  const onFilterChange = React.useCallback((filterModel) => {
    const respFilterData = onGlobalFliterFunc(filterModel, `and concat(u.first_name, ' ', u.last_name)`);
    if (respFilterData) {
      filtersetQueryOptions(respFilterData);
    }
  }, []);

  useEffect(() => {
    setShowLoader(true);
    let payload = {
      jobId: id,
      isShortlisted: 1,
      // filterCriteria: filterOptions?.filterCondition
      //   ? filterOptions?.filterCondition
      //   : "",
      filterCriteria: createdby ? createdby : filterOptions.filterCondition ? filterOptions.filterCondition : "",
    };
    if (queryOptions.sortModel && queryOptions.sortModel.length > 0) {
      payload.sortField = queryOptions?.sortModel[0].field;
      payload.sortBy = queryOptions?.sortModel[0].sort;
    }

    // if(filterOptions && filterOptions.filterCondition) {
    //   payload.filterCriteria = filterOptions.filterCondition
    // }

    axios
      .post(
        `job-applicants/${searchTermPrev && searchTermPrev !== searchTerm
          ? 1
          : paginationModel.page + 1
        }${searchTerm ? "?search=" + searchTerm : ""}`,
        payload,
        {
          headers: {
            authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
          },
        }
      )
      .then((response) => {
        const filteredApplicants = response.data.message.data.filter(
          (applicant) => applicant.id !== null
        );
        setApplicantsdata(filteredApplicants);
        setShowLoader(false);
        setSearchTermPrev(searchTerm);
        setTotalCount(response.data.message.page);
        //
        setIsReset(false);
      })
      .catch((err) => { });
  }, [
    isDrawerOpen,
    open,
    refreshagain,
    isDialogOpen,
    paginationModel,
    queryOptions,
    searchTerm,
    isApplicanOpen,
    filterOptions,
  ]);

  // useEffect(() => {
  //   axios
  //     .get(`candidate/job-status`, {
  //       headers: {
  //         authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
  //       },
  //     })
  //     .then((response) => {
  //       setstatus(response.data.message);
  //       // console.log("hello", response.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const HandleRowClick = (params, event) => {
    // console.log("fatima",params.row);
    setverifiedstatus(params.row.isVerified);
    setSelectedRowData(params.row);
    setApplicanOpen(true);
  };

  const filteredApplicants = applicants.filter((row) =>
    Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleSelection = (params, event) => {
    // console.log("1111111111111111111111", params)
    const rowData = filteredApplicants.filter((e) => params.includes(e.id));
    const newdata = rowData.map((e) => {
      return {
        id: e.id,
        date_modified: e.date_modified,
      };
    });
    // console.log(rowData);
    // console.log("newdata")
    setcheckboxSelected(true);
    setIsCheckboxSelected(!!params.length);
    setCandidateId(params);
    setCandidateIdDate(newdata);
  };

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`, "test");
  };
  const saveCommentAndStatus = () => {
    // console.log("selectedStatus", selectedStatus, selectedIndex);
    axios
      .post(
        `change-candidate-job-status`,
        {
          candidate_ids: candidateIdDate,
          job_id: id,
          status: selectedStatus,
          comment: comment,
        },
        {
          headers: {
            authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          handleSnackbarOpen("Candidate Status Changed Successfully");
          closeDialog();
        }
      })
      .catch((err) => {
        handleSnackbarOpen(err.response.data.error);
        closeDialog();
      });
  };

  const handleMenuItemClick = (event, index, item) => {
    setSelectedIndex(index);
    openDialog();
    setSelectedStatus(item);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleOnClose = () => {
    setApplicanOpen(false);
    setOpennew((prev) => !prev);
  };

  const options = [
    "Create a merge commit",
    "Squash and merge",
    "Rebase and merge",
  ];
  // console.log("feukfuke", selectedIndex);

  // const checkDisabled = () => {
  //   const newArr = filteredApplicants.filter(e => candidateid.includes(e.id));
  //   const isFound = newArr.filter((e) => e.status !== "Withdrawn");
  //   return isFound.length > 0 ? false : true;
  // }

  const [showCustomMessage, setShowCustomMessage] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowCustomMessage(true);
    }, 300); // Adjust the delay time as needed (in milliseconds)

    // Clear the timeout if the component unmounts or the delay changes
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  const localeText = showCustomMessage
    ? { noRowsLabel: "No Rows" }
    : { noRowsLabel: "" };

  return (
    <div>
      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          sx={{ width: "25ch" }}
          type="search"
          placeholder="Search..."
          id="standard-search"
          variant="standard"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          {/* <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
              disabled={!isCheckboxSelected || props.status === "Closed" || props.status === "Cancelled" || props.status === "Full"}
            >
              <Button size="small" variant="outlined" onClick={handleClick}>
                Status{" "}
              </Button>
              <Button
                size="small"
                variant="outlined"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup> */}
          {/* <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
  
                        {status.map((option, index) => (
                          <MenuItem
                            key={option.status}
                            value={option.status}
                            disabled={checkDisabled()}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index, option.status)
                            }
                          >
                            {option.status}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper> */}

          {/* Dialog box open here  */}

          {/* <Dialog open={isDialogOpen} fullWidth>
              <DialogTitle>Change Status</DialogTitle>
              <Divider />
              <DialogContent>
                <Typography variant="body1" className="fw-500" gutterBottom >Add Comment</Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Comment"
                  variant="outlined"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </DialogContent>
              <Divider />
              <Typography style={{ padding: "16px" }} display={"flex"} justifyContent={"flex-end"} >
                <Button
                  variant="text"
                  color="primary"
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  onClick={saveCommentAndStatus}
                >
                  Save
                </Button>
              </Typography>
            </Dialog> */}

          {
            addCandidatePermission?.add === 1 && (
              <Button
                variant="contained"
                size="small"
                startIcon={<PersonAddIcon />}
                onClick={() => setDrawerOpen(true)}
                disabled={props.status === "Closed" || props.status === "Cancelled" || props.status === "Full"}
              >
                {" "}
                Add Candidate{" "}
              </Button>
            )
          }
          <Button
            variant="contained"
            size="small"
            onClick={handleAdvanceFilterOpen}
            // disabled={
            //   props.status === "Closed" ||
            //   props.status === "Cancelled" ||
            //   props.status === "Full"
            // }
            startIcon={<FilterAltIcon />}
            endIcon={
              <>
                {checkedCount > 0 ? (
                  <span
                    style={{
                      color: "#1976d2",
                      backgroundColor: "white",
                      borderRadius: "50px",
                      padding: "0px 7px",
                      paddingTop: "1px",
                      fontSize: "13px",
                    }}
                  >
                    {checkedCount}
                  </span>
                ) : null}
              </>
            }
          >
            Advance Filter</Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<AutoAwesomeIcon />}
            // onClick={() => setQueryOptions({sortModel:[
            //   {
            //     field: "recommend",
            //     sort: "desc",
            //   }
            // ]})}
            onClick={() => {
              // Update the rerender key first
              setRerenderKey((prevKey) => prevKey + 1);
              // Then set the sort model
              setQueryOptions({
                sortModel: [
                  {
                    field: "recommend",
                    sort: "desc",
                  },
                ],
              });
            }}
          >
            Top Recommendations
          </Button>
        </Box>
      </Box>
      <AdvanceFilter
        advanceFilter={advanceFilter}
        handleAdvanceFilterOpen={handleAdvanceFilterOpen}
        handleAdvanceFilterClose={handleAdvanceFilterClose}
        handleAdvanceFilterApply={handleAdvanceFilterApply}
        handleFilterReset={handleFilterReset}
        question={question}
        // setQuestion={setQuestion}
        textFields={textFields}
        // setTextFields={setTextFields}
        radioValues={radioValues}

        selectedCount={selectedCount}
        selectedOptions={selectedOptions}
        handleSelectionData={handleSelectionData}
        candidateList={candidateList}
        username={username}
      />
      <AddCandidateDrawer
        isDrawerOpen={isDrawerOpen}
        setDrawerOpen={setDrawerOpen}
        isAddToJob={true}
      />
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
          {
            display: "none",
          },
        }}
        keepNon
        rows={filteredApplicants}
        autoHeight
        columns={columns}
        key={rerenderKey}
        loading={showLoader}
        paginationMode="server"
        getRowId={(row) => row.id}
        onRowClick={HandleRowClick}
        //   checkboxSelection={checkboxSelected}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[18]}
        rowCount={totalCount}
        localeText={localeText}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={_debounce(onFilterChange, 500)}
        isRowSelectable={(params) => {
          if (
            params.row.isVerified === 0 ||
            params.row.status === "Withdrawn"
          ) {
            return false;
          }
          if (params.row.isVerified === 1) return true;
        }}
        onRowSelectionModelChange={handleSelection}
        disableRowSelectionOnClick
        className="data-grid-row-hover"
      />
      <CandidateDetails
        //   verifiedstatus={verifiedstatus}
        isOpen={isApplicanOpen}
        onClose={handleOnClose}
        setApplicanOpen={setApplicanOpen}
        setRerenderKey={setRerenderKey}
        //   opennew={opennew}
        //   setOpennew={setOpennew}
        selectedRowData={selectedRowData}
        //   setrefreshagain={setrefreshagain}
        //   refreshagain={refreshagain}
        candidatedrawerflag={candidatedrawerflag}
        Shortlisted={true}
        issearchnavbar={true}
        questionrequired={props.questionrequired}
        responsehide={props.questionrequired ? true : false}
        Jobstatus={props.status}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={
            snackbarMessage === "Candidate Status Changed Successfully"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Applicants;
