import React, { useEffect } from 'react'
import Cookies from 'js-cookie';
import { Box, Chip, FormControl, FormControlLabel, FormHelperText, FormLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Typography } from '@mui/material';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Companyselection = ({
  company,
  setCompany,
  selectedCompanies,
  setSelectedCompanies,
  companyRadioValue,
  // setCompanyRadioValue,
  handleCompanyChange,
  handleCompanyRadioChange,
  handleDeleteCompany,
  handleCompanySelectionError,
  companySelectError,
  selectedBranches,
  setSelectedBranches,
  selectedAdmin,
  // isCompanyTab,
  // setIsCompanyTab
}) => {

  const [searchParams,] = useSearchParams();


  const getCompanyLists = async () => {
    try {

      const response = await axios.post('/list-of-companies', { under_assigned_user_id: selectedAdmin }, {
        headers: {
          Authorization: Cookies.get('_secure_ARJ_') || Cookies.get('_secure_ref')
        }
      });
      const companies = response.data.message.companyListResponse;
      setCompany(companies);

      // setIsCompanyTab(true);

      // console.log(selectedCompanies,"selected");

      if (searchParams.get("tab") === "company" && searchParams.get("edit") === null && companyRadioValue === 'all') {
        // const filterCompanieswithBranches = response.data.message.companyListResponse.filter(company => company.is_branch_managed === 1);
        // const transformCompaniesToBranches = 
        // response.data.message.companyListResponse.map(company => ({
        //   company_id: company.id,
        //   company_name: company.name,
        //   branches: [-100],
        //   is_branch_managed: company.is_branch_managed
        // }));

        const transformCompaniesToBranches = response.data.message.companyListResponse.map((company, index) => {
          if (selectedBranches.findIndex(branch => branch.company_id === company.id) === -1) {
            return {

              company_id: company.id,
              company_name: company.name,
              branches: company.is_branch_managed === 1 ? [-100] : [],
              is_branch_managed: company.is_branch_managed
            }
          }
          else {
            return selectedBranches[index]
          }
        })

        setSelectedBranches(transformCompaniesToBranches);
      }

    } catch (error) {
      console.error("Error fetching company lists:", error);
      // You can also add more error handling logic here if needed
    }
  };

  useEffect(() => {
    getCompanyLists();
  }, []);

  return (
    <>
      <Box pb={1}>
        <Box display={"flex"} pb={1}>
          <Typography width={"30%"} variant="body1" fontWeight={500}>
            Company
          </Typography>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={companyRadioValue}
              onChange={handleCompanyRadioChange}
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="All"
                sx={{ color: "gray" }}
              />
              <FormControlLabel
                value="select"
                control={<Radio />}
                label="Select"
                sx={{ color: "gray" }}
              />
            </RadioGroup>
          </FormControl>
          {
            companyRadioValue === 'select' && (
              <Box width={"50%"}>
                <FormControl fullWidth error={companySelectError}>
                  <Select
                    displayEmpty
                    value={selectedCompanies}
                    renderValue={(selected) => (
                      <Typography>
                        Selected{" "}
                        {selected?.length > 0 ? `(${selected?.length})` : ""}
                      </Typography>
                    )}
                    multiple
                    size="small"
                    inputProps={{ "aria-label": "Without label" }}
                    input={<OutlinedInput />}
                    onChange={handleCompanyChange}
                    onBlur={handleCompanySelectionError}
                    MenuProps={{ PaperProps: { style: { maxHeight: 250 } } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#0096FF",
                        },
                        "&:hover fieldset": {
                          borderColor: "#0096FF",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#0096FF",
                        },
                      },
                    }}
                  >
                    {
                      company.map((item) => (
                        <MenuItem key={item.id} sx={{ textTransform: "capitalize" }} value={item} disabled={selectedCompanies.map((company) => company.id).includes(item.id)} >
                          {item.name}
                        </MenuItem>)
                      )
                    }
                  </Select>
                  {companySelectError && <FormHelperText>Please select at least one company.</FormHelperText>}
                </FormControl>
              </Box>
            )
          }
        </Box>
        {
          selectedCompanies.length > 0 && companyRadioValue === 'select' &&
          (
            <Box>
              <Box
                border={1}
                p={1.5}
                borderRadius={1.5}
                borderColor={"gray"}
                width={"65%"}
                marginLeft={"auto"}
              >
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent={"start"}
                  alignContent={"start"}
                  alignItems={"start"}
                  gap={1}
                >
                  {
                    selectedCompanies?.map((company, index) => (
                      <Chip
                        key={index}
                        label={company.name}
                        color="primary"
                        onDelete={() => handleDeleteCompany(company)}
                        sx={{
                          backgroundColor: "rgba(130, 130, 130, 1)",
                          borderRadius: 2,
                        }}
                      />
                    ))
                  }
                </Box>
              </Box>
            </Box>
          )
        }
      </Box>
    </>
  )
}

export default Companyselection