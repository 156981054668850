import React, { useEffect, useState } from "react";
import Header from "../components/header";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Alert,
  Paper,
  Tab,
  Tabs,
  Snackbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import CompanyInfo from "./companyInfo";
import CompanyBranches from "./companyBranches";
import { Link, useSearchParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "js-cookie";
import axios from "axios";
import { useSelector } from "react-redux";
import DeleteJobDialog from "./DeleteJobDialog";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(0.5),
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CompanyDetails = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [companyInfo, setCompanyInfo] = useState({});
  const { id } = useParams();
  // console.log("company_id", id);
  const [searchParams, setSearchParams] = useSearchParams({
    tab: "companyInfo",
  });
  const role = useSelector((state) => state.user.role);

  const getCompanyDetails = async () => {
    const response = await axios.get(`/company-info/${id}`, {
      headers: {
        authorization:
          Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
      },
    });
    // console.log("comapny response", response.data.message[0]);
    setCompanyInfo(response.data.message[0]);
  };
  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab === "companyInfo") {
      setValue(0);
    } else if (tab === "companyBranch") {
      setValue(1);
    }
  }, [searchParams]);

  useEffect(() => {
    getCompanyDetails();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setSearchParams({
        tab: newValue === 0 ? "companyInfo" : "companyBranch",
      });
    } else {
      setSearchParams({
        tab: newValue === 0 ? "companyInfo" : "companyBranch",
        page: 1,
      });
    }
  };

  const managePermissions = useSelector((state) => state?.user.permissions);

  const EditCompanyPermissions = managePermissions["200"];

  const IsEmployer = useSelector((state) => state.user.isEmployer);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [Snackbarserverity, setSnackbarServerity] = useState();

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleSnackbarOpen = (message) => {
    console.log("Snackbar message:", message);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleBack = () => {
    const previousUrl = sessionStorage.getItem('previousUrl');

    if (previousUrl) {
      // Parse the URL
      const url = new URL(previousUrl, window.location.origin); // Ensure URL is absolute
      const path = url.pathname; // Extract path
      const queryParams = url.search; // Extract query parameters

      // Navigate using path and query parameters
      navigate(`${path}${queryParams}`);

      // Clean up
      sessionStorage.removeItem('previousUrl');
    }
    else {
      navigate('/Company?page=1');
    }
  };
  return (
    <Box className="pageWrapper">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
        >
          <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
            <Box onClick={handleBack}>
              <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
            </Box>
            <Box>
              {companyInfo && companyInfo?.logo ? (
                <img
                  src={companyInfo.logo}
                  style={{
                    width: "100px",
                    height: "100px",
                    // border: "0.5px solid gray",
                    borderRadius: "6px",
                  }}
                  alt="Logo"
                />
              ) : (
                <Avatar
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: "6px",
                  }}
                />
              )}
            </Box>
            <Box sx={{ px: 3 }}>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {companyInfo.name || "NA"}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ textTransform: "capitalize" }}
                color={"gray"}
              >
                {companyInfo && companyInfo.city && companyInfo.state ? (
                  <>
                    {companyInfo.city}, {companyInfo.state}
                  </>
                ) : (
                  "NA"
                )}
              </Typography>
            </Box>
          </Grid>
          {(EditCompanyPermissions?.edit === 1 && companyInfo?.is_editable === 1) && (
            <Grid item xs={6}>
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                variant="h3"
                component="h1"
              >
                {searchParams.get("tab") === "companyInfo" && (
                  <Box display={"flex"} gap={2} alignItems={"center"}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => navigate(`/addCompany?c_id=${id}&edit=1`)}
                      startIcon={<EditIcon />}
                    >
                      Edit Company
                    </Button>
                  </Box>
                )}
                {searchParams.get("tab") === "companyInfo" &&
                  (EditCompanyPermissions?.delete === 1 && companyInfo?.is_editable === 1) && (
                    <Box display={"flex"} gap={2} alignItems={"center"}>
                      <Button
                        onClick={handleOpenDeleteDialog}
                        color="error"
                        variant="outlined"
                        sx={{ ml: 2 }}
                        startIcon={<DeleteOutlineOutlinedIcon />}
                      >
                        Delete Company
                      </Button>
                      <DeleteJobDialog
                        Deletename={companyInfo.name}
                        id={id}
                        open={openDeleteDialog}
                        handleClose={handleCloseDeleteDialog}
                        setOpenSnackbar={setOpenSnackbar}
                        setSnackbarMessage={setSnackbarMessage}
                        setSnackbarServerity={setSnackbarServerity}
                      />
                    </Box>
                  )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            textColor={value === 2 ? "secondary" : "primary"}
            indicatorColor={value === 2 ? "secondary" : "primary"}
            onChange={handleChange}
            aria-label="icon label tabs example"
          >
            <Tab
              label="Company Info"
              sx={{ letterSpacing: "1.25px", px: 4 }}
              {...a11yProps(0)}
            />
            {companyInfo.is_branch_managed === 1 && (
              <Tab
                label="Branches"
                sx={{ letterSpacing: "1.25px", px: 4 }}
                {...a11yProps(1)}
              />
            )}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <CompanyInfo companyInfo={companyInfo} />
        </CustomTabPanel>
        {companyInfo.is_branch_managed === 1 && (
          <CustomTabPanel value={value} index={1}>
            <CompanyBranches company_name={companyInfo.name} is_editable={companyInfo?.is_editable} />
          </CustomTabPanel>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={Snackbarserverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CompanyDetails;
