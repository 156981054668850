import React, { useEffect, useRef, useState } from "react";
import Header from "../components/header";
import { Container } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { styled } from "@mui/material/styles";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import CandidateDetails from "../components/candidateDetails";
import Zoom from "@mui/material/Zoom";
import _debounce from "lodash/debounce";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { generate_filter_condition } from "../utiles/filter";
import { onGlobalFliterFunc } from "../utiles/onFilterChange";
import CreatedByCheckbox from "../components/createdbycheckbox";
import Cookies from "js-cookie";

delete DataGrid["getRowParams"];

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
};

const SearchCandidate = () => {
  const PermissionStatus = useSelector((state) => state.user.permissions);
  const addCandidatePermission = PermissionStatus["100"];
  const [minexp, setMinexp] = useState("");
  const [maxexp, setMaxexp] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [jobOrder, setJobOrder] = useState("");
  const [inputValueJobOrder, setInputValueJobOrder] = useState("");
  const [addtoJob, setAddtoJob] = useState(false);
  const [isBooleanSearch, setIsBooleanSearch] = useState(false);
  const [preferredArr, setPreferredArr] = useState([]);
  const [getprefered, setprefcityLoc] = useState([]);
  const [preferredLocation, setpreferedLocation] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState([]);
  const [searchdata, setsearchdata] = useState([]);
  const [minsalary, setminsalary] = useState("");
  const [maxsalary, setmaxsalary] = useState("");
  const getToken = useSelector((state) => state.user.token);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [addToJobCandidateSelection, setAddToJobCandidateSelection] = useState(
    []
  );
  const [jobOrderOptions, setJobOrderOptions] = useState([]);
  const [joborderIds, setJoborderIds] = useState();
  const [isApplicanOpen, setApplicanOpen] = useState(false);
  const [bulkdata, setbulkdata] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [adddisbale, setadddisable] = useState(false);
  const [shimmer, setshimmer] = useState(false);
  const [faliurecount, setfaliurecount] = useState(0);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [Error, setError] = useState("");
  const [Error2, setError2] = useState("");
  const [ExpError, setExpError] = useState("");
  const [ExpError2, setExpError2] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [keepSelected, setKeepSelected] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [addtojobcount, setaddtojobcount] = useState(true);
  const [warning, setwarning] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
    page: pageNumber,
    pageSize: 25,
  });

  const [showLoader, setShowLoader] = useState(false);
  const [filterOptions, filtersetQueryOptions] = React.useState({
    filterModel: null,
  });
  //created by
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [candidateList, setcandidateList] = useState([]);
  const [createdby, setcreatedby] = useState("");
  const [username, setUsername] = useState("");
  const [data, setData] = useState([]);
  const paginationRange = useRef({ start: 1, end: 25 });
  const containerRef = useRef(null);
  //pagenumber previous and next
  const handleNext = () => {
    setPageNumber((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (pageNumber > 1) {
      setPageNumber((prev) => prev - 1);
    }
  };

  const getUserData = async () => {
    const candidateOwnerList = await axios.get(`active-users`, {
      headers: {
        authorization:
          Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
      },
    });
    setcandidateList(candidateOwnerList.data.message);
    setUsername("Select User Name");
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleSelectionData = (event, value, reason) => {
    setSelectedOptions(value);
    setSelectedCount(value.length);
    const usernames = value.map((item) => item.name);
    const filterCondition = generate_filter_condition(
      `and entered_by`,
      "isAnyOf",
      usernames
    );
    //console.log("filterCondition",filterCondition);
    if (usernames.length > 0) {
      setcreatedby(filterCondition);
    } else {
      setcreatedby("");
    }

    if (reason === "clear") {
      setcreatedby("");
      setSelectedOptions([]);
    }
  };

  const handleClose = () => {
    setAddtoJob(false);
    setbulkdata([]);
    setJobOrder("");
    setadddisable(false);
    setaddtojobcount(false);
  };

  const [queryOptions, setQueryOptions] = useState({});

  const HandleRowClick = (params, event) => {
    setSelectedRowData(params.row);
    setApplicanOpen(true);
  };
  const handleSortModelChange = React.useCallback((sortModel) => {
    setQueryOptions({ sortModel: [...sortModel] });
    // console.log("sortModel", sortModel);
  }, []);

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  // const handleSelection = (params, event ) => {
  //     setAddToJobCandidateSelection(params);
  //     setIsAnyCheckboxSelected(!!params.length);

  // };

  const handleSelection = (params, event) => {
    // const currentSelectedRows = addToJobCandidateSelection;
    if (params.length < 1) {
      setaddtojobcount(true);
    }
    if (params.length > 10) {
      setAddToJobCandidateSelection(params);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setwarning(true);
      if (containerRef.current) {
        containerRef.current.scrollIntoView({top: 0, behavior: "smooth" });
      }
      setaddtojobcount(false);
    }
    if (params.length > 0 && params.length <= 10) {
      setAddToJobCandidateSelection(params);
      // setIsAnyCheckboxSelected(!!params.length);
      setaddtojobcount(false);
      setwarning(false);
    }
  };

  const handleCloseDialog = () => {
    // Close the dialog

    setOpenDialog(false);
  };

  const handleOpen = (candidates) => {
    setAddtoJob(true);
    setaddtojobcount((prev) => prev + 1);
    setAddToJobCandidateSelection(candidates);
  };

  const handlereset = () => {
    // setPreferredArr([]);
    // setIsBooleanSearch(false);
    // setInputValue("");
    // setValue([]);
    // setMinexp("");
    // setMaxexp("");
    // setPreferredArr([]);
    // setIsBooleanSearch(false);
    // setValue([]);

    // localStorage.setItem("paramclear", false);
    // searchParams.set("keywords", "");
    // searchParams.set("min_exp", "");
    // searchParams.set("max_exp", "");
    // searchParams.set("location", "");
    // searchParams.set("booleanSearch", "");
    // searchParams.set("location", "");
    // searchParams.set("min_salary", "");
    // searchParams.set("max_salary", "");
    // searchParams.set("prev_search_id", "");
    // searchParams.set("id", "");
    // setSearchParams(searchParams);

    setPreferredArr([]);
    setIsBooleanSearch(false);
    setInputValue("");
    setValue([]);
    setMinexp("");
    setMaxexp("");
    setminsalary("");
    setmaxsalary("");
    setPreferredArr([]);
    setIsBooleanSearch(false);
    setValue([]);
    setError("");
    setError2("");
    setExpError("");
    setExpError2("");
    searchParams.set("keywords", "");
    searchParams.set("min_exp", "");
    searchParams.set("max_exp", "");
    searchParams.set("location", "");
    searchParams.set("booleanSearch", "");
    searchParams.set("location", "");
    searchParams.set("min_salary", "");
    searchParams.set("max_salary", "");
    searchParams.set("prev_search_id", "");
    searchParams.set("created_by", "");
    searchParams.delete("id");
    setSearchParams(searchParams);
    //
    setSelectedOptions([]);
    setPageNumber(1);
    setcreatedby("");
    setSearchTerm("");
    console.log(searchParams.get("keywords"), "adjlnajsld");

    // DataRendering();
  };

  const handleminsalary = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.substring(0, 10);
    setminsalary(limitedValue);
    setError2("");
  };

  const handlemaxsalary = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.substring(0, 10);
    setmaxsalary(limitedValue);
    setError("");
  };

  const let_id = Number(searchParams.get("id"));

  // useEffect(() => {
  //   localStorage.setItem("paramclear", handleparams);
  //   if (let_id === 1) {
  //     axios
  //       .get(`previous-search`, {
  //         headers: {
  //           authorization:
  //             Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
  //         },
  //       })
  //       .then((response) => {
  //         if (response.data.message) {
  //           let filteredArray = response.data.message.filter((data) => {
  //             return let_id === data.id;
  //           });

  //           const {
  //             booleanSearch,
  //             min_exp,
  //             max_exp,
  //             min_salary,
  //             max_salary,
  //             location,
  //             keywords,
  //           } = filteredArray[0];
  //           setMinexp(min_exp);
  //           setMaxexp(max_exp);
  //           setPreferredArr(location ? location.split(",") : []);
  //           setValue(keywords);
  //           setIsBooleanSearch(booleanSearch == 1 ? true : false);
  //           //setSelectedOptions(selectedOptions ? selectedOptions.split(",") : []);
  //           setminsalary(min_salary);
  //           setmaxsalary(max_salary);
  //         }
  //       })
  //       .catch((err) => {
  //         setShowLoader(false);
  //         console.log(err);
  //       });

  //     return;
  //   }
  //   setMinexp(
  //     localStorage.getItem("paramclear")
  //       ? searchParams.get("min_exp")
  //       : searchParams.get("")
  //   );
  //   setMaxexp(
  //     localStorage.getItem("paramclear")
  //       ? searchParams.get("max_exp")
  //       : searchParams.get("")
  //   );
  //   setPreferredArr(
  //     searchParams.get("location")
  //       ? searchParams.get("location").split(",")
  //       : []
  //   );
  //   setSelectedOptions(
  //     searchParams.get("created_by")
  //       ? JSON.parse(decodeURIComponent(searchParams.get("created_by")))
  //       : []
  //   );
  //   setValue(
  //     searchParams.get("keywords")
  //       ? searchParams.get("keywords").split(",")
  //       : []
  //   );
  //   setIsBooleanSearch(Boolean(searchParams.get("booleanSearch") === "true"));
  // }, [searchParams]);

  useEffect(() => {
    // const company_id = localStorage.getItem("company_id");
    axios
      .get(`all-jobs`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        // console.log("alljobsdata", response);
        setJobOrderOptions(response?.data?.message);
        // setJoborderIds(response?.data?.message)
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }, []);

  const onFilterChange = React.useCallback((filterModel) => {
    const respFilterData = onGlobalFliterFunc(filterModel, `and entered_by`);
    if (respFilterData) {
      filtersetQueryOptions(respFilterData);
    }
  }, []);

  const filteredValue = value.filter((val) => val !== "");

  // console.log(" selected location", [preferredArr])

  // console.log(value,"value data")
  // console.log(searchParams.get("keywords"),"searchParams data")

  const handleSearchCandidate = () => {
    // if (value.length === 0) {
    //   setIsFieldEmpty(true);
    // } else {

    if (
      maxsalary !== "" &&
      maxsalary !== null &&
      Number(maxsalary) <= Number(minsalary)
    ) {
      setError("Max salary cannot be less than or equal to min salary");
      return;
    } else {
      setError("");
      setError2("");
    }

    if (maxexp !== "" && maxexp !== null && Number(maxexp) <= Number(minexp)) {
      setExpError(
        "Maximum experience cannot be less than or equal to minimum experience"
      );
      return;
    } else {
      setExpError("");
      setExpError2("");
    }

    let createdByName;
    if (selectedOptions.length > 0) {
      createdByName = generate_filter_condition(
        `and entered_by`,
        "isAnyOf",
        selectedOptions.map((item) => item.name)
      );
    } else if (searchParams.get("created_by")) {
      createdByName = generate_filter_condition(
        `and entered_by`,
        "isAnyOf",
        JSON.parse(decodeURIComponent(searchParams.get("created_by"))).map(
          (item) => item.name
        )
      );
    } else createdByName = "";

    setLoading(true);
    axios
      .post(
        `find-talent-new/1`,
        {
          keywords: isBooleanSearch
            ? filteredValue && filteredValue.join(",")
            : filteredValue,

          min_exp: minexp,
          max_exp: maxexp,
          min_salary: minsalary,
          max_salary: maxsalary,
          location: preferredArr.join(",") !== "" ? preferredArr : [],
          booleanSearch: isBooleanSearch,
          prev_search_id: "",
          // filterCriteria: filterOptions.filterCondition
          // ? filterOptions.filterCondition
          // : "",
          filterCriteria:
            selectedOptions.length > 0
              ? createdByName
              : !createdby.includes("and entered_by in ()")
                ? createdby
                : "",
          pageSize: paginationModel.pageSize,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (containerRef.current) {
            containerRef.current.scrollIntoView({top: 0, behavior: "smooth" });
          }
          setPageNumber(1);
          setPage(response.data.message.total_page);
          setTotalCount(response.data.message.total_search_result);
          setsearchdata(response.data.message.search_result);
          searchParams.delete("id");
          searchParams.set("keywords", value);
          searchParams.set("min_exp", minexp);
          searchParams.set("max_exp", maxexp);
          searchParams.set("min_salary", minsalary ? minsalary : "");
          searchParams.set("max_salary", maxsalary ? maxsalary : "");
          searchParams.set("location", preferredArr.join(","));
          searchParams.set("booleanSearch", isBooleanSearch);
          searchParams.set(
            "created_by",
            selectedOptions.length > 0
              ? encodeURIComponent(JSON.stringify(selectedOptions))
              : ""
          );
          setSearchParams(searchParams);
          setLoading(false);
          setShowLoader(false);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        setShowLoader(false);
        setLoading(false);
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
        setShowLoader(false);
      });
    // }
  };

  const preferedfetchData = async () => {
    try {
      if (getprefered && getprefered.length > 0) {
        const response = await axios.post(
          process.env.REACT_APP_JOBEZEURL + "/state-city/list",
          {
            cityName: getprefered,
          }
        );
        setpreferedLocation(response.data.message);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    const ondata = async () => {
      await preferedfetchData();
    };
    ondata();
  }, [getprefered]);

  const handleAddCandidates = () => {
    axios
      .post(
        `bulk-add/job`,
        {
          candidate_ids: addToJobCandidateSelection
            ? addToJobCandidateSelection
            : [],
          job_id: joborderIds ? joborderIds : "",
          quesAnsArray: [],
          isShortlisted: 0,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setKeepSelected(!keepSelected);
          setRerenderKey((prevKey) => prevKey + 1);
          setAddToJobCandidateSelection([]);
          setJobOrder("");
          setfaliurecount();
          setshimmer(false);
          setbulkdata(response.data.message.details);
          if (response.data.message.failure_candidates >= 1) {
            setadddisable(true);
          }
          handleSnackbarOpen("Candidate Added Successfully");
          if (response.data.message.failure_candidates === 0) {
            setAddtoJob(false);
            setOpenSnackbar(true);
          } else {
            setAddtoJob(true);
          }
        } else {
        }
      })
      .catch((err) => {
        console.log("error");
        setShowLoader(false);
        handleSnackbarOpen(err.response.data.error);
        setAddtoJob(false);
        setRerenderKey((prevKey) => prevKey + 1);
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const hanldePreferredInputChange = (value) => {
    if (value.length > 2) setprefcityLoc(value);
  };

  const handleminexp = (e) => {
    const value = e.target.value;

    // Use a regular expression to check if the input is a number
    if (
      value === "" ||
      (/^\d+$/.test(value) &&
        parseInt(value, 10) >= 0 &&
        parseInt(value, 10) <= 40 &&
        parseInt(value, 10).toString() === value)
    ) {
      setMinexp(value);
      // setMaxexp("");
      setExpError2("");
      setError2("");
    } else {
      setExpError2("'Minimum experience must be between 0 and 40'");
    }
  };

  const handlemaxexp = (e) => {
    // setMaxexp(e.target.value);
    const value = e.target.value;

    if (
      value === "" ||
      (/^\d+$/.test(value) &&
        parseInt(value, 10) >= 1 &&
        parseInt(value, 10) <= 40)
    ) {
      setMaxexp(value);
      setExpError("");
    } else {
      setExpError("Maximum experience must be between 1 and 40");
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, filterable: false },
    {
      field: "name",
      headerName: "Full Name",
      width: 200,
      filterable: false,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            component="div"
            sx={{ whiteSpace: "pre-wrap" }}
            textTransform={"capitalize"}
            title={params.row.name}
          >
            {params.row.name}
          </Typography>
        );
      },
    },

    {
      field: "entered_by",
      headerName: "Created By",
      width: 180,
      filterable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === "contains" ||
          operator.value === "equals" ||
          operator.value === "isAnyOf"
      ),
    },
    {
      filterable: false,
      field: "created_at",
      headerName: "Date Created",
      width: 180,
      renderCell: (params) =>
        new Date(params.row.created_at).toLocaleDateString("en-US"),
    },
    {
      field: "current_location",
      headerName: "Location",
      width: 180,
      filterable: false,
      renderCell: (params) => params.row.current_location || "NA",
    },
    {
      field: "year",
      headerName: "Experience",
      width: 150,
      // sortable: false,
      filterable: false,
      renderCell: (params) => {
        const years = params.row.year || 0;
        const months = params.row.month || 0;

        let result = "";

        if (years > 0) {
          result += `${years} year${years > 1 ? "s" : ""}`;
        }

        if (months > 0) {
          result += `${result.length > 0 ? " " : ""}${months} month${months > 1 ? "s" : ""
            }`;
        }

        return years === 0 ? "NA" : result.length > 0 ? result : "NA";
      },
    },

    {
      align: "start",
      field: "current_pay",
      headerName: "Current Salary",
      width: 150,
      typeof: "date",
      filterable: false,
      renderCell: (params) => params.row.current_pay || "NA",
    },
    {
      field: "date_created",
      headerName: "Last Active On",
      width: 150,
      filterable: false,
      renderCell: (params) => {
        if (params.row.date_created) {
          return params.row.date_created;
          // const beforeDate = new Date(params.row.date_created);
          // const today = new Date();
          // const diffTime = Math.abs(today - beforeDate);
          // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          // if (diffDays === 1) {
          //   return "1 Day Ago";
          // } else {
          //   return diffDays + " Days Ago";
          // }
        } else {
          return "NA";
        }
      },
    },
  ];

  const filteredData = searchdata.filter((row) => {
    const columnToSearch = row.name.toLowerCase(); // Change to the appropriate column
    return columnToSearch.includes(searchTerm.toLowerCase());
  });

  // useEffect(() => {
  //   console.log("let_id", let_id);
  //   console.log("me let id me 0 return krta hu")
  //   if (let_id === 0) {
  //     return;
  //   }
  //   // created by
  //   setLoading(true);
  //   let createdByName;

  //   if (selectedOptions.length > 0) {
  //     createdByName = generate_filter_condition(
  //       `and entered_by`,
  //       "isAnyOf",
  //       selectedOptions.map((item) => item.name)
  //     );
  //   } else if (searchParams.get("created_by")) {
  //     createdByName = generate_filter_condition(
  //       `and entered_by`,
  //       "isAnyOf",
  //       JSON.parse(decodeURIComponent(searchParams.get("created_by"))).map(
  //         (item) => item.name
  //       )
  //     );
  //   } else createdByName = "";
  //   //

  //   let payload = {
  //     keywords: !let_id
  //       ? searchParams.get("booleanSearch") === "false"
  //         ? searchParams.get("keywords") &&
  //           searchParams.get("keywords").split(",") //[searchParams.get("keywords")]
  //         : searchParams.get("keywords").replace(/"/g, "'")
  //       : [],
  //     min_exp: !let_id ? searchParams.get("min_exp") : "",
  //     max_exp: !let_id ? searchParams.get("max_exp") : "",
  //     min_salary: null,
  //     max_salary: null,
  //     location: !let_id
  //       ? searchParams.get("location") !== ""
  //         ? [searchParams.get("location")]
  //         : []
  //       : [],
  //     booleanSearch: !let_id
  //       ? searchParams.get("booleanSearch") === "false"
  //         ? false
  //         : true
  //       : false,
  //     prev_search_id: let_id,
  //     // filterCriteria: filterOptions.filterCondition
  //     // ? filterOptions.filterCondition
  //     // : "",
  //     filterCriteria: searchParams.get("created_by")
  //       ? createdByName
  //       : createdby
  //       ? createdby
  //       : "",
  //     sortField:
  //       queryOptions.sortModel && queryOptions.sortModel.length > 0
  //         ? queryOptions?.sortModel[0].field
  //         : "",
  //     sortBy:
  //       queryOptions.sortModel && queryOptions.sortModel.length > 0
  //         ? queryOptions?.sortModel[0].sort
  //         : "",
  //   };

  //   if (queryOptions.sortModel && queryOptions.sortModel.length > 0) {
  //     payload.sortField = queryOptions?.sortModel[0].field;
  //     payload.sortBy = queryOptions?.sortModel[0].sort;
  //   }

  //   axios
  //     .post(`find-talent-new/${pageNumber}`, payload, {
  //       headers: {
  //         authorization:
  //           Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
  //       },
  //     })
  //     .then((response) => {
  //       setsearchdata(response.data.message.search_result);
  //       setPage(response.data.message.total_page);
  //       setTotalCount(response.data.message.total_search_result);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setShowLoader(false);
  //       console.error("Error:", err);
  //     });
  // }, [queryOptions, let_id]);

  useEffect(() => {
    if (let_id === 1) {
      return;
    }
    const keywordsToSearch = searchParams.get("keywords")
      ? searchParams.get("keywords").split(",")
      : searchParams.get("booleanSearch") === "true"
        ? searchParams.get("keywords") &&
        searchParams.get("keywords").join(",").replace(/"/g, "'")
        : [];

    const minexpsearch = searchParams.get("min_exp")
      ? searchParams.get("min_exp")
      : "";
    const maxexpsearch = searchParams.get("max_exp")
      ? searchParams.get("max_exp")
      : "";
    const locationsearch = searchParams.get("location")
      ? searchParams.get("location").split(",")
      : [];
    const booleanSearchh = Boolean(
      searchParams.get("booleanSearch") === "true"
    );

    let createdByName = "";
    if (selectedOptions.length > 0) {
      createdByName = generate_filter_condition(
        `and entered_by`,
        "isAnyOf",
        selectedOptions.map((item) => item.name)
      );
    } else if (searchParams.get("created_by")) {
      createdByName = generate_filter_condition(
        `and entered_by`,
        "isAnyOf",
        JSON.parse(decodeURIComponent(searchParams.get("created_by"))).map(
          (item) => item.name
        )
      );
    }

    let payload = {
      keywords:
        searchParams.get("booleanSearch") === "true" ||
          searchParams.get("booleanSearch") === "1"
          ? keywordsToSearch && keywordsToSearch.join(", ")?.replace(/"/g, "'")
          : keywordsToSearch,
      // keywords : keywordsToSearch ? isBooleanSearch ? keywordsToSearch.join(", ")?.replace(/"/g, "'") : keywordsToSearch : [],
      min_exp: minexpsearch === "null" ? null : minexpsearch,
      max_exp: maxexpsearch === "null" ? null : maxexpsearch,
      min_salary: minsalary,
      max_salary: maxsalary,
      location: locationsearch,
      booleanSearch:
        searchParams.get("booleanSearch") === "1" ? true : booleanSearchh,
      prev_search_id: searchParams.get("id") ? let_id : "",
      filterCriteria: searchParams.get("created_by")
        ? createdByName
        : createdby
          ? createdby
          : "",
      sortField:
        queryOptions.sortModel && queryOptions.sortModel.length > 0
          ? queryOptions.sortModel[0].field
          : "",
      sortBy:
        queryOptions.sortModel && queryOptions.sortModel.length > 0
          ? queryOptions.sortModel[0].sort
          : "",
      pageSize: paginationModel.pageSize,
    };
    setShowLoader(true);
    axios
      .post(`find-talent-new/${pageNumber}`, payload, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((res) => {
        if (containerRef.current) {
          containerRef.current.scrollIntoView({ top : 0, behavior: "smooth" });
        }
        setData(res.data.message.search_result);
        setShowLoader(false);
        setsearchdata(res.data.message.search_result);
        setValue(
          searchParams.get("keywords") === ""
            ? []
            : searchParams.get("keywords").split(",") || []
        );
        setMinexp(searchParams.get("min_exp") || "");
        setMaxexp(searchParams.get("max_exp") || "");
        setSearchParams(searchParams);
        setPage(res.data.message.total_page);
        setCurrentPage((prev) => prev + 1);
        setTotalCount(res.data.message.total_search_result);
        setIsBooleanSearch(
          searchParams.get("booleanSearch") === "1"
            ? true
            : false || searchParams.get("booleanSearch") === "true"
              ? true
              : false
        );
        setSelectedOptions(
          searchParams.get("created_by")
            ? JSON.parse(decodeURIComponent(searchParams.get("created_by")))
            : []
        );
        setPreferredArr(
          searchParams.get("location")
            ? searchParams.get("location").split(",")
            : []
        );
      })
      .catch((err) => {
        setShowLoader(false);

        console.error("Error fetching data:", err);
      });
  }, [pageNumber, queryOptions, searchParams, paginationModel.pageSize]);

  return (
    <Box ref={containerRef} className="pageWrapper">
      <Box>
        <Box>
          <Grid container>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "5px 0 0 5px",
              }}
            >
              <Typography
                variant="h6"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                p={1}
                px={2}
              >
                Filters <FilterAltIcon />
              </Typography>
              <Divider />
              <Box width={"100%"}>
                <Box display={"flex"} flexDirection={"column"} gap={1.5} p={2}>
                  <Typography variant="subtitle1" mt={1.5}>
                    Created By
                  </Typography>
                  <CreatedByCheckbox
                    candidateList={candidateList}
                    handleSelectionData={handleSelectionData}
                    selectedOptions={selectedOptions}
                    username={username}
                  />
                  <Typography variant="subtitle1">
                    Current Salary Range
                  </Typography>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    justifyItems={"space-between"}
                    gap={1.5}
                    color="gray"
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      label="min"
                      fullWidth
                      value={minsalary}
                      onChange={handleminsalary}
                      helperText={Error2}
                      error={Boolean(Error2)}
                      onFocus={(e) => setError2("")}
                    />
                    to
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      label="max"
                      value={maxsalary}
                      onChange={handlemaxsalary}
                      helperText={Error}
                      error={Boolean(Error)}
                    />
                  </Typography>
                  <Typography variant="subtitle1" mt={1.5}>
                    Experience
                  </Typography>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    justifyItems={"space-between"}
                    gap={1.5}
                    color="gray"
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      label="min"
                      fullWidth
                      type="number"
                      value={minexp}
                      onChange={handleminexp}
                      error={Boolean(ExpError2)}
                      helperText={ExpError2}
                      // onChange={handleminexp}
                      // onBlur={handleBlurMinExp}
                      onFocus={(e) => setExpError2("")}
                    />
                    to
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="number"
                      label="max"
                      value={maxexp}
                      onChange={handlemaxexp}
                      // error={maxexpError !== "" || equalityError !== ""}
                      // helperText={maxexpError || equalityError}
                      // onChange={handlemaxexp}
                      // onBlur={handleBlurMaxExp}
                      onFocus={(e) => setExpError("")}
                      helperText={ExpError}
                      error={Boolean(ExpError)}
                    />
                  </Typography>
                  <Typography variant="subtitle1" mt={1.5}>
                    Locations
                  </Typography>
                  <Autocomplete
                    multiple
                    fullWidth
                    size="small"
                    value={preferredArr}
                    onChange={(e, val) => setPreferredArr(val)}
                    onInputChange={(event, newInputValue) => {
                      hanldePreferredInputChange(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={
                      preferredLocation &&
                      preferredLocation?.map((ele) => ele.city)
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Location" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value &&
                      value?.map((option, index) => (
                        <Chip
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={1.5}
                  >
                    <Typography variant="subtitle1">Keywords</Typography>
                    <Typography
                      variant="body1"
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isBooleanSearch}
                            onChange={(e) =>
                              setIsBooleanSearch(!isBooleanSearch)
                            }
                          />
                        }
                        label="Boolean Search"
                      />
                      <CustomWidthTooltip
                        TransitionComponent={Zoom}
                        title={
                          <Typography>
                            <Typography
                              variant="body2"
                              pb={1.5}
                              fontSize={"10px"}
                            >
                              Narrow down results with AND (e.g., "Java AND
                              Python" for jobs featuring both keywords).
                            </Typography>
                            <Typography
                              variant="body2"
                              pb={1.5}
                              fontSize={"10px"}
                            >
                              Broaden results with OR (e.g., "Java OR Python"
                              for jobs related to either keyword).
                            </Typography>
                            <Typography
                              variant="body2"
                              pb={1.5}
                              fontSize={"10px"}
                            >
                              Exclude keywords with NOT (e.g., "Java NOT Python"
                              for Java jobs without Python).
                            </Typography>
                            <Typography variant="body2" fontSize={"10px"}>
                              Use quotation marks for exact phrases (e.g.,
                              "Senior Java Developer" for precise matches).
                            </Typography>
                          </Typography>
                        }
                        arrow
                      >
                        <IconButton>
                          <img src="/NewTooltip.svg" alt="" />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Typography>
                  </Typography>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={[]}
                    value={value}
                    size="small"
                    sx={{ mb: 1 }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    freeSolo // This allows custom input
                    renderTags={(value, getTagProps) =>
                      isBooleanSearch ? (
                        <>
                          {value?.map((option, index) => (
                            <Typography variant="body1">{option}</Typography>
                          ))}
                        </>
                      ) : (
                        value?.map((option, index) => (
                          <>
                            {option === "" ? (
                              <></>
                            ) : (
                              <Chip
                                variant="filled"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            )}
                          </>
                        ))
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        placeholder={
                          value.length === 0 || value[0] === ""
                            ? "Enter keywords like skills, designation and email "
                            : "Type another keyword"
                        }
                        // error={isFieldEmpty}
                        // helperText={
                        //   isFieldEmpty ? "This field is required" : ""
                        // }
                        onBlur={() => {
                          // Check if inputValue is not empty before creating a new chip
                          if (inputValue.trim() !== "") {
                            setValue([...value, inputValue.trim()]);
                            setInputValue(""); // Clear the input field after creating the chip
                          }
                        }}
                      // onFocus={() => setIsFieldEmpty(false)}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Divider />
                  <Box
                    display={"flex"}
                    justifyContent={"end"}
                    gap={5}
                    px={2}
                    py={1}
                    letterSpacing={2}
                  >
                    <Button variant="text" size="large" onClick={handlereset}>
                      Reset
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleSearchCandidate}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={9}
              md={9}
              // width={"100%"}
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderLeft: "none",
                borderRadius: "0 5px 5px 0",
              }}
            >
              <Box p={2}>
                <Box className="fw-500 fs-24">
                  {value.length === 0 ||
                    (value.length === 1 && value[0] === "") ? (
                    <Typography variant="h5">Showing Results</Typography>
                  ) : (
                    <Typography sx={{ wordBreak: "break-word" }}>
                      <Typography fontWeight={500} variant="h5">
                        Showing Results for
                      </Typography>
                      {isBooleanSearch ? (
                        <Typography
                          fontWeight={400}
                          textTransform={"capitalize"}
                          variant="h6"
                        >
                          '
                          {searchParams
                            .get("keywords")
                            .split(",")
                            .map((word) => word.trim())
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(", ")}
                          '
                        </Typography>
                      ) : (
                        <Typography fontWeight={400} variant="h6">
                          "
                          {searchParams
                            .get("keywords")
                            .split(",")
                            .map((word) => word.trim())
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(", ")}
                          "
                        </Typography>
                      )}
                    </Typography>
                  )}
                  {/* "{searchParams.get("keywords").replace(/^,/, "")}" */}
                </Box>
                {/* <Typography variant="body2" color={"text.secondary"}>
                  {filteredData.length == 0
                    ? "No Result found"
                    : totalCount === 0
                    ? "Searching Result..."
                    : totalCount + " Results found"}
                </Typography> */}
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mt={1.5}
                >
                  <TextField
                    sx={{ width: "25ch" }}
                    type="search"
                    placeholder="Search..."
                    id="standard-search"
                    variant="standard"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {addCandidatePermission?.add === 1 && (
                    <Button
                      variant="contained"
                      disabled={addtojobcount || warning}
                      onClick={() => handleOpen(addToJobCandidateSelection)}
                    >
                      {" "}
                      Add to Job
                    </Button>
                  )}
                  <Modal
                    open={addtoJob}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h5"
                        className="fw-500"
                        p={2}
                      >
                        Add to Job
                      </Typography>
                      <Divider />
                      <Typography id="modal-modal-description" sx={{ p: 2 }}>
                        <Typography variant="body2" className="fw-400">
                          Select Job
                        </Typography>
                        <Autocomplete
                          value={jobOrder}
                          onChange={(event, newValue) => {
                            setJobOrder(newValue);
                          }}
                          inputValue={inputValueJobOrder}
                          onInputChange={(event, newInputValue) => {
                            const joborderId = newInputValue
                              .split(",")
                              .pop()
                              .trim();
                            setJoborderIds(joborderId);
                            setInputValueJobOrder(newInputValue);
                          }}
                          id="controllable-states-demo"
                          options={jobOrderOptions.map(
                            (option) => `${option.title}, ${option.joborder_id}`
                          )}
                          sx={{ mt: 2 }}
                          renderInput={(params) => (
                            <TextField {...params} label="Job" />
                          )}
                        />
                        {/* {addToJobCandidateSelection.length > 1 &&
                          questionrequiredflag == 1 && (
                            <Typography>
                              This job requires screening questions to be
                              answered,. We suggest you to add candidates one by
                              one
                            </Typography>
                          )} */}
                        <Box
                          sx={{ mt: 2 }}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1.5}
                          flexWrap={"wrap"}
                        >
                          <>
                            {shimmer ? (
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                gap={1.5}
                                flexWrap={"wrap"}
                              >
                                <Skeleton variant="text" width={"100%"} />
                                <Skeleton
                                  variant="rectangular"
                                  width={250}
                                  height={60}
                                />
                                <Skeleton
                                  variant="rectangular"
                                  width={250}
                                  height={60}
                                />
                                <Skeleton
                                  variant="rectangular"
                                  width={250}
                                  height={60}
                                />
                                <Skeleton
                                  variant="rectangular"
                                  width={250}
                                  height={60}
                                />
                              </Box>
                            ) : (
                              <Box>
                                {/* <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{ mt: 2 }}
                                width={"100%"}
                              >
                                The following candidates will be added
                              </Typography> */}
                                {bulkdata.length === 0 ? (
                                  <></>
                                ) : (
                                  <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    width={"100%"}
                                    gutterBottom
                                  >
                                    {bulkdata.length > 1 ? "These" : "This"}{" "}
                                    {bulkdata.length}{" "}
                                    {bulkdata.length > 1
                                      ? "candidates"
                                      : "candidate"}{" "}
                                    {bulkdata.length > 1 ? "are " : "is "}
                                    already available in this job.The remaining
                                    candidates have been added successfully.
                                  </Typography>
                                )}
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  gap={2}
                                  flexWrap={"wrap"}
                                >
                                  {bulkdata.map((item) => {
                                    return (
                                      <Typography
                                        variant="body1"
                                        width={225}
                                        sx={{
                                          backgroundColor: "#FDEDED",
                                          p: 1.5,
                                          borderRadius: 2,
                                          overflow: "hidden",
                                        }}
                                      >
                                        <Typography
                                          display={"flex"}
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                          gap={1}
                                        >
                                          <Typography
                                            variant="body1"
                                            className="fs-16 fw-500 "
                                          >
                                            {item.first_name} {item.last_name}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            className="fw-400 "
                                          ></Typography>
                                        </Typography>
                                        <Typography>
                                          <Typography
                                            variant="body2"
                                            className="fw-400 "
                                          >
                                            {item.email1}
                                          </Typography>
                                        </Typography>
                                      </Typography>
                                    );
                                  })}
                                </Box>
                              </Box>
                            )}
                          </>
                        </Box>
                      </Typography>
                      <Divider />
                      <Typography
                        id="modal-modal-footer"
                        variant="body2"
                        sx={{
                          p: 1.8,
                          display: "flex",
                          gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="text"
                          size="small"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          disabled={adddisbale}
                          onClick={handleAddCandidates}
                        >
                          Add
                        </Button>
                      </Typography>
                    </Box>
                  </Modal>
                </Box>
              </Box>
              <Divider />
              {warning ? (
                <Typography sx={{ ml: 3, mt: 1 }} color={"error"}>
                  You cannot select more than 10 Candidates.
                </Typography>
              ) : null}
              <Box p={3}>
                {loading ? (
                  // Render the loader or a loading indicator here
                  <Box textAlign={"center"}>
                    <CircularProgress />
                  </Box>
                ) : (
                  // Render the DataGrid when data is ready
                  <Box>
                    <DataGrid
                      sx={{
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer ": {
                          display: "none",
                        },
                        height: filteredData.length > 0 ? 550 : "unset",
                        "& .MuiTablePagination-toolbar": {
                          display: "none",
                        },
                      }}
                      key={rerenderKey}
                      keepNonExistentRowsSelected
                      loading={showLoader}
                      autoHeight={filteredData.length === 0}
                      rows={filteredData ? filteredData : []}
                      columns={columns}
                      onRowClick={HandleRowClick}
                      checkboxSelection={addCandidatePermission?.add === 1 ? true : false}
                      onRowSelectionModelChange={handleSelection}
                      sortingMode="server"
                      onSortModelChange={handleSortModelChange}
                      className="data-grid-row-hover"
                      disableSelectionOnClick
                      pagination
                      paginationMode="server"
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      filterMode="server"
                      onFilterModelChange={_debounce(onFilterChange, 500)}
                      rowCount={totalCount}
                      pageSizeOptions={[25, 50, 75, 100]}
                      disableRowSelectionOnClick
                    />
                    {searchdata.length > 0 && (
                      <Box display={"flex"} justifyContent={"end"} alignItems={"center"} py={3}>
                        <Box display={"flex"} justifyContent={"end"} alignItems={"center"}>
                          <Typography variant="body1" mx={2}>
                            Rows per page:
                          </Typography>
                          <FormControl sx={{ mr: 2, minWidth: 20 }}>
                            <Select
                              value={paginationModel.pageSize}
                              onChange={(e) =>
                                setPaginationModel((prev) => ({
                                  ...prev,
                                  pageSize: e.target.value,
                                }))
                              }
                              variant="outlined"
                              size="small"
                            >
                              <MenuItem value={25}>25</MenuItem>
                              <MenuItem value={50}>50</MenuItem>
                              <MenuItem value={75}>75</MenuItem>
                              <MenuItem value={100}>100</MenuItem>
                            </Select>
                          </FormControl>
                          <Button
                            onClick={handlePrevious}
                            disabled={pageNumber === 1}
                            size="large"
                            sx={{ border: 1 }}
                          >
                            <NavigateBeforeIcon />
                          </Button>
                          <Typography variant="body1" mx={2}>
                            Page - {pageNumber}
                          </Typography>
                          <Button
                            onClick={handleNext}
                            size="large"
                            sx={{ border: 1 }}
                            py={2}
                            disabled={searchdata.length < paginationModel.pageSize}
                          >
                            <NavigateNextIcon />
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>

                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <CandidateDetails
          issearchnavbar={true}
          isOpen={isApplicanOpen}
          setApplicanOpen={setApplicanOpen}
          onClose={() => setApplicanOpen(false)}
          selectedRowData={selectedRowData}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={
              snackbarMessage === "Candidate Added Successfully"
                ? "success"
                : "error"
            }
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle sx={{ color: "red" }}>Error</DialogTitle>
        <DialogContent>
          You cannot select more than 10 Candidates.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SearchCandidate;
