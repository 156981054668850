import React from "react";
import { Bar } from "@ant-design/plots";

const BarChart = ({ candidatesCount }) => {
  
  const maxYaxis = () => {
    const yaxisCount = {
      domainMax: 5,
      tickCount: 5,
    };

    const maxCount = Math.max(
      candidatesCount?.jobezePortal,
      candidatesCount?.employerPortal?.associatedWithJob,
      candidatesCount?.employerPortal?.notAssociatedWithJob
    );

    if (maxCount + 1 <= 5) {
      yaxisCount.domainMax = maxCount + 1;
      yaxisCount.tickCount = maxCount + 1;
    } else {
      const tempMax = Math.floor(maxCount / 5);
      yaxisCount.domainMax = maxCount + tempMax;
    }

    return yaxisCount;
  };

  const config = {
    data: [
      {
        portal: "Jobeze Portal",
        value: candidatesCount?.jobezePortal,
      },
      {
        portal: "Employer Portal",
        value: candidatesCount?.employerPortal?.associatedWithJob,
        type: "Associated Candidates",
      },
      {
        portal: "Employer Portal",
        value: candidatesCount?.employerPortal?.notAssociatedWithJob,
        type: "Not Associated Candidates",
      },
    ],
    xField: "portal",
    yField: "value",
    colorField: "type",
    stack: true,
    style: {
      maxWidth: 50,
      fill: ({ portal, type }) => {
        if (portal === "Jobeze Portal") {
          return "#006CB7";
        } else if (
          portal === "Employer Portal" &&
          type === "Job Associated Candidates"
        ) {
          return "#3F95EA";
        }
        return "#3F95EA99";
      },
    },
    axis: {
      y: { labelFormatter: "~s" },
      x: {
        labelSpacing: 10,
        labelFontSize: 10,
        style: {
          labelTransform: "rotate(90)",
        },
        labelFormatter: (val) => {
          return val.split(" ")[0];
        },
      },
    },
    scale: {
      y: maxYaxis(),
    },
    legend: false,
    interaction: {
      tooltip: {
        render: (e, { title, items }) => {
          return (
            <div>
              <div>{title}</div>
              {items.map((item, index) => (
                <>
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span>{item.name}</span>
                    <span>{item.value}</span>
                  </div>
                </>
              ))}
            </div>
          );
        },
      },
    },
  };
  return <Bar {...config} />;
};

export default BarChart;
