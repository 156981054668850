// Tile.js
import React from 'react';
import { Box, Typography, Card, CardContent, Paper, Select, MenuItem } from '@mui/material';

const Tile = ({ count, total, description, onClick, highlighted }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }} onClick={onClick} >


            <Paper
                elevation={0}
                sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'pointer',
                    border: "1px solid rgba(0, 108, 183, 0.20)",
                    backgroundColor: highlighted ? '#3F95EA' : 'white   ', // Highlighted background color for the selected tile
                    color: highlighted ? '#fff' : '#000', // Text color based on background
                    position: 'relative',
                    borderRadius: "10px",
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 0.5, fontSize: '24px' }}>
                    {
                        description === "Total Candidates"
                            ? count
                            : (
                                <span>
                                    {count}
                                    <span style={{ fontSize: '12px', marginLeft: '0.25em', fontWeight: 400 }}>
                                        / {total}
                                    </span>
                                </span>
                            )
                    }


                </Typography>
                <Typography variant="body1" sx={{ fontSize: '13px' }} >
                    {description}
                </Typography>

            </Paper>
        </Box >
    );
};

export default Tile;
