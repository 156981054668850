import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const Screeningquestion = ({ setFormData, jobId, disableflag ,setEmptyQuestionIndices,emptyQuestionIndices,newerror,setnewerror }) => {
  const [questionTypes, setQuestionTypes] = useState([]);
  const [addedQuestions, setAddedQuestions] = useState([]);
  const [selectData, setSelectData] = useState();
  const [exceedsLimitError, setExceedsLimitError] = useState(false);
  const getToken = useSelector((state) => state.user.token);

  
//  console.log("addedQuestions",addedQuestions)

 
  
  useEffect(() => {
    axios
      .get("/question-type", {
        headers: {
          authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
        },
      })
      .then((response) => {
        setQuestionTypes(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching question types:", error);
      });
  }, []);

   // Helper function to update empty question indices
   const updateEmptyQuestionIndices = (questions) => {
    const emptyIndices = questions.reduce((acc, question, i) => {
      if (!question.question || question.question.trim() === "") {
        acc.push(i);
      }
      return acc;
    }, []);
    setEmptyQuestionIndices(emptyIndices);
  };


  const handleClick = (questionType) => {

    let existingQuestions = [...addedQuestions];
      // Check if adding a new question would exceed the limit of 10 questions
      const activeQuestionsCount = existingQuestions.reduce((count, question) => {
        if (!question.isDelete) {
            count++;
        }
        return count;
    }, 0);

    // Check if adding a new question would exceed the limit of 10 questions
    if (activeQuestionsCount >= 10) {
        // If there are already 10 active questions, set the error state to true
        setExceedsLimitError(true);
        return; // Exit the function early since the validation failed
    }

  
    // Marking the questionType as to be created and not edited or deleted
    questionType.isCreate = true;
    questionType.isEdit = false;
    questionType.isDelete = false;
    // Pushing the modified questionType into the existingQuestions array
    existingQuestions.push({ ...questionType });
    // Updating the state with the modified array of questions
    setAddedQuestions(existingQuestions);
    updateEmptyQuestionIndices(existingQuestions);
    // console.log("addedQuestions", existingQuestions);
     // If the length of addedQuestions is less than 10, reset the error state
     if (existingQuestions.length < 10) {
      setExceedsLimitError(false);
    }

  };

  useEffect(() => {
    axios
      .post(`/question-list-job/${jobId}`,{applyFilter:0},{
        headers: {
          authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
        },
      })
      .then((response) => {
        // console.log("resssss", response);
        // Assuming response.data contains the array of questions
        let tempData = response.data.message;
        let tempArr = [];
        // Transforming the response data into the desired format
        for (let i = 0; i < tempData.length; i++) {
          let tempObj = {
            id: tempData[i].id,
            question: tempData[i].question,
            quesId: tempData[i].question_id,
            isCreate: false,
            isEdit: false,
            isDelete: false,
            isChange: true,
            values: tempData[i].values,
            questiontype : tempData[i].question_type
          };
          tempArr.push(tempObj);
        }
        setAddedQuestions([...tempArr]);
      })
      .catch((error) => {
        console.error("Error fetching questions for job:", error);
      });
  }, []);

  const handleChange = (e = { target: { value: "" } }, type, index) => {
    const { value } = e.target;
    let existingQuestions = [...addedQuestions];

    if (type == "MenuItem") {
      // Updating the id and type properties based on the selected value
      existingQuestions[index].id = value;
      const ind = questionTypes.findIndex((e) => e.id === value);
      existingQuestions[index].type = questionTypes[ind].type;
    } else if (type === "input") {
      // console.log("input",value)

      if (!existingQuestions[index].isCreate) {
        existingQuestions[index].isEdit = true;
      }
      existingQuestions[index].question = value;
    } else if (type === "delete") {
      if (existingQuestions[index].isCreate) {
        existingQuestions.splice(index, 1);
      } else {
        existingQuestions[index].isEdit = false;
        existingQuestions[index].isDelete = true;
      }

       // Check if the number of questions falls below 10 after deletion
    if (existingQuestions.length < 10) {
      setExceedsLimitError(false);
    }
    }

    const emptyIndices = existingQuestions.reduce((acc, question, i) => {
      if (!question.question || question.question.trim() === "") {
        acc.push(i);
      }
      return acc;
    }, []);
    setEmptyQuestionIndices(emptyIndices);

    let existingQues = [...existingQuestions];
    // Adding isRequired property to each object in existingQuestions
    for (let i = 0; i < existingQues.length; i++) {
      existingQues[i].isRequired = true;

      if (
        existingQues[i].type === "Description" ||
        existingQues[i].type === "Numeric"
      )
        existingQues[i].values = [];
      else if (existingQues[i].type === "Yes/No") {
        existingQues[i].values = [
          { name: "yes", isDefault: true },
          { name: "no", isDefault: false },
        ];
      }
      delete existingQues[i].type;
    }

    setAddedQuestions([...existingQues]);
    setFormData(existingQues);
    updateEmptyQuestionIndices(existingQuestions);
    

    // console.log("Final Output -----------------", existingQues);
  };


  const handleFocus = (index) => {
    // Clear error for the input at the specified index
    setnewerror(newerror.filter((errIndex) => errIndex !== index));
  };

 
  return (
    <>
      <div
      >
      </div>
      {addedQuestions?.length > 0 &&
        addedQuestions?.map((addedQuestion, index) =>
          !addedQuestion.isDelete ? (
            <Box
              key={index}
              sx={{
                mt: 2,
                backgroundColor: "rgba(0, 108, 183, 0.04)",
                padding: "1.5%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel>Type</InputLabel>
                      <Select
                        value={
                          (selectData && selectData?.type) || addedQuestion?.id || addedQuestion?.questiontype
                        }
                      
                        disabled={
                          addedQuestion.isChange || disableflag > 0
                            ? true
                            : false
                        }
                        label="Type"
                        onChange={(e) => handleChange(e, "MenuItem", index)}
                      >
                        {questionTypes.map((questionType, ind) => (
                          <MenuItem key={ind} value={questionType.id} >
                            {questionType.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Typography>
                  <Typography display={"flex"} alignItems={"center"} gap={1}>
                    <InfoIcon />{" "}
                    <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                      You will get response in{" "}
                      {
                        questionTypes?.filter(
                          (e) => e?.id == addedQuestion?.id || e?.id == addedQuestion?.questiontype
                        )[0]?.type
                      }
                    </span>
                  </Typography>
                </Box>
                <Typography>
                  <IconButton
                    disabled={disableflag > 0 ? true : false}
                    onClick={() => handleChange(undefined, "delete", index)}
                    aria-label="delete"
                    size="large"
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Typography>
              </div>
              <div>
                <OutlinedInput
                  error = {newerror.includes(index) ? true : false}
                  disabled={disableflag > 0 ? true : false}
                  sx={{ mt: 1, mb: 1, mx: 1, width: "98%" }}
                  value={(addedQuestion && addedQuestion.question) || ""}
                  onChange={(e) => handleChange(e, "input", index)}
                  inputProps={{ maxLength: 200 }}
                  placeholder="Write question here" 
                  onFocus={() => handleFocus(index)} 
                />{newerror.includes(index) && (
                  <FormHelperText error>
                    Question is required
                  </FormHelperText>
                )}
              </div>
            </Box>
          ) : (
            <> </>
          )
        )}
        {exceedsLimitError && (
        <div style={{ color: 'red' ,marginTop:'10px'}}>You can create upto 10 questions only.</div>
      )}
        <Box style={{
          backgroundColor: "rgba(0, 108, 183, 0.04)",
          borderRadius: 4,
          padding: "1.5%",
          marginTop: "1.5%",
        }}>
       <Typography style={{ color: "rgba(0, 0, 0, 0.6)" }}>
          Add screening questions
        </Typography>
        <Typography display={"flex"} gap={1} mt={2} >
          {questionTypes.map((questionType, index) => (
            <Button
              disabled={disableflag > 0 ? true : false}
              key={index}
              variant="outlined"
              size="medium"
              onClick={() => handleClick(questionType)}
              sx={{
                borderRadius: "34px",
                margin: "10px",
              }}
              startIcon={<AddIcon />}
            >
              {questionType.type}
            </Button>
          ))}
        </Typography>
       </Box>
    </>
  );
};

export default Screeningquestion;
