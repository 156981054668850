import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'

const ViewCandidateBy = ({ radioNames, selectedViewName, handleViewCandidateBy }) => {
    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedViewName}
                onChange={handleViewCandidateBy}
            >
                {radioNames.map((name) => (
                    <FormControlLabel key={name} value={name} control={<Radio />} label={name} />
                ))}

            </RadioGroup>
        </FormControl>
    )
}

export default ViewCandidateBy