import React, { useState } from "react";
import Button from '@mui/material/Button';
import axios from "axios";
import { useSelector } from "react-redux";
import DialogBox from "./DialogBox";
import Cookies from "js-cookie";

function Comment({selectedRowData,setrefreshagain,
  refreshagain,activitesrefresh,setactivitesrefresh,setnewvalue,setstatusupdate,selectedStatus,handleSnackbarOpen,setShowComment})
{
    const title= "Add a Comment";
    const getToken = useSelector((state) => state.user.token);
    //  console.log("candidate_id",selectedRowData);
    const [comment,setComment] = useState("");
    const [isDialogOpen,setDialogOpen] = useState(false);
    const [showNewAdd,setNewAdd] = useState(false);

    const openDialog = ()=>{
        setDialogOpen(true);
    }


    const closeDialog = ()=>{
        setDialogOpen(false);
        setComment("");

    };
    
    const saveCommentAndStatus = ()=>{
      const obj = {
        id:selectedRowData.id,
        date_modified:selectedRowData.date_modified,
      };
      // console.log("obj-->",obj);
      axios.post(`/add-comment`,
            {
              candidate_id:selectedRowData.id,
              job_id: selectedRowData.joborder_id,
              comment: comment,
            },
            {
              headers:{
                authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
              },
            }
      )
      .then((response) =>{
          if(response.status===200){
            console.log("requst send---------->green")
            handleSnackbarOpen("Comment Added Successfully");
            setrefreshagain(!refreshagain);
            setactivitesrefresh(!activitesrefresh);
            setnewvalue(1);
            setstatusupdate(selectedStatus);
            setShowComment(true);
            // console.log("54")
            // console.log("comment res",response)
            
          }
      })
      .catch((err)=>{
        console.log("request rejected",err)
        handleSnackbarOpen(err.response.data.error);
      });
        closeDialog();
    }

    const handleClickOpen = ()=>{
        setNewAdd(true);
        setDialogOpen(true);
    }
    return(
        <React.Fragment>
      <div className='btn1' style={{margin:"16px 0"}}>
      <Button variant="outlined" className='btn' onClick={handleClickOpen} sx={{padding:"6px 16px",border: "1px solid #2196F3",borderRadius:"4px","&:hover":{color:"#fff",backgroundColor:"#2196F3",
      boxShadow: "0px 5px 5px 0px #7D747440",border:"none"

    }}}>
        WRITE A COMMENT
      </Button>
      </div>
      <DialogBox
      showNewAdd={showNewAdd} 
      title={title}
      closeDialog={closeDialog}
      isDialogOpen={isDialogOpen}
      comment={comment}
      setComment={setComment}
      saveCommentAndStatus={saveCommentAndStatus}
      />
        
      </React.Fragment>
    )
}
export default Comment;