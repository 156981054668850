import { Box, Menu, MenuItem } from "@mui/material";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import React, { useEffect, useState } from "react";

const TimeSelector = ({ initialValue, handleFilters }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [timeFilter, setTimeFilter] = useState(initialValue);

  const timeFilterItem = [
    {
      label: "Last Week",
      value: "7d",
    },
    {
      label: "Last Month",
      value: "1m",
    },
    {
      label: "Last 3 Months",
      value: "3m",
    },
    {
      label: "Any Time",
      value: "All",
    },
  ];

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (filterItem) => {
    setTimeFilter(filterItem);
    handleMenuClose();
  };

  useEffect(() => {
    if (timeFilter) {
      handleFilters(timeFilter);
    }
  }, [timeFilter]);

  return (
    <>
      <Box
        id="jobs-menu-button"
        aria-controls={Boolean(anchorEl) ? "jobs-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        onClick={handleMenuClick}
        sx={{
          background: "#FFAD00",
          px: 1.25,
          py: 0.75,
          display: "flex",
          alignItems: "center",
          gap: 0.5,
          cursor: "pointer",
          color: "#fff",
          borderRadius: 1.5,
          width: "fit-content",
        }}
      >
        <ScheduleOutlinedIcon sx={{ fontSize: "16px" }} />
        <span style={{ width: "2.6ch", fontSize: "14px" }}>
          {timeFilter?.value}
        </span>
        <KeyboardArrowDownOutlinedIcon sx={{ fontSize: "16px" }} />
      </Box>
      <Menu
        id="jobs-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "jobs-menu-button",
        }}
        sx={{
          "& ul": {
            padding: "4px 0",
          },
        }}
      >
        {timeFilterItem.map((filterItem) => (
          <MenuItem
            key={filterItem.value}
            sx={{
              mx: 0.5,
              mb: filterItem.label === "Any Time" ? "" : 0.5,
              px: 1.5,
              borderRadius: 1,
              fontSize: "12px",
              "&:hover": {
                background: "#FFAD0015",
              },
              background:
                timeFilter?.label === filterItem.label
                  ? "#FFAD00 !important"
                  : "",
              color:
                timeFilter?.label === filterItem.label ? "#fff !important" : "",
            }}
            onClick={() => handleMenuItemClick(filterItem)}
          >
            {filterItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TimeSelector;
