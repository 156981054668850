import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Zoom from "@mui/material/Zoom";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { useNavigate } from "react-router";
import axios from "axios";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import CreatedByCheckbox from "../components/createdbycheckbox";
import Cookies from "js-cookie";

// const baseURL = process.env.REACT_APP_BASEURL;

const FindTalent = () => {
  const nav = useNavigate();
  const [value, setValue] = useState([]);
  const [isBooleanSearch, setIsBooleanSearch] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [minimum, setminimum] = useState("");
  const [maximum, setmaximum] = useState("");
  const suggestions = ["Option1", "Option2", "Option3"]; // Your suggestions go here
  const [getprefered, setprefcityLoc] = useState([]);
  const [preferredLocation, setpreferedLocation] = useState([]);
  const [preferredArr, setPreferredArr] = useState([]);
  // const [isFieldEmpty, setIsFieldEmpty] = useState(false);
  const getToken = useSelector((state) => state.user.token);
  const [loading, setLoading] = useState(false);
  const [prevoiusserachdata, setprevoiusserachdata] = useState([]);
  const [ExpError2, setExpError2] = useState("");
  const [ExpError, setExpError] = useState("");
  // created by
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [candidateList, setcandidateList] = useState([]);
  const [username, setUsername] = useState("");
  const [refreshagain, setrefreshagain] = useState(false);

  {
    /* Student Data Random */
  }
  const getUserData = async () => {
    try {
      const candidateOwnerList = await axios.get(`active-users`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      setcandidateList(candidateOwnerList.data.message);
      setUsername("Select User Name");
    } catch (error) {
      console.log(error);
      setUsername("Select User Name");
      setrefreshagain((prev) => !prev);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  const handleSelectionData = (event, value) => {
    setSelectedOptions(value);
    setSelectedCount(value.length);
    //console.log("selectedOptions7444444", selectedOptions);
    //const usernames = value.map((item) => item.name);
    //const filterCondition = generate_filter_condition(`and entered_by`,"isAnyOf",usernames)
  };
  //

  // const handleminexp = (e) => {
  //   const value = e.target.value;

  //   // Use a regular expression to check if the input is a number
  //   if (
  //     value === "" ||
  //     (/^\d+$/.test(value) &&
  //       parseInt(value, 10) >= 1 &&
  //       parseInt(value, 10) <= 40)
  //   ) {
  //     setMinexp(value);
  //   }
  // };

  // const handlemaxexp = (e) => {
  //   const value = e.target.value;

  //   // Use a regular expression to check if the input is a number
  //   if (value === "" || (/^\d+$/.test(value) && parseInt(value, 10) >= 1 && parseInt(value, 10) <= 40)) {
  //     setMaxexp(value);
  //   }
  // };

  // const handlemaximumexpreience = (e) => {
  //   const value = e.target.value;

  //   if (
  //     value === "" ||
  //     (/^\d+$/.test(value) &&
  //       parseInt(value, 10) >= 1 &&
  //       parseInt(value, 10) <= 40)
  //   ) {
  //     setmaximum(value);
  //   }
  // };

  // const handleminimumexpreience = (e) => {
  //   const value = e.target.value;

  //   // Use a regular expression to check if the input is a number
  //   if (
  //     value === "" ||
  //     (/^\d+$/.test(value) &&
  //       parseInt(value, 10) >= 0 &&
  //       parseInt(value, 10) <= 40)
  //   ) {
  //     setminimum(value);
  //   }
  // };

  // const handleminimumexpreience = (e) => {
  //   const value = e.target.value;

  //   if (
  //     value === "" ||
  //     (/^\d+$/.test(value) &&
  //       parseInt(value, 10) >= 0 &&
  //       parseInt(value, 10) <= 40)
  //   ) {
  //     setminimum(value);
  //     setExpError2(''); // Clear the error if the value is within the valid range
  //   } else {
  //     setExpError2('Minimum experience must be between 0 and 40');
  //   }
  // };

  const handleminimumexpreience = (e) => {
    const value = e.target.value;

    if (
      value === "" ||
      (/^\d+$/.test(value) &&
        parseInt(value, 10) >= 0 &&
        parseInt(value, 10) <= 40 &&
        parseInt(value, 10).toString() === value)
    ) {
      setminimum(value);
      setExpError2(""); // Clear the error if the value is within the valid range
    } else {
      setExpError2("Minimum experience must be between 0 and 40");
    }
  };

  const handlemaximumexpreience = (e) => {
    const value = e.target.value;

    if (
      value === "" ||
      (/^\d+$/.test(value) &&
        parseInt(value, 10) >= 1 &&
        parseInt(value, 10) <= 40)
    ) {
      setmaximum(value);
      setExpError(""); // Clear the error if the value is within the valid range
    } else {
      setExpError("Maximum experience must be between 1 and 40");
    }
  };

  // const preferedfetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://public.opendatasoft.com/api/explore/v2.1/catalog/datasets/us-cities-demographics/records?where=suggest(city%2C%22${getprefered}%22)&group_by=city&limit=20`
  //     );
  //     setpreferedLocation(response.data.results);
  //   } catch (error) {
  //     console.error("Error", error);
  //   }
  // };

  // const preferedfetchData = async () => {
  //   try {
  //     if (getprefered && getprefered.length > 0) {
  //       const response = await axios.post(
  //         process.env.REACT_APP_JOBEZEURL + "/state-city/list",
  //         {
  //           cityName: getprefered,
  //         }
  //       );
  //       // console.log("response", response)
  //       setpreferedLocation(response.data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error", error);
  //   }
  // };

  useEffect(() => {
    const ondata = async () => {
      try {
        if (getprefered && getprefered.length > 0) {
          const response = await axios.post(
            process.env.REACT_APP_JOBEZEURL + "/state-city/list",
            {
              cityName: getprefered,
            }
          );
          // console.log("response", response)
          setpreferedLocation(response.data.message);
        }
      } catch (error) {
        console.error("Error", error);
        // setrefreshagain(prev => !prev);
      }
    };
    ondata();
  }, [getprefered]);

  const fetchPreviousSearchData = () => {
    axios
      .get(`previous-search`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        setprevoiusserachdata(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching previous search data", error);
        // setrefreshagain(prev => !prev);
      });
  };

  useEffect(() => {
    fetchPreviousSearchData();
  }, []);

  const handleChipClick = (data) => {
    nav(
      `/searchCandidate?id=${data.id}&keywords=${data.keywords}&created_by=${data.created_by ? data.created_by : ""
      }&min_exp=${data.min_exp ? data.min_exp : ""}&max_exp=${data.max_exp ? data.max_exp : ""
      }&location=${data.location}&booleanSearch=${data.booleanSearch
      }&min_salary=${data.min_salary ? data.min_salary : ""}&max_salary=${data.max_salary ? data.max_salary : ""
      }`
    );
  };

  // console.log("isBooleanSearch",isBooleanSearch)
  const handleSearchCandidate = () => {
    // if (value.length === 0) {
    //   // setIsFieldEmpty(true);
    // } else {
    if (maximum !== "" && Number(maximum) <= Number(minimum)) {
      setExpError(
        "Maximum experience cannot be less than or equal to minimum experience"
      );
      return;
    } else {
      setExpError("");
      setExpError2("");
    }

    setLoading(true); // Set loading to true before the API request
    const queryParams = new URLSearchParams();
    const newSelectedOptions = encodeURIComponent(
      JSON.stringify(selectedOptions)
    );
    queryParams.append("keywords", value.join(","));
    queryParams.append("min_exp", minimum);
    queryParams.append("max_exp", maximum);
    queryParams.append("location", preferredArr.join(","));
    queryParams.append("booleanSearch", isBooleanSearch);
    queryParams.append("prev_search_id", "");
    queryParams.append(
      "created_by",
      selectedOptions.length > 0 ? newSelectedOptions : ""
    );
    const queryString = queryParams.toString();

    // Use the queryString in the navigation
    nav(`/searchCandidate?${queryString}`);
    // }
  };
  const handleClear = () => {
    setValue([]);
    setInputValue("");
  };

  const locations = [
    "New York",
    "Los Angeles",
    "Chicago",
    "Houston",
    "Phoenix",
    // ... add more locations here
  ];
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const handleReset = () => {
    setValue([]);
    setPreferredArr([]);
    setInputValue("");
    setminimum("");
    setmaximum("");
    setIsBooleanSearch(false);
    setExpError("");
    setExpError2("");
    //
    setSelectedOptions([]);
  };

  const hanldePreferredInputChange = (value) => {
    if (value.length > 2) setprefcityLoc(value);
  };

  return (
    <Box className="pageWrapper">
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box item sx={{ width: "100%" }}>
            <Box
              flex={1}
              p={3}
              pb={0}
              component={"div"}
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: 5,
                background: "#fff",
              }}
            >
              <Box component={"div"} sx={{ mb: 2 }}>
                <Typography variant="h5" align="left" component="h1">
                  Previously Searched for
                </Typography>
              </Box>
              <Box component={"div"} sx={{ mb: 3 }}>
                <Typography variant="body" align="left" component="body2">
                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      flexWrap={"wrap"}
                      gap={2}
                      className="fw-500"
                    >
                      {prevoiusserachdata?.map((searchItem, index) => (
                        <Chip
                          key={index} // It's important to include a unique key for each Chip component
                          label={searchItem?.keywords?.join(", ")}
                          variant="filled"
                          title={searchItem?.keywords?.join(", ")}
                          sx={{ textTransform: "capitalize", maxWidth: "50ch" }}
                          onClick={() => handleChipClick(searchItem)} // You might want to pass the searchItem to your click handler
                        />
                      ))}
                    </Stack>
                  </Stack>
                </Typography>
              </Box>
              <Box component={"div"} sx={{ mb: 3 }}>
                <Divider />
              </Box>
              <Box component={"div"} sx={{ mb: 3 }}>
                <Typography variant="body1" align="left" sx={{ mb: 4 }}>
                  <Typography
                    variant="body1"
                    display={"flex"}
                    justifyContent={"space-between"}
                    sx={{ mb: 2 }}
                  >
                    <Typography variant="body1">Keywords</Typography>
                    <Typography variant="body1" display={"flex"}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isBooleanSearch}
                              onChange={(e) =>
                                setIsBooleanSearch(!isBooleanSearch)
                              }
                            />
                          }
                          label="Boolean Search"
                          labelPlacement="start"
                        />
                      </FormGroup>
                      <CustomWidthTooltip
                        TransitionComponent={Zoom}
                        title={
                          <Typography>
                            <Typography
                              variant="body2"
                              pb={1.5}
                              fontSize={"10px"}
                            >
                              Narrow down results with AND (e.g., "Java AND
                              Python" for jobs featuring both keywords).
                            </Typography>
                            <Typography
                              variant="body2"
                              pb={1.5}
                              fontSize={"10px"}
                            >
                              Broaden results with OR (e.g., "Java OR Python"
                              for jobs related to either keyword).
                            </Typography>
                            <Typography
                              variant="body2"
                              pb={1.5}
                              fontSize={"10px"}
                            >
                              Exclude keywords with NOT (e.g., "Java NOT Python"
                              for Java jobs without Python).
                            </Typography>
                            <Typography variant="body2" fontSize={"10px"}>
                              Use quotation marks for exact phrases (e.g.,
                              "Senior Java Developer" for precise matches).
                            </Typography>
                          </Typography>
                        }
                        arrow
                      >
                        <IconButton>
                          <img src="/NewTooltip.svg" alt="" />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Typography>
                  </Typography>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={[]}
                    value={value}
                    size="small"
                    autoFocus
                    sx={{ mb: 1 }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                      // console.log(newValue, "chipvalue");
                      setValue(newValue);
                    }}
                    freeSolo // This allows custom input
                    renderTags={(value, getTagProps) =>
                      isBooleanSearch ? (
                        // <Typography variant="body1" px={1}>
                        <>{value.join(", ")}</>
                      ) : (
                        // </Typography>
                        value.map((option, index) => (
                          <Chip
                            variant="filled"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        autoFocus
                        placeholder={
                          value.length === 0
                            ? "Enter keywords like skills, designation and email "
                            : "Type another keyword"
                        }
                        // error={isFieldEmpty}
                        // helperText={
                        //   isFieldEmpty ? "This field is required" : ""
                        // }
                        onBlur={() => {
                          // Check if inputValue is not empty before creating a new chip
                          if (inputValue.trim() !== "") {
                            setValue([...value, inputValue.trim()]);
                            setInputValue(""); // Clear the input field after creating the chip
                          }
                        }}
                      // onFocus={() => setIsFieldEmpty(false)}
                      />
                    )}
                  />
                  <Button
                    variant="text"
                    sx={{ textTransform: "initial" }}
                    onClick={handleClear}
                  >
                    Clear all
                  </Button>
                  <Box width={"100%"}>
                    <Divider />
                  </Box>
                  <Box sx={{ px: 0.2, pt: 1.5 }}>
                    <Box
                      sx={{ pb: 1 }}
                      display={"flex"}
                      gap={1}
                      justifyContent={"space-between"}
                    >
                      <Typography fontWeight={500} sx={{ pb: 3, pt: 1 }}>
                        {" "}
                        Created By{" "}
                      </Typography>
                      <Box width={"70%"}>
                        <CreatedByCheckbox
                          candidateList={candidateList}
                          handleSelectionData={handleSelectionData}
                          selectedOptions={selectedOptions}
                          username={username}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box width={"100%"}>
                    <Divider />
                  </Box>
                </Typography>
                <Typography variant="body1" align="left" sx={{ mb: 3 }}>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    Experience (Years)
                  </Typography>
                  <Box display={"flex"} gap={3} alignItems={"center"} mb={3}>
                    <TextField
                      id="outlined-basic"
                      label="Minimum Experience"
                      fullWidth
                      value={minimum}
                      size="small"
                      variant="outlined"
                      onChange={handleminimumexpreience}
                      type="number"
                      inputProps={{
                        min: 0,
                        max: 40,
                      }}
                      error={Boolean(ExpError2)}
                      helperText={ExpError2}
                      onFocus={(e) => setExpError2("")}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Maximum Experience"
                      fullWidth
                      value={maximum}
                      size="small"
                      variant="outlined"
                      onChange={handlemaximumexpreience}
                      type="number"
                      inputProps={{
                        min: 0,
                        max: 40,
                      }}
                      onFocus={(e) => setExpError("")}
                      helperText={ExpError}
                      error={Boolean(ExpError)}
                    />
                  </Box>
                  <Autocomplete
                    multiple
                    fullWidth
                    size="small"
                    value={preferredArr}
                    onChange={(e, val) => setPreferredArr(val)}
                    onInputChange={(event, newInputValue) => {
                      hanldePreferredInputChange(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={preferredLocation?.map((ele) => ele.city)}
                    renderInput={(params) => (
                      <TextField {...params} label="Location" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  display={"flex"}
                  gap={3}
                >
                  <Button variant="text" onClick={handleReset}>
                    Reset
                  </Button>
                  <Button variant="contained" onClick={handleSearchCandidate}>
                    Search
                  </Button>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            item
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              width: "100%",
              backgroundColor: "#FFF3E0",
              borderRadius: 5,
            }}
          >
            <Box sx={{ py: 3, px: 2 }}>
              <Typography fontSize={"34px"} align="left">
                💡
              </Typography>
              <Typography variant="h6" sx={{ pt: 1 }}>
                Tips for finding a perfect candidate
              </Typography>
              <Typography variant="body1" sx={{ pl: 2, pt: 2.5 }}>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <li>
                    <b>Thoroughly Understand Job Requirements:</b> Start by
                    fully comprehending the job description and its
                    requirements, including technical and soft skills.
                  </li>
                  <li>
                    <b>Craft Clear Job Descriptions:</b> Create concise, clear,
                    and attractive job descriptions to ensure candidates
                    understand the role and responsibilities.
                  </li>
                  <li>
                    <b>Leverage Technology:</b> Utilize applicant tracking
                    systems (ATS) and recruitment software to streamline the
                    candidate search and management process.
                  </li>
                  <li>
                    <b>Build and Maintain a Talent Pool:</b> Continuously engage
                    with potential candidates, even if they aren't suitable for
                    current openings, to cultivate a talent pipeline.
                  </li>
                  <li>
                    <b>Network and Attend Industry Events:</b> Attend
                    industry-specific events and networking opportunities to
                    meet potential candidates and stay updated on industry
                    trends.
                  </li>
                  <li>
                    <b>Conduct Effective Interviews:</b> Prepare standardized
                    questions that assess technical and soft skills during
                    interviews to make informed hiring decisions.
                  </li>
                  <li>
                    <b>Assess Cultural Fit:</b> Evaluate candidates' alignment
                    with the company culture by asking questions about values,
                    work style, and team dynamics preferences.
                  </li>
                  <li>
                    <b>Check References:</b> Perform thorough reference checks
                    to verify candidates' qualifications, experience, and work
                    history.
                  </li>
                  <li>
                    <b>Prioritize Diversity and Inclusion:</b> Actively seek
                    diversity in your candidate pool to promote an inclusive
                    workplace, considering diversity and inclusion in your
                    sourcing strategies.
                  </li>
                </ul>
              </Typography>
            </Box>
          </Box>
        </Box>
        {loading ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress />
          </div>
        ) : null}
      </Box>
    </Box>
  );
};

export default FindTalent;
