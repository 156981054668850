import { Box, Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
// Map full values to short display labels
const displayMapping = {
  "Last Week": "7d",
  "Last Month": "1m",
  "Last 3 Months": "3m",
  "Any Time": "All",
};

export const FilterTypes = ({
  selectedTime,
  handleClick,
  handleClose,
  anchorEl,
  open,
  setAnchorElDuration,
}) => {
  return (
    <>
      <Box
        variant="contained"
        size="small"
        sx={{
          background: "#FFAD00",
          px: 1.25,
          py: 0.75,
          display: "flex",
          alignItems: "center",
          gap: 0.5,
          cursor: "pointer",
          color: "#fff",
          borderRadius: 1.5,
          width: "fit-content",
          "&:hover": {
            backgroundColor: "#FFAD00", // Disable the default blue hover color
          },
          textTransform: "capitalize",
        }}
        onClick={handleClick}
      >
        <ScheduleOutlinedIcon sx={{ fontSize: "16px" }} />
        {/* Display the mapped shorter value or default */}
        <span style={{ width: "2.6ch", fontSize: "14px" }}>
          {selectedTime ? displayMapping[selectedTime] : "7d"}
        </span>
        <KeyboardArrowDownOutlinedIcon sx={{ fontSize: "16px" }} />
      </Box >
      <Menu anchorEl={anchorEl} open={open} onClose={setAnchorElDuration}
        MenuListProps={{
          "aria-labelledby": "jobs-menu-button",
        }}
        sx={{
          "& ul": {
            padding: "4px 0",
          },
        }}>
        {["Last Week", "Last Month", "Last 3 Months", "Any Time"].map(
          (filterItem) => (
            <MenuItem
              key={filterItem}
              selected={selectedTime === filterItem}
              onClick={() => handleClose(filterItem)}
              sx={{
                mx: 0.5,
                mb: filterItem === "Any Time" ? "" : 0.5,
                px: 1.5,
                borderRadius: 1,
                fontSize: "12px",
                "&:hover": {
                  background: "#FFAD0015", // Hover effect
                },
                background:
                  selectedTime === filterItem
                    ? "#FFAD00 !important" // Selected background color
                    : "",
                color:
                  selectedTime === filterItem
                    ? "#fff !important" // Selected text color
                    : "",
              }}
            >
              {filterItem}
            </MenuItem>
          )
        )}
      </Menu>
    </>
  );
};
