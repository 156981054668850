import React, { useEffect, useRef, useState } from "react";
import Header from "../components/header";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Container,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import JobInfo from "../components/jobInfo";
import Applicants from "../components/applicants";
import ShareIcon from "@mui/icons-material/Share";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useSelector } from "react-redux";
import Createjobdrawer from "../components/createjobdrawer";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import RecommendedTab from "../components/recommendedTab";
import Shortlisted from "../components/Shortlisted";
import Cookies from "js-cookie";
import DeleteJobDialog from "./DeleteJobDialog";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// const baseURL = process.env.REACT_APP_BASEURL;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(1),
}));

const jobStatusArray = ["Closed", "Full", "Cancelled", "On Hold", "Active"];

const JobDetails = () => {
  const PermissionStatus = useSelector((state) => state.user.permissions);
  const JobEditPermission = PermissionStatus["400"];
  const CandidatePermission = PermissionStatus["100"];
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(2);
  const [link] = useState("https://example.com");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [isEdit, setIsEdit] = useState("");
  const [jobStatus, setJobStatus] = useState(false);
  const anchorRef = useRef(null);
  const [selectedJobStatus, setSelectedJobStatus] = useState(4);
  const InActiveStatus = ["Cancelled", "Closed", "Full"];
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [singleJobStatus, setSingleJobStatus] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [datemodified, setdatemodified] = useState("");
  const [questionrequired, setquestionrequired] = useState("");
  const [editPermission, setEditPermission] = useState(0);
  let url = "/jobeze.com/jobDetail";
  const location = useLocation();
  const onRowClickInJobAssociated = location?.state?.value;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    localStorage.setItem("jobdetailsTab", value.toString());
  }, [value]);

  const handleSnackbarOpen = (message) => {
    console.log("Snackbar message:", message);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const baseUrl = process.env.REACT_APP_CLIP_BOARD_URL;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`${baseUrl}/jobDetail/${id}`);
      setSnackbarMessage("Copied");
      setAlertSeverity("success");
      setSnackbarOpen(true);

      setTimeout(() => {
        setSnackbarOpen(false);
      }, 1500);
    } catch (err) {
      setSnackbarMessage("Failed to copy the link.");
      setAlertSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedJobStatus(index);
    setSingleJobStatus(event);
    setJobStatus(false);
    axios
      .post(
        `change-job-status`,
        {
          status: event,
          job_id: id,
          original_date_modified: datemodified,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          handleSnackbarOpen("Job Status Changed Successfully");
          setSnackbarServerity("success");
        }
        setTimeout(() => {
          handleSnackbarClose();
        }, 1500);
        setTimeout(() => {
          window.location.reload();
        }, 300);
      })
      .catch((err) => {
        console.log(err);
        handleSnackbarOpen(err.response.data.error);
        setSnackbarServerity("error");
      });
  };

  const handleToggle = () => {
    setJobStatus((prevOpen) => !prevOpen);
  };
  const handleEdit = () => {
    const flag = 1;
    navigate(`/Postjob?edit=${flag}&JobId=${id}`);
  };

  const handleJobStatusClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setJobStatus(false);
  };
  const [jobinfo, setJoninfo] = useState([]);
  const getToken = useSelector((state) => state.user.token);
  useEffect(() => {
    axios
      .get(`job-info/${id}`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        setdatemodified(response.data.message.jobInfoResult[0].date_modified);
        setquestionrequired(
          response.data.message.jobInfoResult[0].questionnaire_required
        );
        const Applied = response.data.message.hasAnyoneApplied;
        setJoninfo(response.data.message.jobInfoResult[0]);
        const st = response.data.message.jobInfoResult[0].status;
        const index = jobStatusArray.indexOf(st);
        setSelectedJobStatus(index);
        setEditPermission(response.data.message.jobInfoResult[0].can_edit);
      })
      .catch((err) => {});
    if (onRowClickInJobAssociated === 0) {
      setValue(0);
    }
  }, [isDrawerOpen]);

  const beforeDate = new Date(`${jobinfo?.date_created}`);
  const today = new Date();
  const diffTime = Math.abs(today - beforeDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const dateTimeString = jobinfo?.date_created;
  const backenddate = dateTimeString && dateTimeString.split("T")[0];

  const todays = new Date();
  const formattedDate = todays.toISOString().split("T")[0];

  let displayText;

  if (formattedDate === backenddate) {
    displayText = "Today";
  } else {
    displayText = `${diffDays} day${diffDays !== 1 ? "s" : ""} ago`;
  }

  const IsEmployer = useSelector((state) => state.user.isEmployer);
  const role = useSelector((state) => state.user.role);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [Snackbarserverity, setSnackbarServerity] = useState();

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const [searchParams, setSearchParams] = useSearchParams({});
  const handleBack = () => {
    const previousUrl = sessionStorage.getItem("JobpreviousUrl");

    if (previousUrl) {
      // Parse the URL
      const url = new URL(previousUrl, window.location.origin); // Ensure URL is absolute
      const path = url.pathname; // Extract path
      const queryParams = url.search; // Extract query parameters

      // Navigate using path and query parameters
      navigate(`${path}${queryParams}`);

      // Clean up
      sessionStorage.removeItem("JobpreviousUrl");
    }
  };

  return (
    <Box className="pageWrapper">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
        >
          <Grid
            item
            xs={6}
            sx={{ gap: 2, display: "flex", alignItems: "center" }}
          >
            <Box onClick={handleBack}>
              <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
            </Box>
            <Box>
              <img
                src={jobinfo?.logo}
                style={{ width: "72px", height: "72px" }}
                alt="Logo"
              />
            </Box>
            <Box>
              <Typography
                variant="h6"
                align="left"
                textTransform={"capitalize"}
                sx={{ wordBreak: "break-word" }}
              >
                {jobinfo?.title ? jobinfo?.title : "NA"}
              </Typography>
              <Typography
                variant="body2"
                align="left"
                fontWeight={700}
                textTransform={"capitalize"}
                sx={{ wordBreak: "break-word" }}
              >
                {jobinfo?.title ? jobinfo?.name : "NA"}
              </Typography>
              <Typography
                variant="body2"
                align="left"
                textTransform={"capitalize"}
              >
                {" "}
                {jobinfo?.city ? jobinfo?.city : "NA"},{" "}
                {jobinfo?.state ? jobinfo?.state : "NA"}
              </Typography>
              <Typography variant="body2" align="left">
                Posted {displayText}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              variant="h3"
              component="h1"
            >
              {JobEditPermission?.edit === 0 ? (
                <></>
              ) : JobEditPermission?.edit && editPermission ? (
                <EditIcon
                  sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.6)" }}
                  onClick={handleEdit}
                />
              ) : (
                <></>
              )}

              <ShareIcon
                onClick={copyToClipboard}
                sx={{ color: "rgba(0, 0, 0, 0.6)", mx: 2, cursor: "pointer" }}
              />

              <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert
                  onClose={handleSnackbarClose}
                  severity={alertSeverity}
                  variant="filled"
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>

              {JobEditPermission?.edit === 0 ? (
                <></>
              ) : JobEditPermission?.edit && editPermission ? (
                <>
                  <ButtonGroup
                    variant="contained"
                    ref={anchorRef}
                    aria-label="split button"
                    sx={{ ml: 2 }}
                  >
                    <Button size="small" variant="contained">
                      {jobStatusArray[selectedJobStatus]}
                    </Button>
                    <Button
                      size="small"
                      aria-controls={
                        jobStatus ? "split-button-menu" : undefined
                      }
                      aria-expanded={jobStatus ? "true" : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={handleToggle}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  {JobEditPermission?.delete === 0 ? (
                    <></>
                  ) : JobEditPermission?.delete && editPermission ? (
                    <Box display={"flex"} gap={2} alignItems={"center"}>
                      <Button
                        onClick={handleOpenDeleteDialog}
                        color="error"
                        variant="outlined"
                        sx={{ ml: 2 }}
                        startIcon={<DeleteOutlineOutlinedIcon />}
                      >
                        Delete Job
                      </Button>
                      <DeleteJobDialog
                        Deletename={jobinfo.title}
                        id={id}
                        open={openDeleteDialog}
                        handleClose={handleCloseDeleteDialog}
                        setOpenSnackbar={setOpenSnackbar}
                        setSnackbarMessage={setSnackbarMessage}
                        setSnackbarServerity={setSnackbarServerity}
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Popper
                    sx={{
                      zIndex: 1,
                    }}
                    open={jobStatus}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleJobStatusClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                              {jobStatusArray.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  selected={index === selectedJobStatus}
                                  onClick={() =>
                                    handleMenuItemClick(option, index)
                                  }
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              ) : (
                <></>
              )}

              {/* {JobEditPermission?.edit === 1 && (
                <>
                  <ButtonGroup
                    variant="contained"
                    ref={anchorRef}
                    aria-label="split button"
                    sx={{ ml: 2 }}
                  >
                    <Button size="small" variant="contained">
                      {jobStatusArray[selectedJobStatus]}
                    </Button>
                    <Button
                      size="small"
                      aria-controls={
                        jobStatus ? "split-button-menu" : undefined
                      }
                      aria-expanded={jobStatus ? "true" : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={handleToggle}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  {searchParams.get("jobOrder") === null &&
                    (IsEmployer ||
                      (role === "ADMIN" &&
                        JobEditPermission?.delete === 1)) && (
                      <Box display={"flex"} gap={2} alignItems={"center"}>
                        <Button
                          onClick={handleOpenDeleteDialog}
                          color="error"
                          variant="outlined"
                          sx={{ ml: 2 }}
                          startIcon={<DeleteOutlineOutlinedIcon />}
                        >
                          Delete Job
                        </Button>
                        <DeleteJobDialog
                          Deletename={jobinfo.title}
                          id={id}
                          open={openDeleteDialog}
                          handleClose={handleCloseDeleteDialog}
                          setOpenSnackbar={setOpenSnackbar}
                          setSnackbarMessage={setSnackbarMessage}
                          setSnackbarServerity={setSnackbarServerity}
                        />
                      </Box>
                    )}
                  <Popper
                    sx={{
                      zIndex: 1,
                    }}
                    open={jobStatus}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleJobStatusClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                              {jobStatusArray.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  selected={index === selectedJobStatus}
                                  onClick={() =>
                                    handleMenuItemClick(option, index)
                                  }
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )} */}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            textColor={value === 2 ? "secondary" : "primary"}
            indicatorColor={value === 2 ? "secondary" : "primary"}
            onChange={handleChange}
            aria-label="icon label tabs example"
          >
            <Tab
              label="Job Info"
              sx={{ letterSpacing: "1.25px", px: 4 }}
              {...a11yProps(0)}
            />
            <Tab
              label="Shortlisted"
              sx={{ letterSpacing: "1.25px", px: 4 }}
              {...a11yProps(1)}
            />
            <Tab
              label="Applicants"
              sx={{ letterSpacing: "1.25px", px: 4 }}
              {...a11yProps(2)}
            />
            <Tab
              icon={<AutoAwesomeIcon fontSize="small" />}
              iconPosition="start"
              label="AI Recommendations"
              sx={{ letterSpacing: "1.25px", px: 2 }}
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <JobInfo isDrawerOpen={isDrawerOpen} id={id} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Shortlisted
            questionrequired={questionrequired}
            status={jobStatusArray[selectedJobStatus]}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Applicants
            status={jobStatusArray[selectedJobStatus]}
            questionrequired={questionrequired}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <RecommendedTab status={jobStatusArray[selectedJobStatus]} id={id} />
        </CustomTabPanel>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={Snackbarserverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default JobDetails;
