import { Box, Typography } from '@mui/material';
import React from 'react';




const Mpermissions = ({ rolepermissions }) => {
    return (
        <div>
            <Box display={"flex"} gap={{ xs: 1, sm: 2, md: 15, lg: 15 }}  >
                <Box sx={{ color: "gray", display: "flex", flexDirection: "column", gap: 2 }} >
                    <Typography variant='body1' fontSize={"18px"}>Role Type</Typography>
                    <Typography variant='body1' fontSize={"18px"}>Assigned Under</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }} >
                    <Typography variant='body1' fontSize={"18px"}> {rolepermissions?.role_name}  </Typography>
                    <Typography variant='body1' fontSize={"18px"}> {rolepermissions?.under_assigned_user_name} </Typography>

                </Box>
            </Box>
        </div>
    )
}

export default Mpermissions