import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Cookies from "js-cookie";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";

const CompanyBranches = ({ company_name, is_editable }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [branches, setBranches] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = useState({
    page: Number(searchParams.get("page")) - 1,
    pageSize: 25,
  });
  const [queryOptions, setQueryOptions] = useState({});

  const { id } = useParams();

  const columns = [
    {
      field: "branch_name",
      headerName: "Branch Name",
      width: 400,
      renderCell: (params) => (
        <Typography
          title={params.row.branch_name}
          variant="body1"
          sx={{
            textTransform: "capitalize",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {params.row.branch_name ? params.row.branch_name : "NA"}
        </Typography>
      ),
    },
    {
      field: "city",
      headerName: "City",
      width: 250,
      renderCell: (params) => (params.row.city ? params.row.city : "NA"),
    },
    {
      field: "state",
      headerName: "State",
      width: 250,
      renderCell: (params) => (params.row.state ? params.row.state : "NA"),
    },
    {
      field: "phone",
      headerName: "Mobile",
      width: 250,
      renderCell: (params) => (
        <Typography
          title={"+1 " + params.row.phone}
          variant="body2"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {params.row.phone ? "+1 " + params.row.phone : "NA"}
        </Typography>
      ),
    },
    {
      field: "date_created",
      headerName: "Created On",
      width: 150,
      renderCell: (params) =>
        new Date(params.row.date_created).toLocaleDateString("en-US"),
    },
  ];

  const navigate = useNavigate();

  const HandleRowClick = (params, event) => {
    const data = {
      company_id: id,
      branch_id: params.row.id,
    };
    const currentUrl = window.location.href;
    sessionStorage.setItem("BranchpreviousUrl", currentUrl);
    navigate(
      `/CompanyDetails/${id}/BranchDetails/${params.row.id}?tab=BranchInfo`,
      { state: data }
    );
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setSearchParams({ tab: "companyBranch", search: query, page: 1 });
    setPaginationModel((prevModel) => ({ ...prevModel, page: 0 }));
  };

  const handleSortModelChange = React.useCallback((sortModel) => {
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const handlePaginationModelChange = (model) => {
    setPaginationModel({ ...paginationModel, ...model });
    setSearchParams({
      tab: "companyBranch",
      page: model.page + 1,
      search: searchQuery,
    });
  };

  const getBranches = async () => {
    setShowLoader(true);
    try {
      const payload = {};
      if (queryOptions.sortModel && queryOptions.sortModel.length > 0) {
        payload.sortField = queryOptions?.sortModel[0].field;
        payload.sortBy = queryOptions?.sortModel[0].sort;
      }
      const response = await axios.post(
        `list-of-branches/${id}/${searchParams.get("page")}${searchParams.get("search")
          ? "?search=" + searchParams.get("search")
          : ""
        }`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );
      setBranches(response.data.message?.branchListResponse);
      setTotalCount(response.data.message?.total);
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const query = searchParams.get("search") || "";
    const page = Number(searchParams.get("page")) - 1 || 0;
    setSearchQuery(query);
    setPaginationModel((prevModel) => ({ ...prevModel, page }));
    getBranches();
  }, [searchParams, queryOptions, paginationModel.page]);

  const managePermissions = useSelector((state) => state?.user.permissions);

  const addBranchPermission = managePermissions["500"];
  // console.log("addBranchPermission", addBranchPermission);

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        pb={2.5}
      >
        <Box>
          <TextField
            id="standard-search"
            type="search"
            variant="standard"
            placeholder="Search"
            autoComplete="off"
            fullWidth
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              maxWidth: "100%",
            }}
          />
        </Box>
        {(addBranchPermission.add === 1 && is_editable === 1) ?
          (
            <Box>
              <Link
                to={`/CompanyDetails/${id}/addBranch`}
                state={{ company_name: company_name }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<AddIcon />}
                >
                  Add Branch
                </Button>
              </Link>
            </Box>
          ) : (<></>)}
      </Box>
      <Box>
        <DataGrid
          rows={showLoader ? [] : branches}
          columns={columns}
          autoHeight={showLoader ? true : true}
          paginationMode="server"
          paginationModel={paginationModel}
          loading={showLoader}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[25]}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          rowCount={totalCount}
          onRowClick={HandleRowClick}
          className="data-grid-row-hover"
        />
      </Box>
    </Box>
  );
};

export default CompanyBranches;
