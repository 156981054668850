import { Box, Chip, Stack, Typography } from "@mui/material";
import React from "react";

const BranchDisplayTabs = ({ companiesBranchList }) => {
  return (
    <div>
      {companiesBranchList.length === 0 ? (
        <Typography variant="body1" fontSize={"18px"} gutterBottom >
            The selected companies have no branches.
        </Typography>
      ) : (
        <>
          {companiesBranchList.map((branch, index) => (
            <Box
              display={"flex"}
              key={index}
              mb={2.5}
              gap={{ xs: 1, sm: 2, md: 15, lg: 15 }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "15%",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ textTransform: "capitalize" }}
                  fontSize={"18px"}
                >
                  {branch.company_name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, width: { xs: "100%", sm: "100%", md: "65%", lg: "65%" } }}>
                {branch.branches[0] === -100 ? (
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="All branches"
                        color="primary"
                        size="large"
                        px={2}
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "rgba(130, 130, 130, 1)",
                          borderRadius: 1,
                          fontSize: "15px",
                          p: 2.5,
                          px: 2,
                        }}
                      />
                    </Stack>
                  </Box>
                ) : (
                  branch.branches.map((subBranch, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1.5,
                      }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={subBranch?.branch_name}
                          color="primary"
                          size="large"
                          px={2}
                          sx={{
                            textTransform: "capitalize",
                            backgroundColor: "rgba(130, 130, 130, 1)",
                            borderRadius: 1,
                            fontSize: "15px",
                            p: 2.5,
                            px: 2,
                          }}
                        />
                      </Stack>
                    </Box>
                  ))
                )}
              </Box>
            </Box>
          ))}
        </>
      )}
    </div>
  );
};
export default BranchDisplayTabs;
