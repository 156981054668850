import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";

function DialogBox({
  showNewAdd,
  title,
  closeDialog,
  isDialogOpen,
  comment,
  setComment,
  saveCommentAndStatus,
}) {
  return (
    <Dialog open={isDialogOpen} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        {!showNewAdd ? (
          <Typography variant="body1" className="fw-500" gutterBottom>
            Add Comment
          </Typography>
        ) : null}
        {/* <Typography
            variant="body1"
            className="fw-500"
            gutterBottom
          >
            Add Comment
          </Typography> */}
        <TextField
          fullWidth
          multiline
          rows={4}
          label="Comment"
          variant="outlined"
          value={comment}
          inputProps={{
            maxLength: 500,
          }}
          onChange={(e) => setComment(e.target.value)}
        />
      </DialogContent>
      <Divider />
      <Typography
        style={{ padding: "16px" }}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <Button variant="text" color="primary" onClick={closeDialog}>
          Cancel
        </Button>
        {!showNewAdd ? (
          <Button
            variant="text"
            color="primary"
            onClick={saveCommentAndStatus}
            // disabled={!comment.trim()}
          >
            Save
          </Button>
        ) : (
          <Button
            variant="text"
            color="primary"
            onClick={saveCommentAndStatus}
            disabled={!comment.trim()}
          >
            Save
          </Button>
        )}
      </Typography>
    </Dialog>
  );
}
export default DialogBox;
