import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
  Breadcrumbs,
  Button,
  duration,
} from "@mui/material";
import Tile from "./components/Tile";
import Activities from "./components/activities";
import { Column } from "@ant-design/plots";
import axios from "axios";
import Cookies from "js-cookie";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { DataGrid } from "@mui/x-data-grid";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DurationDropdown from "./components/DurationDropdown";
import TimeSelector from "./components/TimeSelector";
const CandidateDashboard = () => {
  const [timeDuration, setTimeDuration] = useState({
    EmployerPortalNotAssociated: "Last Week",
    JobezePortalTotal: "Last Week",
  }); // for the time duration
  const [highlightedTile, setHighlightedTile] = useState(
    "Candidates Associated with Job"
  ); //for which tile is highlighted in the dashboard
  const [activities, setActivities] = useState([]); // for the activities
  const [counts, setCounts] = useState({
    EmployerPortalAssociated: 0,
    EmployerPortalNotAssociated: 0,
    JobezePortalAssociated: 0,
  }); // count of the number in the  3 tiles in the dashboard
  const [showGraphDatagrid, setShowGraphDatagrid] = useState(false);
  const [loading, setLoading] = useState({
    loading: false, // for the rest of the tiles
    activityLoading: false, //for the activities table
  }); // Loading state
  const chartNumber = useRef(1); // 1 for chart 1 and 2 for chart 2
  const [chartData, setChartData] = useState([]); // for the chart
  const [breadcrumb, setBreadcrumb] = useState([""]); // for the breadcrumb

  //datagrid States
  const [gridData, setGridData] = useState([]); // for the datagrid
  const [page, setPage] = useState(1); // for the datagrid
  const [pageSize] = useState(10); // for the datagrid
  const [disablePrev, setDisablePrev] = useState(true); // for the datagrid
  const [disableNext, setDisableNext] = useState(false); // for the datagrid
  const [dataGridRecruiterId, setDataGridRecruiterId] = useState(null); // for the datagrid
  const [statusIds, setStatusIds] = useState(null); // for the datagrid
  const isFirstLoad = useRef(true); // for the datagrid

  const handleClick = (title) => {
    setHighlightedTile(title);
    chartNumber.current = 1;
    setPage(1);
    setShowGraphDatagrid(false);
  }; // for the tile click

  const fetchTotalCount = async () => {
    let portalname = "";
    let duration = "";
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      portalname = "";
      duration = "";
    } else {
      if (highlightedTile === "Total Candidates") {
        portalname = "Jobeze Portal";
        duration = timeDuration.JobezePortalTotal;
      } else if (
        highlightedTile === "Candidates not Associated with Job" ||
        highlightedTile === "Candidates Associated with Job"
      ) {
        portalname = "Employer Portal";
        duration = timeDuration.EmployerPortalNotAssociated;
      }
    }

    let payload = {
      portal: portalname,
      time_span: duration,
    };
    try {
      const response = await axios.post(`dashboard/candidate-count`, payload, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      if (response.data.success) {
        const data = response.data.message;
        setCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };

          data.forEach((item) => {
            if (
              item.portal === "Employer Portal" &&
              item.Type === "Associated with Job"
            ) {
              updatedCounts.EmployerPortalAssociated = item.count;
            } else if (
              item.portal === "Employer Portal" &&
              item.Type === "Not Associated with Job"
            ) {
              updatedCounts.EmployerPortalNotAssociated = item.count;
            } else if (
              item.portal === "Jobeze Portal" &&
              item.Type === "Associated with Job"
            ) {
              updatedCounts.JobezePortalAssociated = item.count;
            }
          });

          return updatedCounts;
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const fetchActivities = async () => {
    setLoading((prevLoading) => ({ ...prevLoading, activityLoading: true })); // Start loading activities
    try {
      const response = await axios.get(`dashboard/candidate-activities`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      if (response.data.success) {
        const data = response.data.message;
        setActivities(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, activityLoading: false })); // Stop loading activities
    }
  };

  //first chart by default api call
  const fetchRecruiterchart = async () => {
    setLoading((prevLoading) => ({ ...prevLoading, loading: true }));
    let portalname = "";
    let duration = "";
    if (
      highlightedTile === "Candidates Associated with Job" ||
      highlightedTile === "Candidates not Associated with Job"
    ) {
      portalname = "EMPLOYER PORTAL";
      duration = timeDuration.EmployerPortalNotAssociated;
    } else if (highlightedTile === "Total Candidates") {
      portalname = "JOBEZE PORTAL";
      duration = timeDuration.JobezePortalTotal;
    }

    let payload = {
      portal: portalname,
      duration: duration,
      isAssociatedWithJob:
        highlightedTile === "Candidates Associated with Job" ? 1 : 0,
    };
    try {
      const response = await axios.post(
        `/dashboard/candidate/recruiters`,
        payload,
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );
      if (response.data.success) {
        const data = response.data.message;
        setChartData(data);
        if (highlightedTile === "Total Candidates") {
          setBreadcrumb(["Jobeze Portal", "Associated", "Recruiters"]);
        } else if (highlightedTile === "Candidates Associated with Job") {
          setBreadcrumb(["Employer Portal", "Associated", "Recruiters"]);
        } else {
          setBreadcrumb(["Employer Portal", "Not Associated", "Recruiters"]);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, loading: false }));
    }
  };

  //chart 2 api function on click of the first chart
  const fetchCandidatestatus = async (currentAxisId) => {
    setLoading((prevLoading) => ({ ...prevLoading, loading: true }));
    if (chartNumber.current == 2) {
      let portalname = "";
      let duration = "";
      let isAssociatedWithJob = 0;
      if (
        highlightedTile === "Candidates Associated with Job" ||
        highlightedTile === "Candidates not Associated with Job"
      ) {
        portalname = "EMPLOYER PORTAL";
        duration = timeDuration.EmployerPortalNotAssociated;
        isAssociatedWithJob =
          highlightedTile === "Candidates Associated with Job" ? 1 : 0;
      } else if (highlightedTile === "Total Candidates") {
        portalname = "JOBEZE PORTAL";
        duration = timeDuration.JobezePortalTotal;
        isAssociatedWithJob = 1;
      }
      setDataGridRecruiterId(currentAxisId);
      const payload = {
        portal: portalname,
        isAssociatedWithJob: isAssociatedWithJob,
        recruiter_id: currentAxisId,
        duration: duration,
      };
      try {
        const response = await axios.post(
          `/dashboard/candidate/candidate-status`,
          payload,
          {
            headers: {
              authorization:
                Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
            },
          }
        );
        if (response.data.success) {
          const data = response.data.message;
          setLoading((prevLoading) => ({ ...prevLoading, loading: true }));
          // Find the "Applied" and "Employer Applied" objects
          const appliedData = data.find((item) => item.name === "Applied");
          const employerAppliedData = data.find(
            (item) => item.name === "Employer Applied"
          );

          if (appliedData && employerAppliedData) {
            // Combine their counts into one object
            appliedData.count += employerAppliedData.count;
            // Remove "Employer Applied" from the array
            const filteredData = data.filter(
              (item) => item.name !== "Employer Applied"
            );
            setChartData(filteredData);
          } else if (employerAppliedData && !appliedData) {
            // Change "Employer Applied" to "Applied"
            employerAppliedData.name = "Applied";
            setChartData(data);
          } else {
            // If only "Applied" exists or neither exists, just set the data as is
            setChartData(data);
          }
          if (highlightedTile === "Candidates Associated with Job") {
            setBreadcrumb([
              "Employer Portal",
              "Associated",
              "Recruiters",
              "Candidate Status",
            ]);
          } else if (highlightedTile === "Total Candidates") {
            setBreadcrumb([
              "Jobeze Portal",
              "Associated",
              "Recruiters",
              "Candidate Status",
            ]);
          }
        }
      } catch (error) {
        console.log(error);
        setLoading((prevLoading) => ({ ...prevLoading, loading: false }));
      } finally {
        setTimeout(() => {
          setLoading((prevLoading) => ({ ...prevLoading, loading: false }));
        }, 1000);
      }
    }
  };

  //data grid 2 functions on click of the first chart
  const fetchCandidatestatusGrid = async (currentAxisId, page) => {
    setLoading((prevLoading) => ({ ...prevLoading, loading: true }));
    let portalname = "";
    let duration = "";
    let isAssociatedWithJob = 0;
    if (
      highlightedTile === "Candidates Associated with Job" ||
      highlightedTile === "Candidates not Associated with Job"
    ) {
      portalname = "EMPLOYER PORTAL";
      duration = timeDuration.EmployerPortalNotAssociated;
      isAssociatedWithJob =
        highlightedTile === "Candidates Associated with Job" ? 1 : 0;
    } else if (highlightedTile === "Total Candidates") {
      portalname = "JOBEZE PORTAL";
      duration = timeDuration.JobezePortalTotal;
      isAssociatedWithJob = 1;
    }
    const payload = {
      portal: portalname,
      isAssociatedWithJob: isAssociatedWithJob,
      recruiter_id: currentAxisId,
      duration: duration,
    };
    if (chartNumber.current == 2) {
      try {
        const response = await axios.post(
          `/dashboard/candidate/status-tablular-data/${page}`,
          payload,
          {
            headers: {
              authorization:
                Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
            },
          }
        );
        if (response.data.success) {
          const data = response.data.message;
          const rows = data.map((row, index) => {
            const updatedRow = {};

            for (const key in row) {
              if (key === "Experience") {
                const experience = row[key];
                if (experience === "0" || experience === "0.0") {
                  updatedRow[key] = "Fresher";
                } else if (experience) {
                  const [years, months] = experience.split(".").map(Number);

                  let experienceString = "";
                  if (years > 0) {
                    experienceString += `${years} year${years !== 1 ? "s" : ""
                      }`;
                  }
                  if (months > 0) {
                    if (experienceString) {
                      experienceString += " ";
                    }
                    experienceString += `${months} month${months !== 1 ? "s" : ""
                      }`;
                  }

                  updatedRow[key] = experienceString || "NA";
                } else {
                  updatedRow[key] = "NA";
                }
              } else {
                // Set 'NA' for null or empty string values
                updatedRow[key] =
                  row[key] === null || row[key] === "" ? "NA" : row[key];
              }
            }

            // Create a combined unique id using index and response id
            return {
              uniqueId: `${index}-${row.id}`, // Combined id (index + original id)
              id: row.id, // Preserve the original id for display
              ...updatedRow, // Spread the updatedRow fields
            };
          });

          setGridData(rows);

          setDisableNext(data?.length < pageSize);
          setDisablePrev(page === 1);
        }
      } catch (error) {
        console.log(error);
        setLoading((prevLoading) => ({ ...prevLoading, loading: false }));
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, loading: false }));
      }
    }
  };

  //THIRD DATA GRID LAST CLICK
  const fetchstatusTypeGrid = async (status, page) => {
    setLoading((prevLoading) => ({ ...prevLoading, loading: true }));
    if (chartNumber.current == 3) {
      let portalname = "";
      let duration = "";
      let isAssociatedWithJob = 0;
      if (
        highlightedTile === "Candidates Associated with Job" ||
        highlightedTile === "Candidates not Associated with Job"
      ) {
        portalname = "EMPLOYER PORTAL";
        duration = timeDuration.EmployerPortalNotAssociated;
        isAssociatedWithJob =
          highlightedTile === "Candidates Associated with Job" ? 1 : 0;
      } else if (highlightedTile === "Total Candidates") {
        portalname = "JOBEZE PORTAL";
        duration = timeDuration.JobezePortalTotal;
        isAssociatedWithJob = 1;
      }
      setStatusIds(status);
      const payload = {
        portal: portalname,
        isAssociatedWithJob: isAssociatedWithJob,
        duration: duration,
        recruiter_id: dataGridRecruiterId,
        status: status,
      };
      try {
        const response = await axios.post(
          `/dashboard/candidate/particular-status-data/${page}`,
          payload,
          {
            headers: {
              authorization:
                Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
            },
          }
        );
        if (response.data.success) {
          const data = response.data.message;
          const rows = data.map((row, index) => {
            const updatedRow = {};

            for (const key in row) {
              if (key === "Experience") {
                const experience = row[key];
                if (experience === "0" || experience === "0.0") {
                  updatedRow[key] = "Fresher";
                } else if (experience) {
                  const [years, months] = experience.split(".").map(Number);

                  let experienceString = "";
                  if (years > 0) {
                    experienceString += `${years} year${years !== 1 ? "s" : ""
                      }`;
                  }
                  if (months > 0) {
                    if (experienceString) {
                      experienceString += " ";
                    }
                    experienceString += `${months} month${months !== 1 ? "s" : ""
                      }`;
                  }

                  updatedRow[key] = experienceString || "NA";
                } else {
                  updatedRow[key] = "NA";
                }
              } else {
                // Set 'NA' for null or empty string values
                updatedRow[key] =
                  row[key] === null || row[key] === "" ? "NA" : row[key];
              }
            }

            // Create a combined unique id using index and response id
            return {
              uniqueId: `${index}-${row.id}`, // Combined id (index + original id)
              id: row.id, // Preserve the original id for display
              ...updatedRow, // Spread the updatedRow fields
            };
          });

          setGridData(rows);
          setDisableNext(data?.length < pageSize);
          setDisablePrev(page === 1);
          if (highlightedTile === "Total Candidates") {
            setBreadcrumb([
              "Jobeze Portal",
              "Associated",
              "Recruiters",
              "Candidate Status",
              "Status Type",
            ]);
          } else {
            setBreadcrumb([
              "Employer Portal",
              "Associated",
              "Recruiters",
              "Candidate Status",
              "Status Type",
            ]);
          }
        }
      } catch (error) {
        console.log(error);
        setLoading((prevLoading) => ({ ...prevLoading, loading: false }));
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, loading: false }));
      }
    }
  };

  //NOT ASSOCIATED GRID
  const fetchNotassociatedGrid = async (currentAxisId, page) => {
    setLoading((prevLoading) => ({ ...prevLoading, loading: true }));
    if (
      chartNumber.current == 2 &&
      highlightedTile === "Candidates not Associated with Job"
    ) {
      let durations = "";
      if (
        highlightedTile === "Candidates Associated with Job" ||
        highlightedTile === "Candidates not Associated with Job"
      ) {
        durations = timeDuration.EmployerPortalNotAssociated;
      } else if (highlightedTile === "Total Candidates") {
        durations = timeDuration.JobezePortalTotal;
      }
      const payload = {
        recruiter_id: currentAxisId,
        duration: durations,
      };
      setDataGridRecruiterId(currentAxisId);
      try {
        const response = await axios.post(
          `/dashboard/candidate/not-associated/${page}`,
          payload,
          {
            headers: {
              authorization:
                Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
            },
          }
        );
        if (response.data.success) {
          const data = response.data.message;
          const rows = data.map((row, index) => {
            const updatedRow = {};

            // Function to format date into "YYYY-MM-DD"
            const formatDate = (isoDateString) => {
              const date = new Date(isoDateString);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
              const day = String(date.getDate()).padStart(2, "0");
              return `${year}-${month}-${day}`;
            };

            for (const key in row) {
              if (key === "Experience") {
                const experience = row[key];
                if (experience === "0" || experience === "0.0") {
                  updatedRow[key] = "Fresher";
                } else if (experience) {
                  const [years, months] = experience.split(".").map(Number);

                  let experienceString = "";
                  if (years > 0) {
                    experienceString += `${years} year${years !== 1 ? "s" : ""
                      }`;
                  }
                  if (months > 0) {
                    if (experienceString) {
                      experienceString += " ";
                    }
                    experienceString += `${months} month${months !== 1 ? "s" : ""
                      }`;
                  }

                  updatedRow[key] = experienceString || "NA";
                } else {
                  updatedRow[key] = "NA";
                }
              } else if (key === "Date Created") {
                updatedRow[key] = formatDate(row[key]); // Format the Date Created
              } else {
                // Set 'NA' for null or empty string values
                updatedRow[key] =
                  row[key] === null || row[key] === "" ? "NA" : row[key];
              }
            }

            // Create a combined unique id using index and response id
            return {
              uniqueId: `${index}-${row.id}`, // Combined id (index + original id)
              id: row.id, // Preserve the original id for display
              ...updatedRow, // Spread the updatedRow fields
            };
          });

          setGridData(rows);
          setDisableNext(data?.length < pageSize);
          setDisablePrev(page === 1);
          setBreadcrumb([
            "Employer Portal",
            "Not Associated",
            "Recruiters",
            "Candidate List",
          ]);
        }
      } catch (error) {
        console.log(error);
        setLoading((prevLoading) => ({ ...prevLoading, loading: false }));
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, loading: false }));
      }
    }
  };
  const handlePageChange = (newPage) => {
    setPage(newPage.page);
    if (
      chartNumber.current == 2 &&
      (highlightedTile === "Candidates Associated with Job" ||
        highlightedTile === "Total Candidates")
    ) {
      fetchCandidatestatusGrid(dataGridRecruiterId, newPage.page);
    } else if (
      chartNumber.current == 3 &&
      (highlightedTile === "Candidates Associated with Job" ||
        highlightedTile === "Total Candidates")
    ) {
      fetchstatusTypeGrid(statusIds, newPage.page);
    } else if (
      chartNumber.current == 2 &&
      highlightedTile === "Candidates not Associated with Job"
    ) {
      fetchNotassociatedGrid(dataGridRecruiterId, newPage.page);
    }
  };

  const columns = Object.keys(gridData[0] || {})
    .filter((key) => key !== "uniqueId") // Exclude the 'uniqueId' field from columns
    .map((key, index) => {
      let width = 200;

      // Transform the key to a more readable header name
      let transformed = key.toUpperCase().split("_").join(" ");
      transformed = transformed
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");

      return {
        field: key,
        headerName: transformed,
        width: width,
      };
    });

  useEffect(() => {
    fetchTotalCount(); // Fetch data whenever highlightedTile or timeDuration changes
    fetchRecruiterchart();
  }, [highlightedTile, timeDuration]);

  const handleListClick = () => {
    setShowGraphDatagrid(true); // Show the datagrid and graph based on true or false on click of the icon
  };

  useEffect(() => {
    handleClick("Candidates Associated with Job");
    fetchActivities();
  }, []);

  const handleBreadcrumbClick = async (index) => {
    const slicedBreadcrumb = breadcrumb.slice(0, index + 1);
    setBreadcrumb(slicedBreadcrumb);
    if (slicedBreadcrumb?.length === 3) {
      setShowGraphDatagrid(false);
      fetchRecruiterchart();
      chartNumber.current = 1;
      setPage(1);
    } else if (slicedBreadcrumb?.length === 4) {
      setShowGraphDatagrid(false);
      setPage(1);
      chartNumber.current = 2;
      fetchCandidatestatusGrid(dataGridRecruiterId, 1);
      fetchCandidatestatus(dataGridRecruiterId);
      if (highlightedTile === "Candidates not Associated with Job") {
        fetchNotassociatedGrid(dataGridRecruiterId, 1);
      }
    }
  };
  const maxYaxis = (data) => {
    const yaxisCount = {
      max: 5,
      tickcount: 5,
    };

    const maxCount = Math.max(...data.map((item) => item.count));

    if (maxCount + 1 <= 5) {
      yaxisCount.max = maxCount + 1;
      yaxisCount.tickcount = maxCount + 1;
    } else {
      const tempMax = Math.floor(maxCount / 5);
      yaxisCount.max = maxCount + tempMax;
    }

    return yaxisCount;
  };

  const yaxisCount = maxYaxis(chartData);

  const Handleratio = () => {
    const ratio = 10 / chartData?.length;
    if (ratio >= 1) {
      return null;
    } else {
      return ratio;
    }
  };

  const getChartHeight = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1600) {
      return 524; // Height for extra-large screens (1600px+)
    } else if (screenWidth >= 1200) {
      return 310; // Height for large screens (1200px+)
    } else {
      return 240; // Default height for smaller screens
    }
  };
  const updatedChartData = chartData.map((item) => {
    const formattedName = item?.name
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first character and first character after each space

    return {
      ...item,
      nameWithId: `${formattedName} (${item.id})`,
    };
  });
  const axisTitle =
    breadcrumb.length === 3 ? "Recruiters" : "Candidates Status";
  const displayChartdata = () => {
    const config = {
      data: updatedChartData,
      xField: "name",
      yField: "count",
      height: getChartHeight(),
      style: {
        radiusTopLeft: 10,
        radiusTopRight: 10,
      },
      scrollbar: {
        x: {
          ratio: Handleratio(),
        },
      },
      scale: {
        y: {
          tickCount: yaxisCount.tickcount,
          domainMax: yaxisCount.max,
        },
      },
      animation: false, // Ensure this is set
      animation: {
        enter: {
          duration: 0,
        },
        exit: {
          duration: 0,
        },
      }, // Explicitly set animation options
      axis: {
        x: {
          labelSpacing: 8,
          labelFontSize: 14,
          labelFontWeight: 600,
          labelAlign: "center",
          labelTransform: "Capitalize",
          labelFormatter: (val) => {
            // Define specific mappings
            const mapping = {
              "Offer Accepted (2000)": "Off. Acc.",
              "Offer Rejected (3000)": "Off. Rej.",
              "Offer Released (2500)": "Off. Rel.",
              "Tech Evaluation (2200)": "Tech. Eval.",
              "Manager Evaluation (2300)": "Man. Eval.",
            };
            // Check if the value has a specific mapping
            if (mapping[val]) {
              return mapping[val];
            }
            // Split the value into parts
            const parts = val.split(" ")[0];
            // Handle cases with more than one part but three or fewer parts
            if (parts.length > 1) {
              return parts;
            }
            // If the value is a single word, return it as-is
            return val;
          },
          title: axisTitle,
          line: {
            style: {
              stroke: "#000", // Color of the x-axis line
              lineWidth: 1, // Width of the x-axis line
            },
          },
          grid: null, // Disable grid lines for x-axis
        },
        y: {
          title: "No. of Candidates",
          labelSpacing: 4,
          labelFontSize: 14,
          labelFontWeight: 500,
          line: {
            style: {
              stroke: "#000", // Color of the y-axis line
              lineWidth: 1, // Width of the y-axis line
            },
          },
          grid: null, // Disable grid lines for y-axis
        },
      },
      onReady: ({ chart }) => {
        chart.on(`interval:click`, (ev) => {
          if (chartNumber.current < 3) {
            chartNumber.current = chartNumber.current + 1;
            if (
              chartNumber.current === 2 &&
              (highlightedTile === "Candidates Associated with Job" ||
                highlightedTile === "Total Candidates")
            ) {
              fetchCandidatestatus(ev.data.data.id);
              fetchCandidatestatusGrid(ev.data.data.id, 1);
            } else if (
              chartNumber.current === 3 &&
              (highlightedTile === "Candidates Associated with Job" ||
                highlightedTile === "Total Candidates")
            ) {
              fetchstatusTypeGrid(ev.data.data.id, 1);
            } else if (
              chartNumber.current === 2 &&
              highlightedTile === "Candidates not Associated with Job"
            ) {
              fetchNotassociatedGrid(ev.data.data.id, 1);
            }
          } else {
            chartNumber.current = 1;
          }
        });
      },
    };
    return config;
  };
  const getdatagridheight = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1600) {
      return 540; // Height for extra-large screens (1600px+)
    } else if (screenWidth >= 1200) {
      return 340; // Height for large screens (1200px+)
    } else {
      return 240; // Default height for smaller screens
    }
  };

  //used for time duration , tile onChange and tile click works both same time in dashboard

  // const handleTimeDurationChange = (e, associatedTile) => {

  //   // Check if the Jobeze Portal duration is changed
  //   if (associatedTile === "Jobeze Portal") {
  //     setTimeDuration({
  //       ...timeDuration,
  //       JobezePortalTotal: e.target.value,
  //     });
  //     // Trigger the handleClick for "Total Candidates"
  //     handleClick("Total Candidates");
  //   }
  //   else if (highlightedTile === "Total Candidates" && associatedTile === "Employer Portal") {
  //     handleClick("Candidates Associated with Job");
  //     setTimeDuration({
  //       ...timeDuration,
  //       EmployerPortalNotAssociated: e.target.value,
  //     });
  //   }
  //   else {
  //     setTimeDuration({
  //       ...timeDuration,
  //       EmployerPortalNotAssociated: e.target.value,
  //     });
  //   }
  // };
  const [jobsFilterOption, setJobsFilterOption] = useState({
    label: "Last Week",
    value: "7d",
  });

  const handleEmployerportalTimeDuration = (data) => {
    if (timeDuration.EmployerPortalNotAssociated === data.label) { return; }
    setJobsFilterOption(data);
    if (highlightedTile === "Total Candidates") {
      handleClick("Candidates Associated with Job");
      setTimeDuration({
        ...timeDuration,
        EmployerPortalNotAssociated: data.label,
      });
    }
    else {
      setTimeDuration({
        ...timeDuration,
        EmployerPortalNotAssociated: data.label,
      });
    }
  };
  const handleJobezePortalTimeDuration = (data) => {
    if (timeDuration.JobezePortalTotal === data.label) { return; }
    setJobsFilterOption(data);
    setTimeDuration({
      ...timeDuration,
      JobezePortalTotal: data.label,
    });
    // Trigger the handleClick for "Total Candidates"
    handleClick("Total Candidates");
  };
  return (
    <Box>
      <Grid container>
        <Grid item xs={8}>
          <Box
            sx={{
              width: "100%",
              borderRadius: "10px",
            }}
          >
            <Grid container spacing={2} width={"100%"}>
              {/* First Grid item */}
              <Grid item xs={12} md={8} sx={{ width: "70%" }} >
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                    p: 1.5,
                    width: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Grid container spacing={2} sx={{ width: "100%" }}>
                    <Grid item xs={12} md={6} sx={{ width: "50%" }}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: 'bold',
                          whiteSpace: "nowrap",
                          height: "30px",
                          fontSize: '18px',
                          marginBottom: "15px",
                        }}
                      >
                        {"Employer Portal"}
                      </Typography>
                      <Tile
                        count={counts.EmployerPortalAssociated}
                        total={
                          counts.EmployerPortalAssociated +
                          counts.EmployerPortalNotAssociated
                        }
                        description="Candidates Associated with Job"
                        highlighted={
                          highlightedTile === "Candidates Associated with Job"
                        }
                        onClick={() => handleClick("Candidates Associated with Job")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ width: "50%" }} >
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontWeight: 'bold',
                            width: "100%",
                            whiteSpace: "nowrap",
                            fontSize: '18px',
                            height: "30px",
                            marginBottom: "10px",
                          }}
                        >
                          {"            "}
                        </Typography>
                        <Box sx={{ marginBottom: "10px" }}>
                          <TimeSelector
                            initialValue={jobsFilterOption}
                            handleFilters={handleEmployerportalTimeDuration}
                          />
                        </Box>
                      </Box>
                      <Tile
                        count={counts.EmployerPortalNotAssociated}
                        total={
                          counts.EmployerPortalAssociated +
                          counts.EmployerPortalNotAssociated
                        }
                        description="Candidates not Associated with Job"
                        highlighted={
                          highlightedTile === "Candidates not Associated with Job"
                        }
                        onClick={() => handleClick("Candidates not Associated with Job")}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/* Second Grid item */}
              <Grid item xs={12} md={4} sx={{ width: "30%" }}>
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                    p: 1.5,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: 'bold',
                        width: "100%",
                        whiteSpace: "nowrap",
                        height: "30px",
                        fontSize: '18px',
                        marginBottom: "15px",
                      }}
                    >
                      {"Jobeze Portal"}
                    </Typography>
                    <Box sx={{ marginBottom: "10px" }}>
                      <TimeSelector
                        initialValue={jobsFilterOption}
                        handleFilters={handleJobezePortalTimeDuration}
                      />
                    </Box>
                  </Box>
                  <Tile
                    count={counts.JobezePortalAssociated}
                    description="Total Candidates"
                    highlighted={highlightedTile === "Total Candidates"}
                    onClick={() => handleClick("Total Candidates")}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 2, mr: 2 }} style={{ borderRadius: "10px" }}>
            <Paper elevation={0} style={{ borderRadius: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#F9F9F9",
                  padding: "8px",
                  borderRadius: "10px 10px 0 0",
                }}
              >
                <Breadcrumbs separator="›">
                  {breadcrumb.map((crumb, index) => (
                    <React.Fragment key={index}>
                      {index === 0 || index === 1 ? (
                        <Typography
                          color="#737791"
                          style={{ fontSize: "18px" }}
                        >
                          {crumb}
                        </Typography>
                      ) : (
                        <Typography
                          onClick={() => handleBreadcrumbClick(index)}
                          href=""
                          style={{
                            fontSize: "18px",
                            fontWeight: index === breadcrumb.length - 1 ? "bold" : "bold",
                            color: index === breadcrumb.length - 1 ? "#3F95EA" : "black",
                            cursor: "pointer",
                          }}
                        >
                          {crumb}
                        </Typography>
                      )}
                    </React.Fragment>
                  ))}
                </Breadcrumbs>
                {(highlightedTile === "Candidates Associated with Job" ||
                  highlightedTile === "Total Candidates") &&
                  chartNumber.current === 2 && (
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        aria-label="bar"
                        onClick={() => setShowGraphDatagrid(false)}
                      >
                        <BarChartOutlinedIcon
                          color={showGraphDatagrid ? "default" : "primary"}
                        />
                      </IconButton>
                      <IconButton aria-label="list" onClick={handleListClick}>
                        <FormatListBulletedIcon
                          color={showGraphDatagrid ? "primary" : "default"}
                        />
                      </IconButton>
                    </Box>
                  )}
              </Box>
              {loading.loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: getdatagridheight(),
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (chartNumber.current === 2 &&
                highlightedTile === "Candidates not Associated with Job") ||
                showGraphDatagrid ||
                chartNumber.current === 3 ? (
                <div
                  style={{
                    height: getdatagridheight(),
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <DataGrid
                    rows={gridData}
                    columns={columns}
                    getRowId={(row) => row.uniqueId}
                    pageSize={pageSize}
                    rowsPerPageOptions={[5]}
                    pagination
                    page={page - 1}
                    onPageChange={handlePageChange}
                    paginationMode="server"
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                    components={{
                      Pagination: (props) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginRight: "10px",
                          }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => handlePageChange({ page: page - 1 })}
                            disabled={disablePrev}
                            style={{ marginRight: "10px" }}
                          >
                            <NavigateBeforeIcon />
                          </Button>
                          <Typography variant="body1" pt={0.8}>
                            Page - {page}
                          </Typography>
                          <Button
                            variant="outlined"
                            onClick={() => handlePageChange({ page: page + 1 })}
                            disabled={disableNext}
                            style={{ marginLeft: "10px" }}
                          >
                            <NavigateNextIcon />
                          </Button>
                        </div>
                      ),
                    }}
                    sx={{
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#03253D",
                        color: "#fff",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        color: "#fff",
                      },
                      "& .MuiDataGrid-row:nth-of-type(odd)": {
                        backgroundColor: "#EFEFEF",
                      },
                      "& .MuiDataGrid-row:nth-of-type(even)": {
                        backgroundColor: "#fff", // Default color for even rows (optional, if needed)
                      },
                    }}
                  />
                </div>
              ) : (
                <Box style={{ borderRadius: "10px" }}>
                  <Column {...displayChartdata()} />
                </Box>
              )}
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Paper
            elevation={0}
            sx={{ height: "100%", borderRadius: "10px", border: "none" }}
          >
            {loading.activityLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2.5,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    background: "#F9F9F9",
                    p: 2,
                    borderRadius: "8px 8px 0 0",
                  }}
                  textAlign={"start"}

                // padding={1}
                >
                  Recent Activities
                </Typography>
                <Box
                  sx={{
                    height: "calc(100vh - 281px)",
                    position: "relative",
                    overflowY: "scroll",
                    px: 2,
                  }}
                >
                  <Activities
                    activityData={activities}
                    loading={loading.activityLoading}
                  />
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CandidateDashboard;
