import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import QuestionList from "./QuestionList";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Questionpop = (props) => {
  const [quesErrorHandler, setQuesErrorHandler] = useState({});
  const [question, setQuestion] = useState([]);
  const getToken = useSelector((state) => state.user.token);
  const { id } = useParams();

  useEffect(() => {
    getQuestionList();
  }, [props.Joborder_id]);

  const temp = (value, type) => {
    if (type === "tel") {
      if (value.length === 0) {
        return "This field is required";
      } else if (value.trim().length >= 13) {
        return "Maximum 12 digits allowed";
      } else {
        return null;
      }
    } else if (type === "textarea") {
      if (value.length === 0) {
        return "This field is required";
      } else if (value.trim().length >= 501) {
        return "Maximum 500 characters allowed";
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const handleTextFieldChange = (e, id, index) => {
    const { value, type } = e.target;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    let tempArr = [...props?.quesAnsArray];
    tempArr[index].answer_text = capitalizedValue;
    tempArr[index].answer_id = tempArr[index]?.ques_ans[0]?.answer_id;
    tempArr[index].error = temp(capitalizedValue, type);
    props.setQuesAnsArray(tempArr);
    props.setTextFields((prevState) => ({
      ...prevState,
      [id]: capitalizedValue,
    }));
  };

  const handleRadioChange = (e, id, index) => {
    let tempArr = [...props.quesAnsArray];
    const { value } = e.target;
    props.setRadioValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    let i = props.quesAnsArray[index]?.ques_ans?.findIndex(
      (e) => e?.answer_id == value
    );

    tempArr[index].answer_text = tempArr[index]?.ques_ans[i]?.value;
    tempArr[index].answer_id = tempArr[index]?.ques_ans[i]?.answer_id;
    tempArr[index].error = null;
    props.setQuesAnsArray(tempArr);
  };

  function getQuestionList() {
    if (!(props.Joborder_id || id)) return;
    axios
      .post(
        `question-list-job/${id || props.Joborder_id}`,
        { applyFilter: 0 },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((res) => {
        setQuestion(res.data.message);
        const a = res.data.message.map((question) => {
          return {
            question_types: question.question_type,
            question_id: question.question_id,
            ques_ans: question.ques_ans,
            answer_text: "",
            error: null,
          };
        });
        props.setQuesAnsArray(a);
        console.log();
      })
      .catch((error) => {
        console.error("Error fetching question list:", error);
      });
  }

  const handleQuesPopupClose1 = () => {
    props.setTextFields({});
    props.setRadioValues({});
    const a = props.quesAnsArray.map((question) => {
      return {
        question_types: question.question_type,
        question_id: question.question_id,
        ques_ans: question.ques_ans,
        answer_text: "",
        error: null,
      };
    });
    props.setQuesAnsArray(a);
    props.handleQuesPopupClose();
  };

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.quesOpenPopup}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "12px",
            height: { xs: "auto", sm: "auto", md: "80vh" },
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Adding candidate to
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleQuesPopupClose1}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <QuestionList
            question={question}
            handleTextFieldChange={handleTextFieldChange}
            textFields={props.textFields}
            setTextFields={props.setTextFields}
            handleRadioChange={handleRadioChange}
            radioValues={props.radioValues}
            setRadioValues={props.setRadioValues}
            quesErrorHandler={quesErrorHandler}
            setQuesErrorHandler={setQuesErrorHandler}
            quesAnsArray={props.quesAnsArray}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleQuesPopupClose1}>
            Cancel
          </Button>
          <Button autoFocus onClick={props.handleQuessummit}>
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default Questionpop;
