import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreatedByCheckbox = ({ candidateList, handleSelectionData, selectedOptions, username }) => {



  return (
    <Box width={"100%"}>
      <Autocomplete
        multiple
        fullWidth
        size="small"
        // sx={{ width: "100%" }}
        id="checkboxes-tags-demo"
        key={candidateList}
        options={candidateList}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        onChange={handleSelectionData}
        // defaultValue={selectedOptions}
        value={selectedOptions || []}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <>
            <li  {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                //   style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>

          </>

        )}
        // style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            label={`${username}`}
            placeholder="Search here... "


          />
        )}

      />

    </Box>
  );
}

export default CreatedByCheckbox; 