import { Box, Grid, IconButton, Typography } from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Comment from "./Comment";
import React, {useState} from "react";

const CandidateActivities = ({candidatedetails,selectedRowData,setrefreshagain,
  refreshagain,activitesrefresh,setactivitesrefresh,setnewvalue,setstatusupdate,selectedStatus,handleSnackbarOpen}) => {
  const [showComment,setShowComment] = useState(false);
  const activities = candidatedetails.activities;

  const currentDate = new Date().toLocaleDateString();
  // console.log("aak ka dukha",currentDate)

  // Calculate the date for yesterday
  const yesterday = new Date();
  yesterday?.setDate(yesterday?.getDate() - 1);
  const yesterdayDate = new Date(yesterday)?.toLocaleDateString();
  // console.log("yesterdayDate", yesterdayDate);

  // Split the tasks into three arrays
  const todayTasks = [];
  const yesterdayTasks = [];
  const remainingTasks = [];

  activities?.forEach((task) => {
    // console.log("ssssssssssssss",task.date_created,currentDate)
    const getdate = new Date(task.date_created).toLocaleDateString();
    if (getdate === currentDate) {
      todayTasks.push(task);
    } else if (getdate === yesterdayDate) {
      yesterdayTasks.push(task);
    } else {
      remainingTasks.push(task);
    }
  });

  const date = new Date(remainingTasks[0]?.date_created).toLocaleDateString();
  // console.log('Today\'s Schedule:', todayTasks);
  // console.log('Yesterday\'s Schedule:', yesterdayTasks);
  // console.log('Remaining Tasks:', remainingTasks);
  // console.log("date",date,remainingTasks?.date_created)
  
  return (
    <div>

      <Comment
      setShowComment={setShowComment}
      handleSnackbarOpen={handleSnackbarOpen}
      selectedStatus={selectedStatus}
      setstatusupdate={setstatusupdate}
      setnewvalue={setnewvalue}
      setactivitesrefresh={setactivitesrefresh}
      activitesrefresh={activitesrefresh}
      refreshagain={refreshagain}
      setrefreshagain={setrefreshagain}
      selectedRowData ={selectedRowData}
      />
      {todayTasks.length > 0 ? (
        <Box sx={{ my: 2 }}>
          <Typography variant="body2" color={"text.secondary"} sx={{ mb: 2 }}>
            Today
          </Typography>
          {todayTasks &&
            todayTasks.map((item) => {
              return (
                <Grid
                  sx={{ mb: 2 }}
                  container
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item xs={1}>
                    <IconButton
                      sx={{ backgroundColor: "#E0E0E0", pointerEvents: "none" }}
                    >
                      <AccessTimeOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography component={"div"}>
                      <Typography variant="body1" gutterBottom>
                      <Box sx={{fontSize:"16px"}}>{item.entered_by}</Box>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.notes.replace(/\n/g, "<br />"),
                          }}
                        />
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
        </Box>
      ) : (
        ""
      )}
      {yesterdayTasks.length > 0 ? (
        <Box sx={{ my: 2 }}>
          <Typography variant="body2" color={"text.secondary"} sx={{ mb: 2 }}>
            Yesterday
          </Typography>
          {yesterdayTasks &&
            yesterdayTasks.map((item) => {
              return (
                <Grid
                  sx={{ mb: 2 }}
                  container
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item xs={1}>
                    <IconButton
                      sx={{ backgroundColor: "#E0E0E0", pointerEvents: "none" }}
                    >
                      <AccessTimeOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography component={"div"}>
                      <Typography variant="body1" gutterBottom>
                      <Box sx={{fontSize:"16px"}}>{item.entered_by}</Box>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.notes.replace(/\n/g, "<br />"),
                          }}
                        />
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
        </Box>
      ) : (
        ""
      )}
      {remainingTasks.length > 0 ? (
        <Box sx={{ paddingRight:"16px"}}>
          <Box>
          <Typography variant="body2" color={"text.secondary"} sx={{
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight:"32px",
              letterSpacing:"2px",
              textAlign:"left",
              width:"46px",
              height:"32px"
 }}>
           
          </Typography>
          {remainingTasks &&
            remainingTasks.map((item) => {
              return (
                <Grid
                  sx={{ mb: 2 }}
                  container
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                        {date}
                  </Typography>
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton
                      sx={{ backgroundColor: "#E0E0E0", pointerEvents: "none" }}
                    >
                      <AccessTimeOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography component={"div"}>
                      {/* <Typography variant="body1" gutterBottom>
                        {date}
                      </Typography> */}
                      <Typography variant="body1" gutterBottom>
                      <Box sx={{fontSize:"16px"}}>{item.entered_by}</Box>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.notes.replace(/\n/g, "<br />"),
                          }}
                        />
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
            </Box>
        </Box>
      ) : (
        ""
      )}
    </div>
  );
};

export default CandidateActivities;
