/////////////////addBranch:
import React, { useEffect, useState } from "react";
import Header from "./header";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { useLocation } from "react-router-dom";

const editorConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "insertTable",
      "undo",
      "redo",
    ],
  },
  placeholder: "Write the Description here...",
};

const AddBranch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { company_id, branch_id } = location.state || {};

  const [searchParams, setSearchParams] = useSearchParams();
  const { company_name } = location.state || {};
  const [formState, setFormState] = useState({
    name: "",
    phone: "",
    company_id: "",
    company_name: "",
    addressLine1: "",
    addressLine2: "",
    state: "",
    city: "",
    zip: "",
    notes: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedZipCode, setSelectedZipCode] = useState(0);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [zipCodes, setZipCodes] = useState([]);
  const [errors, setErrors] = useState({});
  const [branchInfo, setBranchInfo] = useState([]);
  const { id } = useParams();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleStateChange = (event) => {
    const selectedIsoCode = event.target.value;
    setSelectedState(selectedIsoCode);
    setFormState({ ...formState, state: selectedIsoCode });
    fetchCities(selectedIsoCode);
    if (searchParams.get("edit") === "1") {
      setFormState((prev) => ({ ...prev, city: "", zip: "" }));
      // setFormState({ ...formState, city: "" });
      // setFormState({ ...formState, zip : "" });
      setSelectedCity("");
      setSelectedZipCode("");
    }
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setSelectedCity(selectedCity);
    setSelectedZipCode("");
    setFormState({ ...formState, city: selectedCity });
    fetchZipCodes(selectedCity);
  };

  const handleZipCodeChange = (event) => {
    const selectedZipCode = event.target.value;
    setSelectedZipCode(selectedZipCode);
    setFormState({ ...formState, zip: String(selectedZipCode) });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formState.name) newErrors.name = "Branch Name is required";
    if (!formState.phone) newErrors.phone = "Contact Number is required";
    if (!formState.addressLine1)
      newErrors.addressLine1 = "Address Line 1 is required";
    if (!formState.state) newErrors.state = "State is required";
    if (!formState.city) newErrors.city = "City is required";
    // if (!formState.zip) newErrors.zip = 'Postal Code is required';
    if (formState.phone.length !== 10) {
      newErrors.phone = "It should contain exactly 10 digits.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));

    if (name === "website") {
      const websiteRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-]*)*\/?$/;
      if (!websiteRegex.test(value)) {
        setErrors((prev) => ({ ...prev, website: "Invalid website URL" }));
      } else {
        setErrors((prev) => {
          const { website, ...rest } = prev;
          return rest;
        });
      }
    }
  };

  const handleResponse = (response) => {
    if (response) {
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity(response.data.success ? "success" : "error");
      setTimeout(() => {
        setSnackbarOpen(true);
      }, 1500);
      setTimeout(() => {
        setLoading(false);
        navigate(-1);
      }, 2000);
    } else {
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm() === false) {
    } else {
      setLoading(true);
      if (searchParams.get("edit") === "1") {
        const edit_response = await editCompanyBranch();
        handleResponse(edit_response);
      } else {
        const create_response = await createBranch();
        handleResponse(create_response);
      }
    }
  };

  //original_date_modified

  const getBranchInfo = async () => {
    const res = await axios.get(`/branch-info/${branch_id}`, {
      headers: {
        authorization:
          Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
      },
    });
    const branchData = res.data.message[0];
    setFormState((prev) => ({ ...prev, ...branchData }));
    setBranchInfo(branchData);
    setSelectedState(branchData.state);
    setSelectedCity(branchData.city);
    fetchCities(branchData.state);
    setSelectedZipCode(Number(branchData.zip));
    fetchZipCodes(branchData.city);
  };

  console.log("formState", formState);
  const editCompanyBranch = async () => {
    try {
      const newFormState = { ...formState, company_id: company_id };
      const deletion = ["company_name", "date_created", "is_internal"];
      deletion.forEach((key) => delete newFormState[key]);
      const response = await axios.post(
        `/company/branch/edit/${branch_id}`,
        newFormState,
        {
          headers: {
            "Content-Type": "application/json",
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );
      return response;
    } catch (error) {
      console.log("error editing branch", error);
      setSnackbarMessage(error?.response?.data?.error);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  const createBranch = async () => {
    try {
      const newFormState = { ...formState, company_id: id };
      const deletion = ["company_name", "date_created"];
      deletion.forEach((key) => delete newFormState[key]);
      const response = await axios.post(
        "/company/branch/create",
        newFormState,
        {
          headers: {
            "Content-Type": "application/json",
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error creating branch", error);
      setSnackbarMessage(error?.response?.data?.error);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // Function to fetch states
  const fetchStates = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_JOBEZEURL + "/state-city/list",
        {}
      );
      setStates(response?.data?.message || []);
    } catch (error) {
      console.error("Error fetching state-city data", error);
    }
  };
  // Function to fetch cities based on state isoCode
  const fetchCities = async (stateName) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_JOBEZEURL + "/state-city/list",
        { stateName: stateName }
      );
      setCities(response?.data?.message || []);
    } catch (error) {
      console.error("Error fetching cities data", error);
    }
  };

  const fetchZipCodes = async (city) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_JOBEZEURL + "/state-city/list",
        { city: city }
      );
      setZipCodes(response?.data?.message || []);
    } catch (error) {
      console.error("Error fetching cities data", error);
    }
  };

  useEffect(() => {
    fetchStates();
    if (searchParams.get("edit") === "1") {
      getBranchInfo();
    }
  }, []);

  return (
    <Box
      className="pageWrapper"
      sx={{
        backgroundColor: "rgba(242, 248, 255, 1)",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                flex={1}
                component={"div"}
                sx={{
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: 5,
                  backgroundColor: "rgba(255, 255, 255, 1)",
                }}
              >
                <Box>
                  <Box>
                    <Typography
                      sx={{ p: 2, px: 3 }}
                      fontWeight={500}
                      fontSize={"22px"}
                      variant="body1"
                    >
                      {searchParams.get("edit") === "1"
                        ? "Edit Branch Details"
                        : "Add Branch"}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box
                    sx={{ p: 2, px: 3 }}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                  >
                    <Typography>
                      <TextField
                        name="name"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Branch Name"
                        inputProps={{ maxLength: 60 }}
                        required
                        value={formState.name}
                        onChange={handleInputChange}
                        onFocus={() => setErrors({ ...errors, name: "" })}
                        error={!!errors.name}
                        helperText={errors.name}
                      />
                    </Typography>
                    <Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={formState.company_name || company_name}
                        disabled
                        placeholder="Company Name"
                      />
                    </Typography>
                    <Typography>
                      <TextField
                        name="phone"
                        variant="outlined"
                        fullWidth
                        type="tel"
                        size="small"
                        placeholder="Contact Number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              + 1
                            </InputAdornment>
                          ),
                          inputProps: {
                            maxLength: 10,
                            onKeyPress: (e) => {
                              const charCode = e.which ? e.which : e.keyCode;
                              if (
                                charCode > 31 &&
                                (charCode < 48 || charCode > 57)
                              ) {
                                e.preventDefault();
                              }
                            },
                            pattern: "[0-9]*",
                            onPaste: (e) => {
                              e.preventDefault();
                            },
                          },
                        }}
                        value={formState.phone}
                        onChange={handleInputChange}
                        onFocus={() => setErrors({ ...errors, phone: "" })}
                        error={!!errors.phone}
                        helperText={errors.phone}
                      />
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ px: 3 }}>
                  <Box>
                    <Typography
                      fontWeight={500}
                      py={1.5}
                      variant="body1"
                      gutterBottom
                    >
                      Address
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <Typography>
                      <TextField
                        name="addressLine1"
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                        placeholder="Address Line 1"
                        value={formState.addressLine1}
                        onChange={handleInputChange}
                        onFocus={() =>
                          setErrors({ ...errors, addressLine1: "" })
                        }
                        error={!!errors.addressLine1}
                        helperText={errors.addressLine1}
                      />
                    </Typography>
                    <Typography>
                      <TextField
                        name="addressLine2"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Address Line 2"
                        value={formState.addressLine2}
                        onChange={handleInputChange}
                      />
                    </Typography>
                  </Box>
                  <Box
                    sx={{ pt: 2 }}
                    display={"flex"}
                    justifyContent={"space-between"}
                    gap={2}
                  >
                    <FormControl
                      fullWidth
                      sx={{ position: "relative" }}
                      error={!!errors.state}
                    >
                      <InputLabel size="small" id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        labelId="state-select-label"
                        id="state-select"
                        value={selectedState}
                        size="small"
                        label="State"
                        onChange={handleStateChange}
                        onFocus={() => setErrors({ ...errors, state: "" })}
                      >
                        {states?.map((state) => (
                          <MenuItem key={state.name} value={state.name}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.state && (
                        <Typography
                          fontSize={12}
                          px={1.5}
                          py={0.5}
                          color="error"
                        >
                          {errors.state}
                        </Typography>
                      )}
                    </FormControl>
                    <FormControl fullWidth error={!!errors.city}>
                      <InputLabel size="small" id="demo-simple-select-label">
                        City
                      </InputLabel>
                      <Select
                        labelId="city-select-label"
                        id="city-select"
                        value={selectedCity}
                        size="small"
                        label="City"
                        onChange={handleCityChange}
                        onFocus={() => setErrors({ ...errors, city: "" })}
                        disabled={!selectedState}
                      >
                        {cities?.map((city) => (
                          <MenuItem key={city.city} value={city.city}>
                            {city.city}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.city && (
                        <Typography
                          fontSize={12}
                          px={1.5}
                          py={0.5}
                          color="error"
                        >
                          {errors.city}
                        </Typography>
                      )}
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel size="small" id="demo-simple-select-label">
                        Zip Code
                      </InputLabel>
                      <Select
                        labelId="zip-select-label"
                        id="zip-select"
                        value={selectedZipCode || ""}
                        size="small"
                        label="Zip Code"
                        onChange={handleZipCodeChange}
                        // onFocus={() => setErrors({ ...errors, city: "" })}
                        disabled={!selectedCity}
                      >
                        {zipCodes?.map((zip) => (
                          <MenuItem key={zip.zip} value={zip.zip}>
                            {zip.zip}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={{ px: 3, py: 1.5 }}>
                  <Box>
                    <Typography
                      fontWeight={500}
                      py={1.5}
                      variant="body1"
                      gutterBottom
                    >
                      Additional Info
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfig}
                        data={formState.notes}
                        // onChange={handleEditorChange}
                        onChange={(event, editor) => {
                          setFormState((prev) => ({
                            ...prev,
                            notes: editor.getData(),
                          }));
                        }}
                        onReady={(editor) => {}}
                        onBlur={(event, editor) => {}}
                        onFocus={(event, editor) => {}}
                      />
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ py: 2 }}>
                  <Divider />
                </Box>
                <Box
                  sx={{ px: 3, pb: 2 }}
                  display={"flex"}
                  gap={2}
                  justifyContent={"end"}
                >
                  <Button variant="text" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    {searchParams.get("edit") === "1" ? "Save" : "Add"}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                sx={{ width: "100%" }}
                // variant="filled"
                elevation={6}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
            <Grid
              item
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              gap={"20px"}
              width={{ xs: "100%", sm: "100%", md: "55%", lg: "55%" }}
              sx={{
                borderRadius: 5,
                height: "100%",
                position: "sticky",
                top: "5%",
                right: 0,
                mb: { xs: 2, sm: 3, md: 4, lg: 8 },
              }}
            >
              <Box
                sx={{
                  py: 3,
                  px: 3,
                  backgroundColor: "#FFF3E0",
                  borderRadius: "16px",
                }}
              >
                <Typography fontSize={"34px"} align="left">
                  <img src="/brake-warning.png" />
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  fontSize={"20px"}
                >
                  Importance
                </Typography>
                <Typography variant="body1" sx={{ pt: 2.5 }}>
                  Many companies have multiple branches or locations. Allowing
                  them to create separate branches within their profile helps in
                  managing job postings specific to each location.
                </Typography>
              </Box>
              <Box
                sx={{
                  py: 3,
                  px: 3,
                  backgroundColor: "#FFF3E0",
                  borderRadius: "16px",
                }}
              >
                <Typography fontSize={"34px"} align="left">
                  💡
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  fontSize={"20px"}
                  sx={{ pt: 1 }}
                >
                  Tips
                </Typography>
                <Typography variant="body1" sx={{ pl: 2, pt: 2.5 }}>
                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <li>
                      Include fields for branch name, address, contact
                      information, and any other relevant details.
                    </li>
                    <li>
                      Provide the option for companies to assign branch-specific
                      administrators who can manage job postings for that
                      location.
                    </li>
                    <li>
                      Implement filters on the job search page to allow
                      candidates to search for openings by branch location.
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddBranch;
