import { Alert, Autocomplete, Box, Button, Divider, Modal, Skeleton, Snackbar, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
};

const AddToJobBtn = ({ openAddtoJob, setOpenAddtoJob, addToJobCandidateids, setSelectedRows, setAddToJobCandidateids, setRenderKey  }) => {

    const [shimmer, setShimmer] = useState(false);
    const [failCandidateData, setFailCandidateData] = useState([]);
    const [allJobsData, setallJobsData] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);

    const [snackbar, setSnackbar] = useState({ open: false, message: null, severity: 'success' });

    const fetchCompany = async () => {
        try {
            const response = await axios
                .get(`all-jobs`, {
                    headers: {
                        authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
                    }
                });
            setallJobsData(response.data.message);
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCompany();
    }, [])

    const handleCloseOpenAddtoJob = () => {
        setOpenAddtoJob(false);
        setSelectedJob(null);
    }



    const handleAddCandidates = async () => {
        setShimmer(true);
        try {
            const response = await axios.post(
                `bulk-add/job`,
                {
                    candidate_ids: addToJobCandidateids,
                    job_id: selectedJob ? selectedJob.joborder_id : "",
                    quesAnsArray: [],
                    isShortlisted: 0,
                },
                {
                    headers: {
                        authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
                    },
                }
            );

            if (response.data.status === 200) {
                // Close the modal if the API call is successful
                setTimeout(() => {
                    setOpenAddtoJob(false);
                    setSelectedRows([]);
                    setAddToJobCandidateids([]);
                },8000)
                setFailCandidateData(response.data.message.details);
                setShimmer(false);
                setSelectedJob(null);
                setRenderKey((prevKey) => prevKey + 1);
                setSnackbar({ open: true, message: response?.data?.message["message"], severity: "success" });
            } else {
                setShimmer(false);
                console.error("Failed to add candidates:", response.statusText);
                setSnackbar({ open: true, message: response.data.error, severity: "error" });
            }
        } catch (error) {
            setShimmer(false);
            console.error("An error occurred while adding candidates:", error);
            setSnackbar({ open: true, message: error.response.data.error, severity: "error" });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };
    
    return (
        <>

            <Modal
                open={openAddtoJob}
                // onClose={() => setOpenAddtoJob(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        className="fw-500"
                        p={2}
                    >
                        Add to Job
                    </Typography>
                    <Divider />
                    <Typography id="modal-modal-description" sx={{ p: 2 }}>
                        <Typography variant="body2" className="fw-400">
                            Select Job
                        </Typography>
                        <Autocomplete
                            id="controllable-states-demo"
                            // options={allJobsData.map(
                            //     (option) => `${option.title}, ${option.joborder_id}`
                            // )}
                            options={allJobsData}
                            getOptionLabel={(option) => `${option.title}, ${option.joborder_id}`}
                            value={selectedJob}
                            onChange={(event, newValue) => {
                                setSelectedJob(newValue);
                            }}

                            sx={{ mt: 2 }}
                            renderInput={(params) => (
                                <TextField {...params} label="Job" />
                            )}
                        />
                        <Box
                            sx={{ mt: 2 }}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1.5}
                            flexWrap={"wrap"}
                        >
                            <>
                                {shimmer ? (
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        gap={1.5}
                                        flexWrap={"wrap"}
                                    >
                                        <Skeleton variant="text" width={"100%"} />
                                        <Skeleton
                                            variant="rectangular"
                                            width={250}
                                            height={60}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            width={250}
                                            height={60}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            width={250}
                                            height={60}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            width={250}
                                            height={60}
                                        />
                                    </Box>
                                ) : (
                                    <Box>
                                        {failCandidateData.length === 0 ? (
                                            <></>
                                        ) : (
                                            <Typography
                                                variant="body1"
                                                color="text.secondary"
                                                width={"100%"}
                                                gutterBottom
                                            >
                                                {failCandidateData.length > 1 ? "These" : "This"}{" "}
                                                {failCandidateData.length}{" "}
                                                {failCandidateData.length > 1
                                                    ? "candidates"
                                                    : "candidate"}{" "}
                                                {failCandidateData.length > 1 ? "are " : "is "}
                                                already available in this job.The remaining
                                                candidates have been added successfully.
                                            </Typography>
                                        )}
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            gap={2}
                                            flexWrap={"wrap"}
                                        >
                                            {failCandidateData.map((item) => {
                                                return (
                                                    <Typography
                                                        variant="body1"
                                                        width={225}
                                                        sx={{
                                                            backgroundColor: "#FDEDED",
                                                            p: 1.5,
                                                            borderRadius: 2,
                                                            overflow: "hidden",
                                                        }}
                                                    >
                                                        <Typography
                                                            display={"flex"}
                                                            alignItems={"center"}
                                                            justifyContent={"space-between"}
                                                            gap={1}
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                                className="fs-16 fw-500 "
                                                            >
                                                                {item.first_name} {item.last_name}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                className="fw-400 "
                                                            ></Typography>
                                                        </Typography>
                                                        <Typography>
                                                            <Typography
                                                                variant="body2"
                                                                className="fw-400 "
                                                            >
                                                                {item.email1}
                                                            </Typography>
                                                        </Typography>
                                                    </Typography>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                )}
                            </>
                        </Box>
                    </Typography>
                    <Divider />
                    <Typography
                        id="modal-modal-footer"
                        variant="body2"
                        sx={{
                            p: 1.8,
                            display: "flex",
                            gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                            justifyContent: "end",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            variant="text"
                            size="small"
                            onClick={handleCloseOpenAddtoJob}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={handleAddCandidates}
                            disabled={selectedJob ? false : true}
                        >
                            Add
                        </Button>
                    </Typography>
                </Box>
            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default AddToJobBtn