import { Box, Typography, CircularProgress, Divider } from "@mui/material";
import React from "react";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useSearchParams } from "react-router-dom";

const Activities = ({ activityData, loading }) => {
  const [searchParams] = useSearchParams();

  //for applying hovereffect based on the comment
  //hover effect in app.css file with these classnames
  const getIcon = (comment, type) => {
    if (searchParams?.get("data") === "Jobs" || type === "Job") {
      return (
        <Box
          className={"activity-icon"}
          sx={{
            borderRadius: 1,
            height: "fit-content",
            p: 1,
            display: "inline-block",
            transition: "background-color 0.5s",
          }}
        >
          <WorkHistoryOutlinedIcon />
        </Box>
      );
    } else {
      if (comment?.includes("applied")) {
        return (
          <Box
            className={"activity-icon-person-icon"}
            sx={{
              borderRadius: 1,
              height: "fit-content",
              p: 1,
              display: "inline-block",
              transition: "background-color 0.5s",
            }}
          >
            <PersonOutlineOutlinedIcon />
          </Box>
        );
      } else if (comment?.includes("created")) {
        return (
          <Box
            className={"activity-icon-person-add-icon"}
            sx={{
              borderRadius: 1,
              height: "fit-content",
              p: 1,
              display: "inline-block",
              transition: "background-color 0.5s",
            }}
          >
            <PersonAddAlt1OutlinedIcon />
          </Box>
        );
      } else {
        return (
          <Box
            className={"activity-icon-work-history-icon"}
            sx={{
              borderRadius: 1,
              height: "fit-content",
              p: 1,
              display: "inline-block",
              transition: "background-color 0.5s",
            }}
          >
            <WorkHistoryOutlinedIcon />
          </Box>
        );
      }
    }
  };

  const getColor = (comment, type) => {
    if (searchParams?.get("data") === "Jobs" || type === "Job") {
      return "#3F95EA";
    } else {
      if (comment?.includes("applied")) {
        return "#2196F3";
      } else if (comment?.includes("created")) {
        return "#FFA93C";
      } else {
        return "#4CAF50 ";
      }
    }
  };

  return (
    <>
      {loading ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : activityData.length === 0 ? (
        // <Box
        //   style={{
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //   }}
        // >
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{
            fontWeight: 700,
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          No activities
        </Typography>
      ) : (
        /* </Box> */
        activityData.map(
          (item, index) =>
            item?.comment !== null && (
              <Box
                key={index}
                display={{ xs: "block", md: "block", lg: "flex" }}
                className="activity-box"
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
                pr={1}
                py={2}
                alignItems={"center"}
                gap={1}
              >
                {getIcon(item?.comment, item?.type)}
                <Box overflow="hidden">
                  <Typography
                    sx={{ fontWeight: 400 }}
                    variant="body2"
                    color={getColor(item?.comment, item?.type)}
                  >
                    {item?.title?.charAt(0).toUpperCase() +
                      item.title?.slice(1).toLowerCase()}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "wrap",
                    }}
                    variant="body1"
                  >
                    {item?.comment}
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 400 }}
                    variant="body2"
                    color="gray"
                  >
                    {new Date(item?.date).toLocaleString()}
                  </Typography>
                </Box>
                <Box>
                  <Divider />
                </Box>
              </Box>
            )
        )
      )}
    </>
  );
};

export default Activities;
