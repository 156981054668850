import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridToolbar,
  getGridStringOperators,
} from "@mui/x-data-grid";
import axios from "axios";
import { useSelector } from "react-redux";
import "../custom.css";
import { json, useNavigate, useSearchParams } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Drawer,
  Divider,
  TextField,
  InputAdornment,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Stack,
} from "@mui/material";
import Header from "../components/header";
import AddCandidateDrawer from "../components/addCandidateDrawer";
import SearchIcon from "@mui/icons-material/Search";
import AdvanceFilter from "../components/advanceFilter";
import { generate_filter_condition } from "../utiles/filter";
import CandidateDetails from "../components/candidateDetails";
import { current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import AddToJobBtn from "../components/addToJobBtn";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(1),
}));
const formatExperience = (experience) => {
  if (!experience) {
    return "NA";
  } else {
    const exp = experience.split(".");
    const year = exp[0] * 1;
    const month = exp[1] * 1;

    if (!year && !month) {
      return "NA";
    } else {
      return `${year > 1 ? `${year} years` : year === 0 ? "" : `${year} year`
        } ${month > 1 ? `${month} months` : month === 0 ? "" : `${month} month`}`;
    }
  }
};
const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 120,
    filterable: false,
    renderCell: (params) => (
      <a
        href={`/Manage-Candidates/${params.row.id}`}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <Typography variant="body2" title={params.row.id}>
          {params.row.id || "NA"}
        </Typography>
      </a>
    ),
  },
  {
    field: "fullname",
    headerName: "Full Name",
    filterable: false,
    width: 270,
    renderCell: (params) => (
      <a
        href={`/Manage-Candidates/${params.row.id}`}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <Typography
          variant="body2"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textTransform: "capitalize",
          }}
          title={params.row.fullname}
        >
          {params.row.fullname || "NA"}
        </Typography>
      </a>
    ),
  },
  {
    field: "entered_by",
    headerName: "Created By",
    width: 230,
    filterable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === "contains" ||
        operator.value === "equals" ||
        operator.value === "isAnyOf"
    ),
    renderCell: (params) => (
      <a
        href={`/Manage-Candidates/${params.row.id}`}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <Typography
          variant="body2"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          title={params.row.entered_by}
        >
          {params.row.entered_by || "NA"}
        </Typography>
      </a>
    ),
  },
  {
    field: "date_created",
    headerName: "Date Created",
    width: 230,
    renderCell: (params) =>
      new Date(params.row.date_created).toLocaleDateString("en-US"),
  },
  {
    field: "current_pay",
    headerName: "Salary",
    filterable: false,
    width: 180,
    renderCell: (params) => (
      <a
        href={`/Manage-Candidates/${params.row.id}`}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <Typography
          variant="body2"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          title={params.row.current_pay}
        >
          {params.row.current_pay || "NA"}
        </Typography>
      </a>
    ),
  },
  {
    field: "current_location",
    headerName: "Location",
    filterable: false,
    width: 180,
    renderCell: (params) => (
      <a
        href={`/Manage-Candidates/${params.row.id}`}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <Typography
          variant="body2"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          title={params.row.current_location}
        >
          {params.row.current_location || "NA"}
        </Typography>
      </a>
    ),
  },
  {
    field: "experience",
    headerName: "Experience",
    filterable: false,
    width: 180,
    renderCell: (params) => (
      <a
        href={`/Manage-Candidates/${params.row.id}`}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <Typography
          variant="body2"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          title={params.row.experience}
        >
          {formatExperience(params.row.experience) || "NA"}
        </Typography>
      </a>
    ),
  },
];



function ManageCandidate() {
  const PermissionStatus = useSelector((state) => state.user.permissions);
  const addCandidatePermission = PermissionStatus["100"];

  const [clickButton, setClickButton] = useState(false);
  const [newCount, setNewCount] = useState(0);
  const [quesAnsArrayFilter, setQuesAnsArrayFilter] = useState([]);
  const [current, setCurrent] = useState([]);
  const [onrowClick, setOnRowClick] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [FilterHitting, setFilterHitting] = useState(false);
  const [newData, setNewData] = useState([]);
  const [createdby, setCreatedby] = useState("");
  const [checkboxValues, setCheckboxValues] = useState({});
  const [cancelData, setCancelData] = useState(false);
  const [question, setQuestion] = useState([]);
  const [refreshagain, setrefreshagain] = useState(false);
  const [ManageCandidates, setManageCandidates] = useState([]);
  const getToken = useSelector((state) => state.user.token);
  const [searchQuery, setSearchQuery] = useState("");
  const [candidateList, setCandidateList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [textFields, setTextFields] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTermPrev, setSearchTermPrev] = useState("");
  const [applicants, setApplicantsdata] = useState([]);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isApplicanOpen, setApplicanOpen] = useState(false);
  const [radioValues, setRadioValues] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [operatorValues, setOperatorValues] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [checkedCount, setCheckedCount] = useState(0);
  const [username, setUsername] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterOptions, filtersetQueryOptions] = React.useState({
    filterModel: null,
  });
  const [warning, setWarning] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAddtoJob, setOpenAddtoJob] = useState(false);
  const [addToJobCandidateids, setAddToJobCandidateids] = useState([]);
  const [selectionLimitReached, setSelectionLimitReached] = useState(true);

  const [paginationModel, setPaginationModel] = useState({
    page: Number(searchParams.get("page")) - 1,
    pageSize: 25,
  });
  const containerRef = React.useRef(null);
  const [queryOptions, setQueryOptions] = useState({});
  const nav = useNavigate();
  const viewCandidateFlag = 1;

  const radioNames = [
    "All Candidates",
    "Associate With Job",
    "Not Associate With Job",
  ];

  const [selectedViewName, setSelectedViewName] = useState(radioNames[0]);
  const [isRadioChange, setIsRadioChange] = useState(false);

  const handleViewCandidateBy = (event) => {
    setSelectedViewName(event.target.value);
    sessionStorage.setItem("selectedViewName", event.target.value);
    setIsRadioChange(true);
  };

  const getUserData = async () => {
    try {
      const candidateOwnerList = await axios.get(`active-users`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      setCandidateList(candidateOwnerList.data.message);
      setUsername("Select User Name");
      setrefreshagain((prev) => !prev);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (advanceFilter === true) {
      getUserData();
    }
  }, [advanceFilter]);

  const [renderKey, setRenderKey] = useState(0);

  const handleRowSelection = (selectionModel) => {
    setSelectedRows(selectionModel);
    if (selectionModel.length >= 11) {
      setWarning(true);
      if (containerRef.current) {
        containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
        containerRef.current.scrollIntoView({ behavior: "smooth" });
      }
      return; // Early return to prevent further processing
    }
    if (selectionModel.length <= 10 && selectionModel.length > 0) {
      setAddToJobCandidateids(selectionModel);
      setWarning(false);
    }
  };

  const HandleRowClick = (params, event) => {

    // const data = {
    //   id: params.row.id,
    //   title: params.row.title,
    //   type: params.row.type,
    //   status: params.row.status,
    //   date_created: params.row.date_created,
    //   count: params.row.count,
    //   openapplication: params.row.open_count,
    // };
    const currentUrl = window.location.href;
    sessionStorage.setItem('manageCandidatepreviousUrl', currentUrl);
    setOnRowClick(true);
    nav(`/Manage-Candidates/${params.row.id}`, {});
  };

  const validate = () => {
    let isValid = true;
    let updateArray = [...quesAnsArrayFilter];

    updateArray.forEach((item, index) => {

      if (item.type_id === 2) {
        if (item.isChecked === true) {
          if (item.ans_text === "") {
            item.errorText = "This field is required";
            isValid = false;
          } else if (item.ans_text.length > 12) {
            item.errorText = "Maximum 12 digits allowed";
            isValid = false;
          } else {
            item.errorText = null;
          }
        }
      } else if (item.type_id === 3) {
        if (item.isChecked === true) {
          if (item.ans_text === "") {
            item.errorText = "Please select an option";
            isValid = false;
          } else {
            item.errorText = null;
          }
        }
      } else {
        item.errorText = null;
      }
    });
    setQuesAnsArrayFilter(updateArray);

    return isValid;
  };

  const listofCandidatesApi = async () => {
    if (clickButton || refreshagain) {
      setShowLoader(false);
      return;

    }
    setShowLoader(true);

    const selectedViewName = sessionStorage.getItem("selectedViewName");
    const index = radioNames.indexOf(selectedViewName);
    const selectedViewNameIndex = index !== -1 ? index : (selectedViewName ? selectedViewName : 0);


    let payload = {
      // jobId: "undefined",
      // isShortlisted: 1,
      // filterCriteria: filterOptions?.filterCondition
      //   ? filterOptions?.filterCondition
      //   : "",
      // searchQuery: searchQuery,
      filterCriteria: sessionStorage.getItem("createdby") ? sessionStorage.getItem("createdby") : "",
      // ? createdby
      // // : filterOptions.filterCondition
      // //   ? filterOptions.filterCondition
      //   : "",
      viewCandidatesBy: selectedViewNameIndex,
      pageSize: queryOptions.pageSize ? queryOptions.pageSize : 25,
    };
    if (queryOptions.sortModel && queryOptions.sortModel.length > 0) {
      payload.sortField = queryOptions?.sortModel[0].field;
      payload.sortBy = queryOptions?.sortModel[0].sort;
    }


    // if(filterOptions && filterOptions.filterCondition) {
    //   payload.filterCriteria = filterOptions.filterCondition
    // }
    // Construct the API URL with search query and pagination
    const page = searchParams.get("page") || 1;
    const searchQueryParam = searchParams.get("search") || "";
    let apiUrl = `manage-candidates/${page}`;
    if (searchQueryParam) {
      apiUrl += `?search=${encodeURIComponent(searchQueryParam)}`;
    }

    try {
      axios
        .post(apiUrl, payload,
          {
            headers: {
              authorization:
                Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
            },
          }
        )
        .then((response) => {
          const filteredApplicants = response.data.message.data.filter(
            (applicant) => applicant.id !== null
          );
          setManageCandidates(filteredApplicants);
          setShowLoader(false);
          setSearchTermPrev(searchTerm);
          setTotalCount(response.data.message.page);
          window.scrollTo({ top: 0, behavior: "smooth" });
          //
          // setIsReset(false);
        })
        .catch((error) => {
          setShowLoader(false);
          console.log("err", error);
          // setrefreshagain(prev => !prev);
        });
    } catch (err) {
      console.log("err", err);
      setShowLoader(false);
    }
    finally {
      setShowLoader(false);
    }
  }

  useEffect(() => {
    const query = searchParams.get("search") || "";
    setSearchQuery(decodeURIComponent(query)); // Decode the search query from the URL

    const fetchData = async () => {
      await listofCandidatesApi();
    };

    fetchData();
  }, [queryOptions, searchParams, paginationModel, refreshagain,
    clickButton,
    searchQuery,]);

  const handleSortModelChange = React.useCallback((sortModel) => {
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Update the URL with the search query and reset page to 1
    setSearchParams((prevParams) => ({
      ...prevParams,
      search: (query),
      page: 1,
    }));
  };




  const handleSelectionData = (event, value, reason) => {
    setSelectedOptions(value);
    setSelectedCount(value?.length);
    sessionStorage.setItem("selectedOptions", JSON.stringify(value));
    sessionStorage.setItem("selectedCount", value?.length);

    const usernames = value?.map((item) => item.name);
    const filterCondition = generate_filter_condition(
      "and concat(u.first_name, ' ', u.last_name)",
      "isAnyOf",
      usernames
    );


    if (usernames?.length > 0) {
      setCreatedby(filterCondition);
      sessionStorage.setItem("createdby", filterCondition);
    } else {
      setCreatedby("");
      sessionStorage.removeItem("createdby");
    }

    if (reason === "clear") {
      setCreatedby("");
      sessionStorage.removeItem("createdby");
      setCancelData(true);
      setOpen(true);
    }
    if (reason === "removeOption") {
      setCancelData(true);
      setOpen(true);
    }
  };

  const handleAdvanceFilterOpen = () => {
    setNewData(selectedOptions);
    let mytemp = current.map((q) => {
      return { ...q };
    });
    const updatedValues = {};
    const updatedText = {};
    const updatedRadioValues = {};
    const updatedOperator = {};
    mytemp.forEach((question) => {
      updatedText[question.question_id] = question.ans_text;
      updatedRadioValues[question.question_id] = question.ans_text;
      updatedOperator[question.question_id] = question.operator;
      if (question.isChecked !== undefined) {
        updatedValues[question.question_id] = question.isChecked;
      }
    });
    setOperatorValues((prevOperatorValues) => ({
      ...prevOperatorValues,
      ...updatedOperator,
    }));
    setRadioValues((prevRadioValues) => ({
      ...prevRadioValues,
      ...updatedRadioValues,
    }));
    setTextFields((prevState) => ({
      ...prevState,
      ...updatedText,
    }));
    setCheckboxValues((prevCheckboxValues) => ({
      ...prevCheckboxValues,
      ...updatedValues,
    }));
    setQuesAnsArrayFilter([...mytemp]);

    if (cancelData) {
      setSelectedCount(newData?.length);
    }
    setAdvanceFilter(true);

  };

  const handleAdvanceFilterClose = () => {
    setAdvanceFilter(false);
    setOpen(true);
    setFilterHitting(true);
    let sessionSelectedOption = sessionStorage.getItem("selectedOptions")
    selectedOptions?.length > 0 || cancelData
      ? setSelectedOptions(newData)
      : setSelectedOptions([]);
    setrefreshagain(!refreshagain);
    if (!FilterHitting) {
      setSelectedViewName((prev) => radioNames[0]);
    }
    else {
      setSelectedViewName(prev => radioNames[1] || radioNames[2] || radioNames[0]);
    }
    setIsRadioChange(false);
    setShowLoader(false);
  };
  const handleAdvanceFilterApply = () => {
    setAdvanceFilter(!validate());
    let error = 0;
    let updateArray = [...quesAnsArrayFilter];
    updateArray.forEach((item, index) => {
      if (item.isChecked) {
        if (item.type_id === 2) {
          if (item.ans_text === "") {
            error = 1;
            item.errorText = "This field is required";
          } else {
            item.errorText = null;
          }
        } else if (item.type_id === 3) {
          if (item.ans_text === "") {
            error = 1;
            item.errorText = "Please select an option";
          } else {
            item.errorText = null;
          }
        }
      }
    });
    if (!error) {
      let mytemp = quesAnsArrayFilter.map((q) => {
        return { ...q };
      });
      const countChecked = mytemp.filter(
        (item, index) => item.isChecked
      ).length;
      setCheckedCount(countChecked);
      if (selectedCount > 0) {
        setNewCount(countChecked + 1);
      } else {
        setNewCount(countChecked);
      }

      //
      setCurrent([...mytemp]);
      setFilterHitting(true);
      setOpen(true);
      setrefreshagain(!refreshagain);
      setIsRadioChange(false);
    }
  };
  const handlePaginationModelChange = (model) => {
    setPaginationModel(model);

    // Update the URL with the new page and existing search query
    setSearchParams({
      page: model.page + 1,
      search: searchQuery,
    });
    setQueryOptions({
      ...queryOptions,
      pageSize: model.pageSize,
    })

  };

  useEffect(() => {
    // Update paginationModel from searchParams when they change
    const page = Number(searchParams.get("page")) - 1 || 0;
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: page,
    }));
  }, [searchParams]);

  const handleFilterReset = () => {
    setTextFields({});
    setRadioValues({});
    setCheckboxValues({});
    setCheckedCount(0);
    setNewCount(0);
    sessionStorage.setItem("selectedCount", 0);
    const a = quesAnsArrayFilter.map((question) => {
      return {
        type_id: question.type_id,
        question_id: question.question_id,
        ans_text: "",
        errorText: null,
        operator: "=",
        isChecked: false,
      };
    });
    // console.log(a, "a");
    setQuesAnsArrayFilter(a);
    setIsReset(true);
    setCurrent(a);
    setAdvanceFilter(false);
    setFilterHitting(false);
    setrefreshagain(!refreshagain);
    setSearchParams({ page: 1 });
    window.location.reload();
    // setPaginationModel({
    //    page: Number(searchParams.get("page")) - 1,
    // pageSize: 18,
    // });

    //
    setSelectedOptions([]);
    sessionStorage.removeItem("selectedOptions");
    setCreatedby("");
    sessionStorage.removeItem("createdby");
    setSelectedViewName(radioNames[0]);
    sessionStorage.setItem("selectedViewName", radioNames[0]);
  };

  const handleAddToJobOpen = () => {
    setOpenAddtoJob(true);
  };

  // console.log(selectedViewName, "selectedViewName");
  // console.log(sessionStorage.getItem("selectedViewName"), "selectedViewName");
  // console.log(sessionStorage.getItem("createdby"), "createdby");
  // console.log(sessionStorage.getItem("selectedOptions"), "selectedOptions");

  const selectedChipOption = () => {
    const sessionStorageOptions = sessionStorage.getItem("selectedOptions");
    if (selectedOptions.length > 0) {
      return selectedOptions
    }
    else {
      return JSON.parse(sessionStorageOptions)
    }
  };
  return (
    <div ref={containerRef} >
      <AddCandidateDrawer
        isDrawerOpen={clickButton}
        setDrawerOpen={setClickButton}
        isAddToJob={false}
      />
      {openAddtoJob && (
        <AddToJobBtn
          openAddtoJob={openAddtoJob}
          addToJobCandidateids={addToJobCandidateids}
          setOpenAddtoJob={setOpenAddtoJob}
          setSelectedRows={setSelectedRows}
          setAddToJobCandidateids={setAddToJobCandidateids}
          setRenderKey={setRenderKey}
        />
      )}
      <Box className="pageWrapper">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TextField
            variant="standard"
            placeholder="Search Candidates"
            id="standard-search"
            type="search"
            value={searchQuery}
            onChange={handleSearch}
            sx={{
              width: "25%",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              gap: "24px",
            }}
          >
            {addCandidatePermission?.add === 1 && (
              <Button
                variant="contained"
                size="small"
                disabled={
                  selectedRows.length === 0 || selectedRows.length >= 11
                }
                onClick={handleAddToJobOpen}
              >
                {" "}
                Add to Job
              </Button>
            )}

            {addCandidatePermission?.add === 1 && (
              <Button
                variant="contained"
                size="small"
                startIcon={<PersonAddIcon />}
                onClick={() => setClickButton(true)}
              >
                {" "}
                Add Candidate{" "}
              </Button>
            )}

            <Button
              variant="contained"
              size="small"
              onClick={handleAdvanceFilterOpen}
              // disabled={
              //   props.status === "Closed" ||
              //   props.status === "Cancelled" ||
              //   props.status === "Full"
              // }
              startIcon={<FilterAltIcon />}
              endIcon={
                <>
                  {sessionStorage.getItem("selectedCount") > 0 ? (
                    <span
                      style={{
                        color: "#1976d2",
                        backgroundColor: "white",
                        borderRadius: "50px",
                        padding: "0px 7px",
                        paddingTop: "1px",
                        fontSize: "13px",
                      }}
                    >
                      {sessionStorage.getItem("selectedCount")}
                    </span>
                  ) : null}
                </>
              }
            >
              Advance Filter
            </Button>
          </Box>
        </Box>
        {/* <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          >
            <Grid item xs={6}>
              <Item>
                <Typography variant="h4" align="left" component="h5">
                  Manage Candidates
                </Typography>
              </Item>
            </Grid>
          </Grid>
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ paddingTop: "30px" }}>
            <TextField
              variant="standard"
              placeholder="Search Candidates"
              id="standard-search"
              type="search"
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: "20px" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              gap: "24px",
              alignItems: "center",
            }}
          >
            {addCandidatePermission?.add === 1 && (
              <Button
                variant="contained"
                size="small"
                disabled={
                  selectedRows.length === 0 || selectedRows.length >= 11
                }
                onClick={handleAddToJobOpen}
              >
                {" "}
                Add to Job
              </Button>
            )}

            {addCandidatePermission?.add === 1 && (
              <Button
                variant="contained"
                size="small"
                startIcon={<PersonAddIcon />}
                onClick={() => setClickButton(true)}
              >
                {" "}
                Add Candidate{" "}
              </Button>
            )}

            <Button
              variant="contained"
              size="small"
              onClick={handleAdvanceFilterOpen}
              // disabled={
              //   props.status === "Closed" ||
              //   props.status === "Cancelled" ||
              //   props.status === "Full"
              // }
              startIcon={<FilterAltIcon />}
              endIcon={
                <>
                  {newCount > 0 ? (
                    <span
                      style={{
                        color: "#1976d2",
                        backgroundColor: "white",
                        borderRadius: "50px",
                        padding: "0px 7px",
                        paddingTop: "1px",
                        fontSize: "13px",
                      }}
                    >
                      {newCount}
                    </span>
                  ) : null}
                </>
              }
            >
              Advance Filter
            </Button>
          </Box>
        </Box> */}

        <Box>
          <AdvanceFilter
            viewCandidateFlag={viewCandidateFlag}
            selectedViewName={sessionStorage.getItem("selectedViewName") || radioNames[0]}
            handleViewCandidateBy={handleViewCandidateBy}
            isRadioChange={isRadioChange}
            radioNames={radioNames}
            advanceFilter={advanceFilter}
            handleAdvanceFilterOpen={handleAdvanceFilterOpen}
            handleAdvanceFilterClose={handleAdvanceFilterClose}
            handleAdvanceFilterApply={handleAdvanceFilterApply}
            handleFilterReset={handleFilterReset}
            question={question}
            // setQuestion={setQuestion}
            textFields={textFields}
            // setTextFields={setTextFields}
            radioValues={radioValues}
            selectedCount={sessionStorage.getItem("selectedCount") || 0}
            selectedOptions={selectedChipOption()}
            handleSelectionData={handleSelectionData}
            candidateList={candidateList}
            username={username}
          />
        </Box>
        <Box sx={{ py: 2.5, width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              mb: 5,
              textAlign: "center",
            }}
          >
            {warning && (
              <Typography variant="body1" textAlign={"start"} color={"error"}>
                You cannot select more than 10 Candidates.
              </Typography>
            )}
            <DataGrid
              rows={ManageCandidates}
              // rows={rows}
              sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                  display: "none",
                },
              }}
              key={renderKey}
              pagination
              columns={columns}
              autoHeight
              paginationMode="server"
              paginationModel={paginationModel}
              loading={showLoader}
              onPaginationModelChange={handlePaginationModelChange}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              rowCount={totalCount}
              onRowClick={HandleRowClick}
              pageSizeOptions={[25, 50, 75, 100]}
              className="data-grid-row-hover"
              // checkboxSelection
              checkboxSelection={addCandidatePermission?.add === 1}
              onRowSelectionModelChange={(newSelection) =>
                handleRowSelection(newSelection)
              }
              disableSelectionOnClick
              disableRowSelectionOnClick
              keepNonExistentRowsSelected
              selectionModel={selectedRows}
            />
          </Box>
        </Box>
      </Box>

      {/* {onrowClick === true && <CandidateDetails isOpen={onrowClick} />} */}
    </div>
  );
}
export default ManageCandidate;
