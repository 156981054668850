import { Box, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Cookies from "js-cookie";


const Questionnairedata = (props) => {
  const [questionData, setQuestionData] = useState([])
  const getToken = useSelector((state) => state.user.token);
  



  useEffect(() => {
    axios
      .get(`/all-ques-ans/${props.candidateId}/jobId/${props.id}`,    
       {
        headers: {
           authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
        },
      })
      .then((response) => {
        // console.log("ressssss", response.data.message)
        setQuestionData(response.data.message)
      })
      .catch((error) => {
        console.error("Error fetching questions for job:", error);
      });
  }, []);

  


  return (
    <div style={{ marginTop: "20px" }}>
      <Typography mx={2}fontSize={16} fontWeight={500} color={"rgba(0, 0, 0, 0.6)"}>
        RESPONSE SUBMITTED BY THE CANDIDATE
      </Typography>
    <Box>
    {questionData.map((item,index) => (
        <Box key={item.question_id} sx={{ mt: 2 ,mx: 2}}>
          <Typography fontSize={17} fontWeight={500}>Question {index + 1}: {item.question}</Typography>
          <Typography fontSize={17} fontWeight={500} color={"rgba(0, 0, 0, 0.6)"}>{item.answer_text.charAt(0).toUpperCase() + item.answer_text.slice(1)}</Typography>
        </Box>
      ))}
    </Box>
    </div>
  )
}

export default Questionnairedata
