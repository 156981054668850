import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Pagination,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CandidateDetails from "./candidateDetails";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
// import styled from "@emotion/styled";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { generate_filter_condition } from "../utiles/filter";
import Questionpop from "./Questionpop";
import _debounce from "lodash/debounce";
import { onGlobalFliterFunc } from "../utiles/onFilterChange";
import AdvanceFilter from "./advanceFilter";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Cookies from "js-cookie";

// const baseURL = process.env.REACT_APP_BASEURL;
const statusColors = {
  Applied: "primary",
  "Employer Applied": "primary",
  Shortlisted: "success",
  "Tech Evaluation": "warning",
  "Manager Evaluation": "info",
  "HR Round": "info",
  "Offer Released": "success",
  Withdrawn: "error",
  Rejected: "error",
};

const verifiedColors = {
  1: "success",
  0: "error",
};
const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 90,
    filterable: false,
  },
  {
    field: "fullName",
    headerName: "Full Name",
    filterable: false,
    width: 200,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        title={params.row.fullName}
      >
        {" "}
        {params.row.fullName || "NA"}{" "}
      </Typography>
    ),
  },
  {
    field: "entered_by",
    headerName: "Created By",
    width: 180,
    filterable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === "contains" ||
        operator.value === "equals" ||
        operator.value === "isAnyOf"
    ),
  },
  {
    filterable: false,
    field: "created_at",
    headerName: "Date Created",
    width: 180,
    renderCell: (params) =>
      new Date(params.row.created_at).toLocaleDateString("en-US"),
  },
  {
    field: "current_location",
    headerName: "Location",
    filterable: false,
    width: 180,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        title={params.row.current_location}
      >
        {params.row.current_location || "NA"}
      </Typography>
    ),
  },
  {
    align: "start",
    field: "experience",
    filterable: false,
    headerName: "Experience",
    width: 180,
    renderCell: (params) => {
      const years = params.row.year || 0;
      const months = params.row.month || 0;

      let result = "";

      if (years > 0) {
        result += `${years} year${years > 1 ? "s" : ""}`;
      }

      if (months > 0) {
        result += `${result.length > 0 ? " " : ""}${months} month${months > 1 ? "s" : ""
          }`;
      }

      return years === 0 ? "NA" : result.length > 0 ? result : "NA";
    },
  },
  {
    field: "current_salary",
    filterable: false,
    headerName: "Current Salary",
    width: 180,
    renderCell: (params) => params.row.current_salary || "NA",
  },
  {
    field: "last_active",
    filterable: false,
    headerName: "Last Active on",
    width: 180,
    renderCell: (params) => params.row.last_active || "NA",
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const RecommendedTab = (props) => {
  const PermissionStatus = useSelector((state) => state.user.permissions);
  const CandidatePermission = PermissionStatus["100"]
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [applicants, setApplicantsdata] = useState([]);
  const getToken = useSelector((state) => state.user.token);
  const [isApplicanOpen, setApplicanOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [checkboxSelected, setcheckboxSelected] = useState(true);
  const [verifiedstatus, setverifiedstatus] = useState("");
  const [refreshagain, setrefreshagain] = useState(false);
  const [opennew, setOpennew] = useState(false);
  const [sPage, _sPage] = useState(0);
  const [candidatedrawerflag, setcandidatedrawerflag] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [queryOptions, setQueryOptions] = useState({});
  const [shimmer, setshimmer] = useState(false);
  const [isAnyCheckboxSelected, setIsAnyCheckboxSelected] = useState(false);
  const [addToJobCandidateSelection, setAddToJobCandidateSelection] = useState(
    []
  );
  const [addtoJob, setAddtoJob] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [bulkdata, setbulkdata] = useState([]);
  const [adddisbale, setadddisable] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [addtojobcount, setaddtojobcount] = useState(true);
  const [inputValueJobOrder, setInputValueJobOrder] = useState("");
  const [keepSelected, setKeepSelected] = useState(true);
  const [warning, setwarning] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(0);
  const [activitydisable, setactivitydisable] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 18,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = React.useState(
    addToJobCandidateSelection.length > 1 ? true : false
  );
  const [filterOptions, filtersetQueryOptions] = React.useState({
    filterModel: null,
  });
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [status, setstatus] = useState([]);
  // advance filter function variables start
  const [FilterHitting, setFilterHitting] = useState(false);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [question, setQuestion] = useState([]);
  const [quesAnsArrayFilter, setQuesAnsArrayFilter] = useState([]);
  const [current, setCurrent] = useState([]);
  const [myArray, setArray] = useState([]);
  const [textFields, setTextFields] = useState({});
  const [radioValues, setRadioValues] = useState({});
  const [operatorValues, setOperatorValues] = useState({});
  const [checkboxValues, setCheckboxValues] = useState({});
  const [checkedCount, setCheckedCount] = useState(0);
  const [isReset, setIsReset] = useState(false);

  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [createdby, setCreatedby] = useState("");
  const [newData, setNewData] = useState([]);
  const [cancelData, setCancelData] = useState(false);
  const [username, setUsername] = useState("");
  const containerRef = useRef(null);
  const getUserData = async () => {

    const candidateOwnerList = await axios.get(`active-users`, {
      headers: {
        authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"))
      },
    })
    setCandidateList(candidateOwnerList.data.message)
    setUsername("Select User Name");
  }
  useEffect(() => {
    getUserData()
  }, [])

  const handleSelectionData = (event, value, reason) => {
    setSelectedOptions(value);
    setSelectedCount(value.length);

    const usernames = value?.map((item) => item.name);
    const filterCondition = generate_filter_condition(" candidate_info.entered_by ", "isAnyOf", usernames)

    if (usernames.length > 0) {
      setCreatedby(filterCondition)
    }
    else {
      setCreatedby("")
    }

    if (reason === "clear") {
      setCreatedby("");
      setCancelData(true);
    }
    if (reason === "removeOption") {
      // setCreatedby("");
      setCancelData(true);
    }
  };

  const handleCheckboxChange = (e, id, index) => {
    const checked = e.target.checked;
    setCheckboxValues((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    let tempArr = [...quesAnsArrayFilter];
    tempArr[index].isChecked = checked;
    if (checked) {
      setQuesAnsArrayFilter(tempArr);
    } else {
      tempArr[index].ans_text = "";
      tempArr[index].errorText = null;
      const updatedTextFields = { ...textFields };
      updatedTextFields[id] = "";
      setTextFields(updatedTextFields);
      const updatedRadioValues = { ...radioValues };
      updatedRadioValues[id] = "";
      setRadioValues(updatedRadioValues);
      setQuesAnsArrayFilter(tempArr);
    }
    const countChecked = quesAnsArrayFilter.filter(
      (item, index) => item.isChecked
    ).length;
    setCheckedCount(countChecked);
  };

  const handleOperatorValuesChange = (e, id, index) => {
    const { value } = e.target;
    setOperatorValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    let tempArr = [...quesAnsArrayFilter];
    tempArr[index].operator = value;
    setQuesAnsArrayFilter(tempArr);
  };

  const temp = (value, type) => {
    if (type === "tel") {
      if (value.length === 0) {
        return "This field is required";
      } else if (value.trim().length >= 13) {
        return "Maximum 12 digits allowed";
      } else {
        return null;
      }
    }
  };

  const handleTextFieldChange = (e, id, index) => {
    const { value, type } = e.target;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setTextFields((prevState) => ({
      ...prevState,
      [id]: capitalizedValue,
    }));

    let tempArr = [...quesAnsArrayFilter];
    if (tempArr[index].isChecked === true) {
      tempArr[index].ans_text = capitalizedValue;
      tempArr[index].operator = quesAnsArrayFilter[index].operator
        ? quesAnsArrayFilter[index].operator
        : "=";
      tempArr[index].errorText = temp(capitalizedValue, type);
    } else {
      tempArr[index].errorText = null;
      tempArr[index].operator = "";
      tempArr[index].ans_text = "";
    }
    setQuesAnsArrayFilter(tempArr);
  };

  const handleRadioChange = (e, id, index) => {
    const { value } = e.target;
    setRadioValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    let tempArr = [...quesAnsArrayFilter];

    if (tempArr[index].isChecked === true) {
      tempArr[index].operator = quesAnsArrayFilter[index].operator
        ? quesAnsArrayFilter[index].operator
        : "";
      tempArr[index].errorText = null;
      tempArr[index].ans_text = value;
    } else {
      tempArr[index].errorText = null;
      tempArr[index].operator = "=";
      tempArr[index].ans_text = "";
    }
    setQuesAnsArrayFilter(tempArr);
  };

  const handleAdvanceFilterOpen = () => {
    setNewData(selectedOptions);
    let mytemp = current.map((q) => {
      return { ...q }
    })
    const updatedValues = {};
    const updatedText = {};
    const updatedRadioValues = {};
    const updatedOperator = {};
    mytemp.forEach(question => {
      updatedText[question.question_id] = question.ans_text;
      updatedRadioValues[question.question_id] = question.ans_text;
      updatedOperator[question.question_id] = question.operator;
      if (question.isChecked !== undefined) {
        updatedValues[question.question_id] = question.isChecked;
      }
    });
    setOperatorValues(prevOperatorValues => ({
      ...prevOperatorValues,
      ...updatedOperator
    }))
    setRadioValues(prevRadioValues => ({
      ...prevRadioValues,
      ...updatedRadioValues
    }));
    setTextFields((prevState) => ({
      ...prevState,
      ...updatedText,
    }));
    setCheckboxValues(prevCheckboxValues => ({
      ...prevCheckboxValues,
      ...updatedValues
    }));
    setQuesAnsArrayFilter([...mytemp]);
    if (cancelData) {
      setSelectedCount(newData.length);
    }
    setAdvanceFilter(true);
  };

  const handleAdvanceFilterClose = () => {
    setAdvanceFilter(false);

    console.log("newData", newData);
    selectedOptions.length > 0 || cancelData ? setSelectedOptions(newData) : setSelectedOptions([]);
  };

  const handleFilterReset = () => {
    setTextFields({});
    setRadioValues({});
    setCheckboxValues({});
    setCheckedCount();
    const a = quesAnsArrayFilter.map((question) => {
      return {
        type_id: question.type_id,
        question_id: question.question_id,
        ans_text: "",
        errorText: null,
        operator: "=",
        isChecked: false,
      };
    });
    setQuesAnsArrayFilter(a);
    setIsReset(true);
    setCurrent(a);
    setAdvanceFilter(false);
    setFilterHitting(false);
    setrefreshagain(!refreshagain);
    setSelectedOptions([]);
    setCreatedby("");
  };

  const validate = () => {
    let isValid = true;
    let updateArray = [...quesAnsArrayFilter];

    updateArray.forEach((item, index) => {
      if (item.type_id === 2) {
        if (item.isChecked === true) {
          if (item.ans_text === "") {
            item.errorText = "This field is required";
            isValid = false;
          } else if (item.ans_text.length > 12) {
            item.errorText = "Maximum 12 digits allowed";
            isValid = false;
          } else {
            item.errorText = null;
          }
        }
      } else if (item.type_id === 3) {
        if (item.isChecked === true) {
          if (item.ans_text === "") {
            item.errorText = "Please select an option";
            isValid = false;
          } else {
            item.errorText = null;
          }
        }
      } else {
        item.errorText = null;
      }
    });
    setQuesAnsArrayFilter(updateArray);
    return isValid;
  };

  const handleAdvanceFilterApply = () => {
    setAdvanceFilter(!validate());
    let error = 0;
    let updateArray = [...quesAnsArrayFilter];
    updateArray.forEach((item, index) => {
      if (item.isChecked) {
        if (item.type_id === 2) {
          if (item.ans_text === "") {
            error = 1;
            item.errorText = "This field is required";
          } else {
            item.errorText = null;
          }
        } else if (item.type_id === 3) {
          if (item.ans_text === "") {
            error = 1;
            item.errorText = "Please select an option";
          } else {
            item.errorText = null;
          }
        }
      }
    });
    if (!error) {
      let mytemp = quesAnsArrayFilter.map((q) => {
        return { ...q }
      })
      const countChecked = quesAnsArrayFilter.filter((item, index) => item.isChecked).length;
      //setCheckedCount(countChecked);
      if (selectedOptions.length || newData.length > 0) {
        setCheckedCount(countChecked + 1);
      }
      else {
        setCheckedCount(countChecked);
      }
      //
      setCurrent([...mytemp])
      setFilterHitting(true);
      setFilterHitting(true);
      setrefreshagain(!refreshagain);
      setrefreshagain(!refreshagain);
    }
    // handleSnackbarOpen(snackbarMessage => "Filter Applied");
  };


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [searchTermPrev, setSearchTermPrev] = useState("");

  const handleSortModelChange = React.useCallback((sortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const handletoprecommendations = () => {
    setRerenderKey((prevKey) => prevKey + 1);
    // Update paginationModel and setQueryOptions
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });
    setQueryOptions({});
    // Perform other actions as needed
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseDialog = () => {
    // Close the dialog
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };


  const onFilterChange = React.useCallback((filterModel) => {
    const respFilterData = onGlobalFliterFunc(filterModel, `and concat(u.first_name, ' ', u.last_name)`);
    if (respFilterData) {
      filtersetQueryOptions(respFilterData);
    }
  }, []);

  useEffect(() => {
    // console.log("queryOptionsqueryOptions", queryOptions);
    setShowLoader(true);
    let payload = {
      jobId: id,
      //
      // filterCriteria: filterOptions.filterCondition
      //   ? filterOptions.filterCondition
      //   : "",
      // created by
      filterCriteria: createdby ? createdby.includes("and candidate_info.entered_by in ()") ? "" : createdby : "",
    };
    if (queryOptions.sortModel && queryOptions.sortModel.length > 0) {
      payload.sortField = queryOptions?.sortModel[0].field;
      payload.sortBy = queryOptions?.sortModel[0].sort;
    }

    axios
      .post(
        `ai-recommendation/candidate-list/${searchTermPrev && searchTermPrev !== searchTerm
          ? 1
          : paginationModel.page + 1
        }${searchTerm ? "?search=" + searchTerm : ""}`,
        payload,
        {
          headers: {
            authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
          },
        }
      )
      .then((response) => {
        const filteredApplicants = response.data.message.data.filter(
          (applicant) => applicant.id !== null
        );
        setApplicantsdata(filteredApplicants);
        setShowLoader(false);
        // setaddtojobcount(true);
        setTotalCount(response.data.message.page);
        setSearchTermPrev(searchTerm);
        //
        setIsReset(false);
      })
      .catch((err) => {
        setShowLoader(false);
        setApplicantsdata([]);
        console.log(err);
      });
  }, [
    //
    refreshagain,
    //
    paginationModel,
    queryOptions,
    searchTerm,
    shimmer,
    isApplicanOpen,
    filterOptions,
  ]);

  const HandleRowClick = (params, event) => {
    setverifiedstatus(params.row.isVerified);
    setSelectedRowData(params.row);
    setApplicanOpen(true);
  };

  const handleSelection = (params, event) => {
    const currentSelectedRows = addToJobCandidateSelection;
    if (params.length < 1) {
      setaddtojobcount(true);
    }
    if (params.length > 10) {
      setwarning(true);
      if (containerRef.current) {
        containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
        containerRef.current.scrollIntoView({ behavior: "smooth" });
      }
      setAddToJobCandidateSelection(params);
      setaddtojobcount(false);
    }
    if (params.length > 0 && params.length <= 10) {
      setAddToJobCandidateSelection(params);
      setIsAnyCheckboxSelected(!!params.length);
      setaddtojobcount(false);
      setwarning(false);
    }
  };

  const filteredApplicants = applicants.filter((row) =>
    Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleOnClose = () => {
    setApplicanOpen(false);
    setOpennew((prev) => !prev);
  };

  useEffect(() => { }, []);
  const handleAddCandidates = () => {
    setshimmer(true);
    axios
      .post(
        `bulk-add/job`,
        {
          candidate_ids: addToJobCandidateSelection
            ? addToJobCandidateSelection
            : [],
          job_id: id,
          quesAnsArray: [],
          isShortlisted: 0,
        },
        {
          headers: {
            authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setKeepSelected(!keepSelected);
          setRerenderKey((prevKey) => prevKey + 1);
          setAddToJobCandidateSelection([]);
          setaddtojobcount(true);
          setshimmer(false);
          setwarning(false);
          setbulkdata(response.data.message.details);
          if (response.data.message.failure_candidates >= 1) {
            setadddisable(true);
          }
          handleSnackbarOpen("Candidate Added Successfully");
          if (response.data.message.failure_candidates === 0) {
            setAddtoJob(false);
            setOpenSnackbar(true);
          } else {
            setAddtoJob(true);
          }
        } else {
        }
      })
      .catch((err) => {
        console.log("error");
        handleSnackbarOpen(err.response.data.error);
      });
  };

  const [showCustomMessage, setShowCustomMessage] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowCustomMessage(true);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  const localeText = showCustomMessage
    ? { noRowsLabel: "No Rows" }
    : { noRowsLabel: "" };

  return (
    <div ref={containerRef}>
      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          sx={{ width: "25ch" }}
          type="search"
          placeholder="Search..."
          id="standard-search"
          variant="standard"
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          {
            CandidatePermission?.add === 1 && (
              <Button
                variant="outlined"
                size="small"
                onClick={handleAddCandidates}
                disabled={addtojobcount || warning}
              // disabled={props.status === "Closed" || props.status === "Cancelled" || props.status === "Full"}
              >
                Add to Job
              </Button>
            )
          }
          <Button
            variant="contained"
            size="small"
            onClick={handleAdvanceFilterOpen}
            // disabled={
            //   props.status === "Closed" ||
            //   props.status === "Cancelled" ||
            //   props.status === "Full"
            // }
            startIcon={<FilterAltIcon />}
            endIcon={
              <>
                {checkedCount > 0 ? (
                  <span
                    style={{
                      color: "#1976d2",
                      backgroundColor: "white",
                      borderRadius: "50px",
                      padding: "0px 7px",
                      paddingTop: "1px",
                      fontSize: "13px",
                    }}
                  >
                    {checkedCount}
                  </span>
                ) : null}
              </>
            }
          >
            Advance Filter</Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<AutoAwesomeIcon />}
            // onClick={handletoprecommendations}
            onClick={() => {
              // Update the rerender key first
              setRerenderKey((prevKey) => prevKey + 1);
              setPaginationModel({
                ...paginationModel,
                page: 0,

              });
              if (containerRef.current) {
                containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
                containerRef.current.scrollIntoView({ behavior: "smooth" });
              }
              // Then set the sort model
              setQueryOptions({
                sortModel: [
                  {
                    field: "recommend",
                    sort: "desc",
                  },
                ],
              });
            }}
          >
            Top Recommendations
          </Button>
        </Box>
      </Box>
      <AdvanceFilter
        advanceFilter={advanceFilter}
        handleAdvanceFilterOpen={handleAdvanceFilterOpen}
        handleAdvanceFilterClose={handleAdvanceFilterClose}
        handleAdvanceFilterApply={handleAdvanceFilterApply}
        handleFilterReset={handleFilterReset}
        question={question}
        // setQuestion={setQuestion}
        textFields={textFields}
        // setTextFields={setTextFields}
        radioValues={radioValues}

        selectedCount={selectedCount}
        selectedOptions={selectedOptions}
        handleSelectionData={handleSelectionData}
        candidateList={candidateList}
        username={username}
      />
      {warning ? (
        <Typography sx={{ mb: 1, mt: 1, }} color={"error"}>
          You cannot select more than 10 Candidates.
        </Typography>
      ) : null}
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
          {
            display: "none",
          },
        }}
        keepNonExistentRowsSelected
        columns={columns}
        loading={showLoader}
        autoHeight={filteredApplicants.length === 0}
        key={rerenderKey}
        paginationMode="server"
        rows={filteredApplicants ? filteredApplicants : []}
        getRowId={(row) => row.id}
        onRowClick={HandleRowClick}
        checkboxSelection={(props.status === "Closed" || props.status === "Cancelled" || props.status === "Full") ? false : checkboxSelected}
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => {
          setPaginationModel(newModel);

          if (containerRef.current) {
            containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
            containerRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }}

        pageSizeOptions={[18]}
        rowCount={totalCount}
        sortingMode="server"
        filterMode="server"
        localeText={localeText}
        onFilterModelChange={_debounce(onFilterChange, 500)}
        onSortModelChange={handleSortModelChange}
        onRowSelectionModelChange={handleSelection}
        disableRowSelectionOnClick
      // isRowSelectable={(params) => {
      //   if (addToJobCandidateSelection?.length < 10) return true;
      //   else if (addToJobCandidateSelection?.includes(params?.id))
      //     return true;
      //   else {
      //     return false;
      //   }
      // }}
      />
      <CandidateDetails
        activitydisable={activitydisable}
        setactivitydisable={setactivitydisable}
        verifiedstatus={verifiedstatus}
        candidatedrawerflag={candidatedrawerflag}
        isOpen={isApplicanOpen}
        setApplicanOpen={setApplicanOpen}
        onClose={handleOnClose}
        opennew={opennew}
        setOpennew={setOpennew}
        selectedRowData={selectedRowData}
        setrefreshagain={setrefreshagain}
        refreshagain={refreshagain}
        issearchnavbar={false}
        questionrequired={props.questionrequired}
        Jobstatus={props.status}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle sx={{ color: "red" }}>Error</DialogTitle>
        <DialogContent>
          You cannot select more than 10 Candidates.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={
            snackbarMessage === "Candidate Added Successfully"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Required additional questions
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            This job requires screening questions to be answered. We suggest you
            to add candidates one by one for efficient screening.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="error">
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default RecommendedTab;
