import React from "react";
import { Box, Typography } from "@mui/material";
import { handleDate } from "../utiles/handler";

const CompanyInfo = ({ companyInfo }) => {
  function ensureHttps(url) {
    // Check if the URL starts with 'https://'
    if (!url.startsWith("https://")) {
      // Add 'https://' if not present
      return "https://" + url;
    }
    // Return the URL as is if it already has 'https://'
    return url;
  }
  return (
    <Box sx={{ display: "flex" }} flexDirection={"column"} gap={2}>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          Name
        </Typography>
        <Typography sx={{ textTransform: "capitalize" }}>
          {companyInfo?.name || "NA"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          City
        </Typography>
        <Typography sx={{ textTransform: "capitalize" }}>
          {companyInfo?.city || "NA"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          State
        </Typography>
        <Typography sx={{ textTransform: "capitalize" }}>
          {companyInfo?.state || "NA"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          Contact Number
        </Typography>
        <Typography>
          {companyInfo?.phone ? "+1 " + companyInfo?.phone : "NA"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography
          fontWeight={500}
          width={{ xs: 200, sm: 150, md: 120, lg: 120 }}
          sx={{ color: "gray" }}
        >
          Address
        </Typography>
        <Typography>
          {companyInfo && companyInfo.addressLine1 ? (
            <>
              {companyInfo.addressLine1}
              {companyInfo.addressLine2 && `, ${companyInfo.addressLine2}`}
              {", "}
              {companyInfo.city}, {companyInfo.state}
              {companyInfo.zip && ` (${companyInfo.zip})`}
            </>
          ) : (
            "NA"
          )}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography
          fontWeight={500}
          width={{ xs: 200, sm: 150, md: 120, lg: 120 }}
          sx={{ color: "gray" }}
        >
          Website Link
        </Typography>
        <Typography>
          {companyInfo?.url ? (
            <a
              href={ensureHttps(companyInfo.url)}
              rel="noreferrer noopener nofollow "
              target="_blank"
              style={{ color: "#0096FF" }}
            >
              {companyInfo?.url}
            </a>
          ) : (
            "NA"
          )}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          Branches
        </Typography>
        <Typography>
          {companyInfo.is_branch_managed === 0
            ? "NA"
            : companyInfo?.branch_count}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          Created On
        </Typography>
        <Typography>
          {companyInfo.date_created
            ? handleDate(companyInfo?.date_created)
            : "NA"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
      >
        <Typography fontWeight={500} width={120} sx={{ color: "gray" }}>
          Additional Info
        </Typography>
        <Typography>
          {companyInfo ? (
            companyInfo?.notes ? (
              <span dangerouslySetInnerHTML={{ __html: companyInfo?.notes }} />
            ) : (
              "NA"
            )
          ) : (
            "NA"
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default CompanyInfo;
