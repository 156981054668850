export const generate_filter_condition = (colum, operator, value) => {
    if (operator === "contains") {
    // contains
    //console.log("contains", value);
    return `  ${colum} like '%${value}%'`;
  } else if (operator === "equals") {
    // equals
    return ` ${colum} = '${value}'`;
  } else if (operator === "isAnyOf") {
    // is any
    //console.log("value", value);
    const values = value?.map((val) => `'${val}'`).join(", ");
    //console.log("values", values);
    return ` ${colum} in (${values})`;
  } else {
    throw new Error("Invalid operator");
  }
};
