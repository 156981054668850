import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DeleteCanidateDrawer = ({
  open,
  handleClose,
  handleDelete,
  candidatename,
  acknowledged,
  handleAcknowledgeChange,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          padding: "36px 8px 36px 8px",
          borderRadius: "8px",
        },
      }}
    >
      <span
        style={{
          position: "absolute",
          top: "24px",
          right: "16px",
          bottom: "4px",
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </span>
      <DialogTitle style={{ fontWeight: 700, textAlign: "center" }}>
        Are you sure, you want to delete
        <span
          style={{
            fontWeight: 700,
            color: "#3394EF",
            textTransform: "capitalize",
          }}
        >
          {" "}
          {candidatename} {"?"}
        </span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <img
            src={"/deletePopUp.svg"}
            alt="Delete Icon"
            style={{ display: "block", margin: "0 auto 16px" }}
          />
          <Box style={{ padding: "10px", backgroundColor: "#FF4D4F1A" }}>
            <Typography
              style={{ fontWeight: 500, fontSize: "16px", color: "#FF4D4F" }}
            >
              If you delete this candidate!
            </Typography>
            <Typography
              varient="body1"
              style={{
                fontWeight: 400,
                color: "black",
                marginTop: "10px",
                fontSize: "14px",
                paddingLeft: "4px",
              }}
            >
              • The internal profile created for this candidate will be deleted.
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontWeight: 400,
                color: "black",
                marginTop: "10px",
                fontSize: "14px",
                paddingLeft: "4px",
              }}
            >
              • This Candidate will be removed from all associated jobs.
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontWeight: 400,
                color: "black",
                marginTop: "10px",
                marginBottom: "20px",
                fontSize: "14px",
                paddingLeft: "4px",
              }}
            >
              • All activities related to this candidate will be deleted.
            </Typography>
          </Box>
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              checked={acknowledged}
              onChange={handleAcknowledgeChange}
            />
          }
          label={
            <span style={{ fontWeight: 500, fontSize: "16px" }}>
              I acknowledge the implications and wish to proceed.
            </span>
          }
        />
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "center",
          display: "flex",
          gap: "30px",
          padding: "0 25px",
        }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          fullWidth
          style={{ borderRadius: "8px", textTransform: "capitalize" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          color="error"
          disabled={!acknowledged}
          fullWidth
          style={{ borderRadius: "8px", textTransform: "capitalize" }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCanidateDrawer;
