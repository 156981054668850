import { Box, Chip, Stack, Typography } from '@mui/material';
import React from 'react'

const CompanydisplayTabs = ({ companiesList }) => {
    return (
        <div>
            <Box display={"flex"} gap={{ xs: 1, sm: 2, md: 15, lg: 15 }}>
                <Box sx={{ display: "flex", width: "15%", flexDirection: "column", gap: 2 }} >
                    <Typography variant='body1' fontSize={"18px"}>Companies</Typography>
                </Box>
                <Box sx={{ display: "flex",  gap: 2, flexWrap: "wrap", width: { xs: "100%", sm: "100%", md: "65%", lg: "65%"} }}>
                {
                    companiesList[0] === -100 ? (
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }} >
                            <Stack direction="row" spacing={1}>
                                <Chip label="All companies" color='primary' size='large' sx={{ textTransform: "capitalize", backgroundColor: "rgba(130, 130, 130, 1)", borderRadius: 1 , fontSize:"15px", p: 2.5, px: 2 }} />
                            </Stack>
                        </Box>
                    ):(
                            companiesList.map((company, index) => (
                                <Box key={index} sx={{ display: "flex", gap: 1.5,  }} >
                                    <Stack spacing={1}>
                                        <Chip label={company?.name} color='primary' size='large' sx={{ textTransform: "capitalize", backgroundColor: "rgba(130, 130, 130, 1)", borderRadius: 1 , fontSize:"15px", p: 2.5, px: 2 }} />
                                    </Stack>
                                </Box>
                            ))
                        )
                }
                </Box> 
            </Box>  
        </div>
    )
}

export default CompanydisplayTabs