import { DataGrid } from "@mui/x-data-grid";
import { Button, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const GraphDatagrid = (
  { data,
    disablePrev,
    pageSize,
    jobData,
    loading,
    disableNext,
    page,
    handlePageChange,
    handleRowClick,
    handleJobRowClick,
    dataGridRecruiterId }
) => {

  // if (!data?.length && !jobData?.length) {
  //   return <div style={{ height: 300, width: "100%" }}>No data available</div>;
  // }

  const getdatagridheight = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1600) {
      return 540; // Height for extra-large screens (1600px+)
    } else if (screenWidth >= 1200) {
      return 340; // Height for large screens (1200px+)
    } else {
      return 240; // Default height for smaller screens
    }
  }
  const columns = Object.keys(data[0] || {}).map((key, index) => {
    let width = 200;
    if (index === 2) {
      width = 300;
    }
    let transformed = key.toUpperCase().split("_").join(" ");
    transformed = transformed.charAt(0) + transformed.slice(1).toLowerCase();
    return {
      field: key,
      headerName: transformed,
      width: width,
    };
  });

  const jobColumns = Object.keys(jobData[0] || {}).map((key, index) => {
    let width = 200;
    if (index === 2) {
      width = 300;
    }
    let transformed = key.toUpperCase().split("_").join(" ");
    transformed = transformed.charAt(0) + transformed.slice(1).toLowerCase();
    return {
      field: key,
      headerName: transformed,
      width: width,
    };
  });

  return (
    <div style={{ height: getdatagridheight(), width: "100%" }}>
      {!dataGridRecruiterId ? (
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5]}
          pagination
          page={page - 1}
          onPageChange={handlePageChange}
          onRowClick={handleRowClick}
          disableColumnMenu
          paginationMode="server"
          components={{
            Pagination: (props) => (
              <div style={{ display: "flex", justifyContent: "center", marginRight: '10px' }}>
                <Button
                  variant="outlined"
                  onClick={() => handlePageChange({ page: page - 1 })}
                  disabled={disablePrev}
                  style={{ marginRight: "10px" }}
                >
                  <NavigateBeforeIcon />
                </Button>
                <Typography variant="body1" pt={0.8}>
                  Page - {page}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => handlePageChange({ page: page + 1 })}
                  disabled={disableNext}
                  style={{ marginLeft: "10px" }}
                >
                  <NavigateNextIcon />
                </Button>
              </div>
            ),
          }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#03253D',
                color: '#fff',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                color: '#fff',
            },
            '& .MuiDataGrid-row:nth-of-type(odd)': {
                backgroundColor: '#EFEFEF',
            },
            '& .MuiDataGrid-row:nth-of-type(even)': {
                backgroundColor: '#fff', // Default color for even rows (optional, if needed)
            },
        }}
        />
      ) : (
        <DataGrid
          rows={jobData}
          columns={jobColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[5]}
          pagination
          page={page - 1}
          onPageChange={handlePageChange}
          onRowClick={handleJobRowClick}
          disableSelectionOnClick  
          disableRowSelectionOnClick
          paginationMode="server"
          components={{
            Pagination: (props) => (
              <div style={{ display: "flex", justifyContent: "center", marginRight: '10px' }}>
                <Button
                  variant="outlined"
                  onClick={() => handlePageChange({ page: page - 1 })}
                  disabled={disablePrev}
                  style={{ marginRight: "10px" }}
                >
                  <NavigateBeforeIcon />
                </Button>
                <Typography variant="body1" pt={0.8}>
                  Page - {page}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => handlePageChange({ page: page + 1 })}
                  disabled={disableNext}
                  style={{ marginLeft: "10px" }}
                >
                  <NavigateNextIcon />
                </Button>
              </div>
            ),
          }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#03253D',
                color: '#fff',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                color: '#fff',
            },
            '& .MuiDataGrid-row:nth-of-type(odd)': {
                backgroundColor: '#EFEFEF',
            },
            '& .MuiDataGrid-row:nth-of-type(even)': {
                backgroundColor: '#fff', // Default color for even rows (optional, if needed)
            },
        }}
        />
      )}
    </div>
  );
};
export default GraphDatagrid;
