import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../custom.css";
import Cookies from "js-cookie";
import { Box } from "@mui/material";

const columns = [
  { field: "id", headerName: "Job ID", filterable: false, width: 100 },
  {
    field: "title",
    headerName: "Job Name",
    width: 500,
    filterable: false,
    sortable: true,
  },
  {
    field: "short_description",
    headerName: "Current Status",
    filterable: false,
    sortable: true,
    width: 250,
  },
  {
    field: "date_created",
    headerName: "Date Added",
    type: "number",
    filterable: false,
    sortable: true,
    width: 250,
    headerAlign: "center",
    align: "center",
    renderCell: (params) =>
      new Date(params.row.date_created).toLocaleDateString("en-US"),
  },
];
const JobAssociated = () => {
  const [jobAssociatedData, setJobAssociatedData] = useState([]);
  const getToken = useSelector((state) => state.user.token);
  const [queryOptions, setQueryOptions] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 18,
  });
  const nav = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    let payload = {};
    if (queryOptions.sortModel && queryOptions.sortModel.length > 0) {
      payload.sortField = queryOptions?.sortModel[0].field;
      payload.sortBy = queryOptions?.sortModel[0].sort;
    }
    axios
      .post(
        `/candidate-job-associated/${id}/page/${paginationModel.page + 1}`,
        payload,
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        setJobAssociatedData(response.data.message.data);
        setTotalCount(response.data.message.page);
        //  console.log("144", response.data.message.page);
      });
  }, [paginationModel, queryOptions]);

  // console.log("84", jobAssociatedData);
  const handleSortModelChange = React.useCallback((sortModel) => {
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const HandleRowClick = (params, event) => {
    nav(`/jobOrders/${params.row.id}`, {
      state: {
        data: params.row,
        value: 0,
      },
    });
  };

  return (

      <DataGrid
        rows={jobAssociatedData}
        columns={columns}
        autoHeight
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[18]}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        onRowClick={HandleRowClick}
        className="data-grid-row-hover"
        rowCount={totalCount}
      />

  );
};

export default JobAssociated;
