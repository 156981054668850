import React, { useState } from 'react'
import { Container, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import '../App.css';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

// const baseURL = process.env.REACT_APP_BASEURL;
const defaultTheme = createTheme();



const Createpassword = () => {
    const nav = useNavigate();
    const getToken = useSelector((state) => state.user.token);


    const handleSubmit = (event) => {
        event.preventDefault();
        let isValid = true;
        setPasswordError('');
        setConfirmPasswordError('');
    
        if (password.length <= 6) {
            setPasswordError('Password must be more than 6 letters.');
            isValid = false;
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match!');
            isValid = false;
            return;
        }
        if (isValid) {
            console.log("reduxtoken",localStorage.getItem("token"))
            axios.post(`user/reset-password/verify-user`, { password: password, confirmPassword:confirmPassword},
            {
              headers: {
                //   authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
                    authorization : window.location.pathname.split('/')[2].split("=")[1]

                },
              })
            .then((response) => {
         
              toast.success("Password changed successfully", {hideProgressBar: true, closeOnClick: true});
              nav('/login');
      
            }).catch((err)=>{
       
              toast.error(err.response.data.error, {hideProgressBar: true, closeOnClick: true});
            })
          }


    };
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    console.log("window.location",window.location.pathname.split('/')[2])

    return (
        <div>
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 15,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img src="https://jobeze.com/Logo.svg" className='LoginJobezeLogo' alt="Employer Portal" />
                    </Box>
                    <Box
                        sx={{
                            marginTop: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '20px',
                            borderRadius: '16px',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20);',
                        }}
                    >
                        <Typography textAlign={'start'} component="h1" sx={{ my: 2 }} variant="h4">
                            Create New Password
                        </Typography>
                        <Box component="form" width={'100%'} onSubmit={e => {nav('/jobOrders')}} sx={{ mt: 1 }}>
                            <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
                                <InputLabel size='small' htmlFor="outlined-adornment-password">New Password</InputLabel>
                                <OutlinedInput
                                    margin="dense"
                                    id="outlined-adornment-password"
                                    size='small'
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    error={!!passwordError}
                                    onChange={e => setPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="New Password"
                                />
                                <FormHelperText error >{passwordError}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
                                <InputLabel size='small' htmlFor="outlined-adornment-password"> Confirm Password</InputLabel>
                                <OutlinedInput
                                    margin="dense"
                                    id="outlined-adornment-password"
                                    size='small'
                                    type={showPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    error={!!confirmPasswordError}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirm Password"
                                />
                                <FormHelperText error >{confirmPasswordError}</FormHelperText>
                            </FormControl>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, py: 1 }}
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </div>
    )
}

export default Createpassword;