import { generate_filter_condition } from './filter';

export const onGlobalFliterFunc = (filterModel,colum) => {
    let filtersetQueryOptions ={}
    if (filterModel?.items && filterModel.items.length > 0) {
      const { field, operator, value } = filterModel.items[0];
      if (value?.length > 2 || (operator === "isAnyOf" && value?.length > 0)) {
        const filterCondition = generate_filter_condition(colum, operator, value);
        filtersetQueryOptions = {
            filterModel: { ...filterModel }, 
            filterCondition,
          }
          return filtersetQueryOptions
      } else if (value?.length === 0 || (operator === "isAnyOf" && value?.length === 0)) {
        filtersetQueryOptions = {
            filterModel: { ...filterModel }, 
            filterCondition:filterModel.filterCondition,
          }
          return filtersetQueryOptions
      } else {
      }
    } else {
        filtersetQueryOptions = {
            filterModel: { ...filterModel }, 
            filterCondition: filterModel.filterCondition,
          }
          return filtersetQueryOptions
    }
  }; 