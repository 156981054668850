import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import CreatedByCheckbox from "./createdbycheckbox";
import Cookies from "js-cookie";
import ViewCandidateBy from "./viewCandidateBy";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AdvanceFilter = (props) => {
  const getToken = useSelector((state) => state.user.token);
  const { id } = useParams(); // job id
  useEffect(() => {
    const fetchData = async () => {
      const applyFilter = 1;
      if (!id) return;
      try {
        const response = await axios.post(
          `question-list-job/${id}`,
          { applyFilter: applyFilter },
          {
            headers: {
              authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"))
            },
          }
        );
        const questions = response.data.message;
        props.setQuestion(questions);
        const a = response.data.message.map((question) => {
          return {
            type_id: question.question_type,
            question_id: question.question_id,
            ans_text: "",
            errorText: null,
            operator: "=",
            isChecked: false,
          };
        });

        // props.setQuesAnsArrayFilter(a);
        props.setCurrent(a);
      } catch (error) {
        // Handle error here, e.g., set error state
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={props.advanceFilter}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "8px",
          height: {
            xs: "auto",
            md: `${props.question?.length > 1 ? "90vh" : "40vh"}`,
          },
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, px: 3 }} id="customized-dialog-title">
        Advance Filter
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.handleAdvanceFilterClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ overflow: "hidden", overflowY: "scroll" }}>

        {/* created by component */}

        <Box sx={{ px: 2, py: 1.5 }}>
          <Box sx={{ pb: 2.5 }}
            display={"flex"}
            gap={2}
            justifyContent={"space-between"}
          >
            <Typography width={"30%"} fontWeight={700} sx={{ pt: 1.5 }}> Created By </Typography>
            <CreatedByCheckbox candidateList={props.candidateList} handleSelectionData={props.handleSelectionData}  selectedOptions={props?.selectedOptions} username={props.username} />
          </Box>
        </Box>
        {
          props.viewCandidateFlag === 1 &&
          <Box sx={{ px: 2 }}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography width={"25%"} fontWeight={700} >View Candidates By</Typography>
                <ViewCandidateBy handleViewCandidateBy={props.handleViewCandidateBy} selectedViewName={props.selectedViewName} radioNames={props?.radioNames} />
            </Box>
          </Box>
        }

        {
          props.question?.length > 0 &&
          <Box width={"100%"}>
            <Divider />
          </Box>
        }

        <Box sx={{ px: 2, pt: 1.5 }}>
          {
            props.question?.length > 0 &&
            <Typography fontWeight={700} sx={{ pb: 3 }}> Additional Questions </Typography>
          }
          {props.question.map((question, index) => (
            <Box
              key={question.question_id}
              sx={{ pb: 3.5 }}
              display={"flex"}
              gap={2}
            >
              <Box
                sx={{
                  maxWidth: "65%",
                  width: "100%",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      id={question.question_id}
                      checked={
                        props.checkboxValues[question.question_id] || false
                      }
                      onChange={(e) =>
                        props.handleCheckboxChange(
                          e,
                          question.question_id,
                          index
                        )
                      }
                    />
                  }
                  label={
                    question.question.charAt(0).toUpperCase() +
                    question.question.slice(1)
                  }
                />
              </Box>
              {question.question_type === 2 && (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"start"}
                  gap={2}
                >
                  <Box>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        sx={{
                          width: "60px",
                          maxWidth: "60px",
                          minWidth: "60px",
                        }}
                        defaultValue={"="}
                        value={props.operatorValues[question.question_id]}
                        disabled={!props.checkboxValues[question.question_id]}
                        onChange={(e) =>
                          props.handleOperatorValuesChange(
                            e,
                            question.question_id,
                            index
                          )
                        }
                      >
                        <MenuItem value={"="}>{"="}</MenuItem>
                        <MenuItem value={"<"}>{"<"}</MenuItem>
                        <MenuItem value={">"}>{">"}</MenuItem>
                        <MenuItem value={"<="}>{"≤"}</MenuItem>
                        <MenuItem value={">="}>{"≥"}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box autoComplete="off">
                    <TextField
                      size="small"
                      id="outlined-basic"
                      placeholder="Enter numeric value"
                      variant="outlined"
                      type="tel"
                      autoComplete="off"
                      disabled={!props.checkboxValues[question.question_id]}
                      value={props.textFields[question.question_id] || ""}
                      InputProps={{
                        inputProps: {
                          maxLength: 13,
                          onKeyPress: (e) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (
                              charCode > 31 &&
                              (charCode < 48 || charCode > 57)
                            ) {
                              e.preventDefault();
                            }
                          },
                          pattern: "[0-9]*",
                        },
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                        const pastedText =
                          e.clipboardData.getData("text/plain");
                        const filteredText = pastedText.replace(/[^\d]/g, ""); // Keep only digits
                        navigator.clipboard
                          .writeText(filteredText)
                          .then(() => {
                            // Update the field value after pasting filtered text
                            e.target.value = filteredText;
                            props.handleTextFieldChange(
                              e,
                              question.question_id,
                              index
                            );
                          })
                          .catch((err) => {
                            console.error("Error writing to clipboard: ", err);
                          });
                      }}
                      onChange={(e) =>
                        props.handleTextFieldChange(
                          e,
                          question.question_id,
                          index
                        )
                      }
                      error={props.quesAnsArrayFilter[index]?.errorText !== null}
                      helperText={props.quesAnsArrayFilter[index]?.errorText}
                    />
                  </Box>
                </Box>
              )}

              {question.question_type === 3 && (
                <Box>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={props.radioValues[question.question_id] || ""}
                      onChange={(e) =>
                        props.handleRadioChange(e, question.question_id, index)
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        id={question.ques_ans[0].answer_id}
                        disabled={!props.checkboxValues[question.question_id]}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        id={question.ques_ans[1].answer_id}
                        disabled={!props.checkboxValues[question.question_id]}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    {props.quesAnsArrayFilter[index]?.errorText && (
                      <Typography
                        variant="caption"
                        error
                        sx={{ color: "#d32f2f" }}
                      >
                        {props.quesAnsArrayFilter[index]?.errorText}
                      </Typography>
                    )}
                  </FormControl>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Box>
          <Button
            variant="outlined"
            color="error"
            sx={{ borderRadius: 8, textTransform: "capitalize" }}
            onClick={props.handleFilterReset}
          >
            Reset
          </Button>
        </Box>
        <Box>
          <Button
            variant="text"
            sx={{
              color: (theme) => theme.palette.grey[500],
              borderRadius: 8,
              textTransform: "capitalize",
            }}
            onClick={props.handleAdvanceFilterClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={{ borderRadius: 8, textTransform: "capitalize" }}
            onClick={props.handleAdvanceFilterApply}
            disabled={
              props.viewCandidateFlag === 1 ? false  :
              !Object.values(props.radioValues).some((value) => value) &&
              !Object.values(props.textFields).some((value) => value)
              && props.selectedOptions.length === 0
            }
          >
            Apply
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default AdvanceFilter;
