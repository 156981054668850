import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  Slider,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  LinearProgress,
  Tooltip,
  FormLabel,
  Link,
} from "@mui/material";
import tooltipClasses from "@mui/material/Tooltip/tooltipClasses";
// import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CloseIcon from "@mui/icons-material/Close";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";

import PlusOneIcon from "@mui/icons-material/PlusOne";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { GridContextProvider } from "@mui/x-data-grid";

const PrettoSlider = styled(Slider)({
  color: "#006CB7",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: "#006CB7",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 26,
    height: 26,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#006CB7",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const monthss = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const CandidateEditableProfile = ({ candidateId }) => {
  const PermissionStatus = useSelector((state) => state.user.permissions);
  const EditCandidatePermission = PermissionStatus["100"];
  const getToken = useSelector((state) => state.user.token);
  const [candidatedetails, setcandidatedetails] = useState({});

  const [isopen, setisopen] = useState(false);
  const { id } = useParams();

  const [fullname, setFullname] = useState("");
  const [fullnameError, setFullnameError] = useState("");
  const [gender, setGender] = useState("");
  const eighteenYearsAgo = dayjs().subtract(18, "year");
  const [selectedDate, setSelectedDate] = useState(null);
  const [email, setEmail] = useState({
    contact: "",
    contact_id: null,
    contact_type: "email",
    is_primary: 1,
  });

  const [mobileError, setMobileError] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [workAuthorizationOptions, setWorkAuthorizationOptions] = useState([]);
  const [years, setYears] = useState("");
  const [months, setMonths] = useState("");
  const [getCity, setCityLoc] = useState("");
  const [currrentLocation, setCurrentLocation] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [selectedworkAuthorization, setSelectedworkAuthorization] =
    useState("");
  const [currentEmployer, setCurrentEmployer] = useState("");
  const [currentSalary, setCurrentSalary] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");
  const [preferredArr, setPreferredArr] = useState([]);
  const [radius, setRadius] = useState(0);
  const [NoticePeriod, setNoticePeriod] = useState("");
  const [lastworkingday, setLastworkingday] = useState("");
  const [DateAvailable, selectedDateAvailable] = useState("");
  const [highEducation, setHighEducation] = useState("");
  const [HighEducationOptions, setHighEducationOptions] = useState([]);
  const [skills, setSkills] = useState([]);
  const [linkedin, setLinkedin] = useState("");
  const [linkedinError, setLinkedinError] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const [portfolioError, setPortfolioError] = useState("");
  const [profileErrorHelperText, setPortfolioErrorHelperText] = useState("");
  const [Miscellaneous, setMiscellaneous] = useState("");
  const [currentlocationID, setCurrentlocationID] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarServerity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [miscellaneousError, setMiscellaneousError] = useState("");
  const [tempArray, setTempArray] = useState({});
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [datemodified, setDateModified] = useState("");
  const [isedited, setisedited] = useState();
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [customWorkAuthorization, setCustomWorkAuthorization] = useState("");
  const [customeworkError, setCustomeworkError] = useState(false);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      placement="right-start"
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 14,
      // maxWidth: 'max-content',
      whiteSpace: "wrap",
    },
  }));
  //attachment upload code
  const attachment_type = {
    1: "Resume",
    2: "Cover Letter",
    3: "Visa",
    4: "Passport",
    5: "Other",
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [open, setOpen] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [fileError, setFileError] = useState("");
  // const [isResume, setIsResume] = useState("no");
  const [description, setDescription] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [uploadDate, setUploadDate] = useState("");
  const [attachmentsType, setAttachmentsType] = useState("1");

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.style.border = "2px dashed #0F91D2";
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.style.border = "2px dashed #0F91D2";
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.style.border = "2px dashed #ccc";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.style.border = "2px dashed #ccc";
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileUpload({ target: { files: [file] } });
    }
  };

  const handleClickOpen = () => {
    if (attachments.length === 5) {
      setSnackbarMessage("Maximum 5 files can be uploaded");
      setSnackbarServerity("error");
      setSnackbarOpen(true);
    } else if (EditCandidatePermission.edit === 1) {
      setIsDataChanged(true);
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setAttachment(null);
    setFileError("");
    setAttachmentsType("1");
    setDescription("");
    setUploadProgress(0);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
      "application/vnd.oasis.opendocument.spreadsheet",
      "application/vnd.oasis.opendocument.text",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-powerpoint",
      "application/vnd.ms-excel",
      "application/msword",
      "application/rtf",
      "application/mspowerpoint",
      "application/powerpoint",
      "application/x-mspowerpoint",
      "application/vnd.oasis.opendocument.presentation",
    ];

    if (file && allowedTypes.includes(file.type)) {
      if (file.size <= 10485760) {
        // 10 MB in bytes
        setAttachment(file);
        setFileError("");
        setFileName(file.name);
        setUploadDate(new Date().toLocaleDateString());
      } else {
        setFileError("File size must be less than 10MB.");
        setAttachment(null);
      }
    } else {
      setFileError(
        "Only .ppt, .docx, .pdf, .txt, .xlsx, .xls, .png, .jpg, .jpeg, .doc, .rtf and .pptx,  files can be uploaded."
      );
      setAttachment(null);
    }
  };

  const handleDeleteAttachment = () => {
    setAttachment(null);
  };

  const handleAttachmentTypeChange = (event) => {
    setAttachmentsType(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length > 100) {
      setSnackbarMessage("Maximum 100 characters allowed");
      setSnackbarServerity("error");
      setSnackbarOpen(true);
    } else {
      setDescription(newValue);
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleSubmitAttachment = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("attachment", attachment);
    formData.append("candidate_id", candidateId);
    formData.append("attachment_type", attachment_type[attachmentsType]);
    formData.append("description", description);

    try {
      const response = await axios
        .post("/attachment/create", formData, {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        })
        .then((response) => {
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
          setAttachmentsType("1");
          setSnackbarServerity("success");
        });
      handleClose();
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.error);
      setSnackbarServerity("error");
      console.error("Error uploading files: ", error);
    }
  };

  const handleDelete = (id) => {
    axios
      .delete(`/attachment/remove/${selectedAttachment.id}`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        setAttachments(
          attachments.filter(
            (attachment) => attachment.id !== selectedAttachment.id
          )
        );
        setSnackbarMessage(response.data.message);
        setSnackbarOpen(true);
        setSnackbarServerity("success");
        setisopen(true);
        closeDeleteDialog();
      })
      .catch((error) => {
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.error);
        setSnackbarServerity("error");
        console.error("Error deleting attachment:", error);
      });
  };

  const handleDownload = (storedFilename) => {
    window.open(storedFilename, "_blank");
  };

  const openDeleteDialog = (attachment) => {
    if (EditCandidatePermission?.edit === 1) {
      setSelectedAttachment(attachment);
      setOpenDialog(true);
    }
  };

  const closeDeleteDialog = () => {
    setOpenDialog(false);
    setSelectedAttachment(null);
  };

  // const [dataChange, setDataChange] = useState
  const [alternateemail1, setAlternateEmail1] = useState({
    contact: "",
    contact_id: null,
    contact_type: "email",
    is_primary: 0,
  });
  const [alternateemail2, setalternateemail2] = useState({
    contact: "",
    contact_id: null,
    contact_type: "email",
    is_primary: 0,
  });
  const [mobile, setMobile] = useState({
    contact: "",
    contact_id: null,
    contact_type: "mobile",
    is_primary: 1,
  });
  const [alternatemobile, setalternatemobile] = useState({
    contact: "",
    contact_id: null,
    contact_type: "mobile",
    is_primary: 0,
  });
  const [AlternateEmail1Error, setAlternateEmail1Error] = useState("");
  const [AlternateEmail2Error, setAlternateEmail2Error] = useState("");
  const [AlternateMobileError, setAlternateMobileError] = useState("");
  // const [PrimaryEmail, setPrimaryEmail] = useState("");

  const handleSecondaryemail = (e) => {
    // let tempEmail = {...alternateemail1};

    // tempEmail.contact = e.target.value;
    setAlternateEmail1({
      contact: e.target.value,
      contact_id: alternateemail1.contact_id
        ? alternateemail1.contact_id
        : null,
      contact_type: "email",
      is_primary: 0,
    });
    setTempArray({ ...tempArray, secondary_email: e.target.value });
  };

  const handlealternateemail2 = (e) => {
    // let tempEmail = {...alternateemail2};
    // tempEmail.contact = e.target.value;
    // setalternateemail2(tempEmail);
    setalternateemail2({
      contact: e.target.value,
      contact_id: alternateemail2.contact_id
        ? alternateemail2.contact_id
        : null,
      contact_type: "email",
      is_primary: 0,
    });
    setTempArray({ ...tempArray, secondary_email2: e.target.value });
  };

  const handlemiscellaneous = (event) => {
    setMiscellaneous(event.target.value);
    setTempArray({ ...tempArray, miscellaneous_notes: event.target.value });
  };
  const handlePortfolio = (event) => {
    setPortfolio(event.target.value);
    setTempArray({ ...tempArray, portfolio: event.target.value });
  };

  const handleFullname = (event) => {
    const input = event.target.value;

    const regex = /^[a-zA-Z ]{0,61}$/;

    // Check if the input matches the regular expression
    if (regex.test(input) || input === "") {
      // Valid input, update the state
      setFullname(input);
      setTempArray({ ...tempArray, name: input });
    }
    if (input.length >= 61) {
      setFullnameError(
        "Please enter a valid with a maximum length of 60 characters"
      );
    } else {
      setFullnameError("");
    }
    // else: Invalid input, do nothing (or show an error message if you prefer)
  };

  const handleLinkedin = (event) => {
    setLinkedin(event.target.value);
    setTempArray({ ...tempArray, linkedIn: event.target.value });
  };

  const handleGender = (event) => {
    setGender(event.target.value);
    setTempArray({ ...tempArray, gender: event.target.value });
  };

  const handleHighEducation = (event) => {
    setHighEducation(event.target.value);
    const obj = HighEducationOptions.filter(
      (item) => item.highest_education_id === event.target.value
    )[0];
    setTempArray({ ...tempArray, highest_educ: obj });
  };

  const handleDateChange = (e) => {
    let date = "";
    if (e) {
      let m = e.$d.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = ("0" + e.$D).slice(-2);
      date = e.$d.getFullYear() + "-" + m + "-" + d;
      setSelectedDate(date);
      setTempArray({ ...tempArray, dob: date });
    }
  };

  const handleMobile = (e) => {
    // const value = e.target.value;
    // let tempmobile = {...mobile};
    // tempmobile.contact = e.target.value;
    setMobileError("");
    // Validate that the input contains at most 10 digits
    if (/^\d{0,10}$/.test(e.target.value)) {
      setMobile({
        contact: e.target.value,
        contact_id: mobile ? mobile.contact_id : null,
        contact_type: "mobile",
        is_primary: 1,
      });

      // let tempmobile = {...mobile};
      // setMobile(tempmobile);
      setTempArray({ ...tempArray, mobile: e.target.value });
    }
  };

  const handlealternateMobile = (e) => {
    // const value = e.target.value;
    // let tempmobile = {...alternatemobile};
    // tempmobile.contact = e.target.value;

    setAlternateMobileError("");
    // Validate that the input contains at most 10 digits
    if (/^\d{0,10}$/.test(e.target.value)) {
      // setalternatemobile(tempmobile);
      setalternatemobile({
        contact: e.target.value,
        contact_id: alternatemobile ? alternatemobile.contact_id : null,
        contact_type: "mobile",
        is_primary: 0,
      });

      setTempArray({ ...tempArray, mobile2: e.target.value });
    }
  };

  const handleCurrentAddress = (event) => {
    setCurrentAddress(event.target.value);
    setTempArray({ ...tempArray, address: event.target.value });
  };

  const handleMonths = (event) => {
    setMonths(event.target.value);
    setTempArray({ ...tempArray, month: JSON.stringify(event.target.value) });
  };

  const handleCitySelect = (event, value, reason) => {
    if (value) {
      const selectedLocation = currrentLocation.find(
        (loc) => loc.city === value
      );
      if (selectedLocation) {
        setSelectedCityId(selectedLocation.city_id);

        const cityobj = currrentLocation.filter(
          (item) => item.city_id === selectedLocation.city_id
        )[0];

        const new_obj = {
          label: cityobj.city,
          value: cityobj.city_id,
        };
        setTempArray({ ...tempArray, current_loc: [new_obj] });
      }
    } else {
      setSelectedCityId(null); // Clear the selected city_id if value is cleared
    }

    if (reason === "clear") {
      setCurrentlocationID(null);
      setSelectedCityId(null);
      setIsDataChanged(true);
    }
  };

  const handleworkAuthorization = (event) => {
    const value = event.target.value;
    const selectedOption = workAuthorizationOptions?.find(
      (option) => option?.work_authorization === value
    );
    // Update the selectedWorkAuthorization with the ID
    if (selectedOption) {
      setSelectedworkAuthorization(selectedOption);
    }
    if (selectedOption.work_authorization === "Others") {
      setIsOtherSelected(true);
      setCustomWorkAuthorization("");
    } else {
      setIsOtherSelected(false);
      setCustomWorkAuthorization("");
    }
    const obj1 = workAuthorizationOptions.filter(
      (item) => item.work_authorization_id === event.target.value
    )[0];
    setTempArray({ ...tempArray, work_authorization: obj1 });
  };

  const handleCustomWorkAuthorizationChange = (event) => {
    setCustomeworkError(false);
    setCustomWorkAuthorization(event.target.value);
  };

  const handleCurrentSalary = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.substring(0, 10);
    setCurrentSalary(limitedValue);
    setTempArray({ ...tempArray, CTC: limitedValue });
  };

  const handleExpectedSalary = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.substring(0, 10);
    setExpectedSalary(limitedValue);
    setTempArray({ ...tempArray, Exp_CTC: limitedValue });
  };

  const handleNoticePeriod = (event) => {
    const input = event.target.value;

    // Validate if input is numeric and up to 3 digits
    if (/^\d{0,2}$/.test(input)) {
      setNoticePeriod(input);
      const numericInput = parseInt(input, 10); // Convert input to a number
      setTempArray({ ...tempArray, notice_period: numericInput });
    }
  };

  const handlelastworkingday = (e) => {
    let date = "";
    if (e) {
      let m = e.$d.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = ("0" + e.$D).slice(-2);
      date = e.$d.getFullYear() + "-" + m + "-" + d;
      setLastworkingday(date);
      setTempArray({ ...tempArray, last_working_day: date });
    }
  };

  const handleDateAvailable = (e) => {
    let date = "";
    if (e) {
      let m = e.$d.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = ("0" + e.$D).slice(-2);
      date = e.$d.getFullYear() + "-" + m + "-" + d;
      selectedDateAvailable(date);
      setTempArray({ ...tempArray, expected_joining_date: date });
    }
  };

  useEffect(() => {
    const ondata = async () => {
      await fetchData();
    };
    ondata();
  }, [getCity]);

  // useEffect to compare data whenever originalData or tempArray changes
  useEffect(() => {
    // Check if there are any differences between originalData and tempArray

    const dataChanged =
      JSON.stringify(candidatedetails) !== JSON.stringify(tempArray);

    if (EditCandidatePermission?.edit === 1) {
      setIsDataChanged(dataChanged);
      setIsDataChanged(true);
    }
  }, [candidatedetails, tempArray, EditCandidatePermission]);

  const fetchData = async () => {
    try {
      if (getCity && getCity.length > 0) {
        const response = await axios.post(
          process.env.REACT_APP_JOBEZEURL + "/state-city/list",
          {
            cityName: getCity,
          }
        );
        response.data.message && response.data.message.length > 0
          ? setCurrentLocation(response.data.message)
          : setCurrentLocation(currrentLocation);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const validateLinkedInURL = (url) => {
    if (url.trim() === "") {
      return true; // No validation needed if the field is empty
    }
    const pattern =
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm;
    return pattern.test(url);
  };

  const validateURL = (url) => {
    const pattern =
      /(https: \/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;
    return pattern.test(url);
  };

  const MAX_PORTFOLIO_URL_LENGTH = 128;
  const validatePortfolioURL = (url) => {
    return validateURL(url) && url.length <= MAX_PORTFOLIO_URL_LENGTH;
  };

  const pref = preferredArr.map((item) => item.value);

  const scrollToErrorField = (fieldName) => {
    const errorFieldRef = errorFieldRefs[fieldName];
    if (errorFieldRef && errorFieldRef.current) {
      errorFieldRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const errorFieldRefs = {
    fullname: useRef(null),
    mobile: useRef(null),
    otherWorkauthorization: useRef(null),
    // Add similar refs for other fields...
  };
  const handleSubmit = () => {
    let hasErrors = false;
    // setDrawerOpen(true);

    if (!fullname) {
      setFullnameError("Full Name is required.");
      scrollToErrorField("fullname");
      hasErrors = true;
    } else {
      setFullnameError("");
    }

    // Validate email if it's not empty
    if (alternateemail1?.contact?.trim() !== "") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (
        alternateemail1?.contact &&
        !emailRegex?.test(alternateemail1?.contact)
      ) {
        setAlternateEmail1Error("Invalid email format.");
        hasErrors = true;
      } else if (
        alternateemail1?.contact &&
        alternateemail1?.contact.length > 165
      ) {
        setAlternateEmail1Error(
          "Email length should not exceed 165 characters."
        );
        hasErrors = true;
      } else if (alternateemail1?.contact === email) {
        setAlternateEmail1Error("Email cannot be the same as primary email.");
        hasErrors = true;
      } else {
        setAlternateEmail1Error(""); // Clear any previous error
      }
    }

    // Validation for alternateemail2
    if (alternateemail2?.contact?.trim() !== "") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (
        alternateemail2?.contact &&
        !emailRegex?.test(alternateemail2?.contact)
      ) {
        setAlternateEmail2Error("Invalid email format.");
        hasErrors = true;
      } else if (
        alternateemail2?.contact &&
        alternateemail2?.contact === alternateemail1?.contact
      ) {
        setAlternateEmail2Error("Email cannot be the same as alternateemail1.");
        hasErrors = true;
      } else if (
        alternateemail2?.contact &&
        alternateemail2?.contact.length > 165
      ) {
        setAlternateEmail2Error(
          "Email length should not exceed 165 characters."
        );
        hasErrors = true;
      } else if (
        alternateemail2?.contact &&
        alternateemail2?.contact === email
      ) {
        scrollToErrorField("mobile");
        setAlternateEmail2Error("Email cannot be the same as primary email.");
        hasErrors = true;
      } else {
        setAlternateEmail2Error(""); // Clear any previous error
      }
    }

    if (mobile?.contact) {
      // Check if mobile number has exactly 10 digits
      if (!/^\d{10}$/.test(mobile?.contact)) {
        setMobileError("It should contain exactly 10 digits.");
        scrollToErrorField("mobile");
        // scrollToErrorField("mobile");
        hasErrors = true; // Stop submission if validation fails
      } else {
        setMobileError("");
      }
    }

    if (alternatemobile?.contact) {
      // Check if mobile number has exactly 10 digits
      if (!/^\d{10}$/.test(alternatemobile?.contact)) {
        scrollToErrorField("mobile");
        setAlternateMobileError("It should contain exactly 10 digits.");
        hasErrors = true; // Stop submission if validation fails
      } else if (alternatemobile?.contact === mobile?.contact) {
        scrollToErrorField("mobile");
        setAlternateMobileError(
          "Mobile number cannot be the same as Primary mobile."
        );
        hasErrors = true;
        return;
      } else {
        setAlternateMobileError("");
      }
    }

    if (linkedin && !validateLinkedInURL(linkedin)) {
      // The LinkedIn URL is valid, you can proceed with your form submission logic here.
      setLinkedinError("Invalid LinkedIn URL");
      return;
    } else {
      setLinkedinError("");
    }

    if (portfolio && !validatePortfolioURL(portfolio)) {
      setPortfolioError(true);
      setPortfolioErrorHelperText(
        "Invalid URL or URL length exceeds 128 characters"
      );
      return;
    }

    if (Miscellaneous?.length > 1000) {
      setMiscellaneousError("Maximum 1000 characters required.");
      hasErrors = true;
    } else {
      setMiscellaneousError("");
    }

    if (hasErrors) {
      return; // Do not submit the form if there are validation errors
    }

    if (!customWorkAuthorization && isOtherSelected) {
      scrollToErrorField("otherWorkauthorization");
      hasErrors = true;
      setCustomeworkError(true);
      return;
    } else {
      setCustomeworkError(false);
    }
    // const companyId = localStorage.getItem("company_id");
    // setLoading(true);

    let contactArr = [email];
    if (alternateemail1) contactArr.push(alternateemail1);
    if (alternateemail2) contactArr.push(alternateemail2);
    if (alternatemobile) contactArr.push(alternatemobile);
    if (mobile) contactArr.push(mobile);

    axios
      .post(
        `/duplicate-profile/${candidateId}`,
        {
          fullname: fullname,
          // mobile: mobile,
          address: currentAddress,
          gender: gender,
          current_employer: currentEmployer,
          dob: selectedDate,
          CTC: currentSalary,
          Exp_CTC: expectedSalary,
          year: years,
          month: months,
          current_loc: selectedCityId
            ? selectedCityId
            : currentlocationID
            ? currentlocationID
            : null,
          highest_educ: highEducation ? highEducation : 1,
          linkedin: linkedin,
          portfolio: portfolio,
          work_authorization: selectedworkAuthorization.work_authorization_id
            ? selectedworkAuthorization.work_authorization_id
            : 1,
          work_authorization_text: customWorkAuthorization
            ? customWorkAuthorization
            : null,
          expected_joining_date: DateAvailable ? DateAvailable : null,
          notice_period:
            NoticePeriod || NoticePeriod === 0 ? NoticePeriod : null,
          miscellaneous_notes: Miscellaneous,
          last_working_day: lastworkingday ? lastworkingday : null,
          original_date_modified: datemodified,
          contacts: contactArr,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          // setLoading(false);
          // setPreferredLocationIds([]);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
          setSnackbarServerity("success");
          setisopen(true);
          setisedited(true);
        }
      })
      .catch((err) => {
        // setLoading(false);
        setSnackbarOpen(true);
        setisedited(false);
        setSnackbarMessage(err.response.data.error);
        setSnackbarServerity("error");
        // setEmailalreadyexists(true);
      });
  };

  useEffect(() => {
    // const candidateId = selectedRowData?.id;
    if (!candidateId) return;
    axios
      .post(
        `candidate/details/${candidateId}?isEditableProfile=true`,
        {
          jobId: id,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setAttachments(response.data.message.attachments || []);
        }

        const original_data = response.data.message;
        delete original_data["activities"];
        delete original_data["additional_info"];
        delete original_data["cover_letter"];
        delete original_data["profile"];
        delete original_data["profile_path"];
        delete original_data["relocate"];
        delete original_data["resume"];
        delete original_data["resume_path"];
        delete original_data["resume_date"];

        setcandidatedetails(original_data);
        setTempArray(original_data);
        setFullname(response?.data?.message?.name);
        setGender(response?.data?.message?.gender);
        setSelectedDate(response?.data?.message?.dob);
        // setEmail(response?.data?.message?.email);
        setMobile(response?.data?.message?.mobile);
        setCurrentAddress(response?.data?.message?.address);
        setYears(response?.data?.message?.year);
        setMonths(response?.data?.message?.month);

        setSelectedworkAuthorization(
          response?.data?.message?.work_authorization
        );
        const selectedOtherOrnot =
          response?.data?.message?.work_authorization?.work_authorization ===
          "Others";
        if (selectedOtherOrnot) {
          setIsOtherSelected(true);
        }
        setCustomWorkAuthorization(
          response?.data?.message?.work_authorization_text
        );
        setCurrentEmployer(response?.data?.message?.current_employer);
        setCurrentSalary(response?.data?.message?.CTC);
        setExpectedSalary(response?.data?.message?.Exp_CTC);
        setCityLoc(response?.data?.message?.current_loc[0]?.label);
        setCurrentlocationID(response?.data?.message?.current_loc[0]?.value);
        setPreferredArr(response?.data?.message?.preferred_loc);
        // setRadius(response?.data?.message?.radius);
        setNoticePeriod(response?.data.message?.notice_period);
        setLastworkingday(response?.data.message?.last_working_day);
        setHighEducation(
          response?.data?.message?.highest_educ?.highest_education_id
        );
        // setSkills(response.data.message.skill);
        setLinkedin(response?.data?.message?.linkedIn);
        setPortfolio(response?.data?.message?.portfolio);
        selectedDateAvailable(response?.data?.message?.expected_joining_date);
        setMiscellaneous(response?.data.message?.miscellaneous_notes);
        setDateModified(response?.data.message?.date_modified);
        const contacts = response?.data?.message?.contacts;
        setisedited(false);
        let primaryEmail;
        let alternateEmail1;
        let alternateEmail2;
        let primaryMobile;
        let alternateMobile;

        contacts.forEach((contact) => {
          if (contact.contact_type === "email") {
            if (contact.is_primary === 1) {
              primaryEmail = contact;
            } else if (contact.is_primary === 0) {
              if (!alternateEmail1) {
                alternateEmail1 = contact;
              } else if (!alternateEmail2) {
                alternateEmail2 = contact;
              }
            }
          } else if (contact.contact_type === "mobile") {
            if (contact.is_primary === 1) {
              primaryMobile = contact;
            } else if (contact.is_primary === 0) {
              alternateMobile = contact;
            }
          }
        });

        setEmail({
          contact: primaryEmail?.contact || "",
          contact_id: primaryEmail ? primaryEmail.contact_id : null,
          contact_type: "email",
          is_primary: 1,
        });
        setAlternateEmail1({
          contact: alternateEmail1?.contact || "",
          contact_id: alternateEmail1?.contact_id || null,
          contact_type: "email",
          is_primary: 0,
        });
        setalternateemail2({
          contact: alternateEmail2?.contact || "",
          contact_id: alternateEmail2?.contact_id || null,
          contact_type: "email",
          is_primary: 0,
        });
        setMobile({
          contact: primaryMobile?.contact || "",
          contact_id: primaryMobile?.contact_id || null,
          contact_type: "mobile",
          is_primary: 1,
        });
        setalternatemobile({
          contact: alternateMobile?.contact || "",
          contact_id: alternateMobile?.contact_id || null,
          contact_type: "mobile",
          is_primary: 0,
        });
      })
      .catch((error) => {});
  }, [isopen, open, isedited]);

  useEffect(() => {
    // Fetch highest education options
    axios
      .get(process.env.REACT_APP_JOBEZEURL + `/highest-education`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        setHighEducationOptions(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching highest education options:", error);
      });

    // Fetch work authorization options
    axios
      .get(process.env.REACT_APP_JOBEZEURL + `/work-authorization`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        setWorkAuthorizationOptions(response.data.message);
      })
      .catch((error) => {
        // console.error("Error fetching work authorization options:", error);
      });
  }, [isopen]);
  // // console.log("himanshuuuuuuuuuu",alternateemail1)
  // let filename = attachments.map((attachment) => {
  // let displayFilename = "";
  //   if (filename) {
  //       let parts = filename.split(".");
  //       let extension = parts.pop(); // Last dot ke baad wala part extract karna
  //       let nameWithoutExtension = parts.join("."); // Bina extension ke naam
  //       if (nameWithoutExtension.length > 16) {
  //           displayFilename = nameWithoutExtension.substring(0, 16) + "..." + extension;
  //       } else {
  //           displayFilename = filename;
  //       }
  //   }

  // console.log(displayFilename);
  // })

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        disabled={!isDataChanged}
        sx={{ mt: 2 }}
      >
        Upload Attachment
      </Button>

      <Box>
        <Box
          display="flex"
          flexDirection={"row"}
          flexWrap={"wrap"}
          py={2}
          gap={
            window.location.pathname.includes(
              `/Manage-Candidates/${candidateId}`
            )
              ? 3.5
              : 1.5
          }
        >
          {/* Display attachments */}
          {attachments.map((attachment) => (
            <>
              <Box
                position="relative"
                borderRadius={2}
                backgroundColor="#3F95EA0F;"
                // border={1}
                width={
                  window.location.pathname.includes(
                    `/Manage-Candidates/${candidateId}`
                  )
                    ? "max-content"
                    : " 100%"
                }
              >
                <Box
                  key={attachment.id}
                  display="flex"
                  alignItems="center"
                  p={1}
                >
                  <InsertDriveFileOutlinedIcon />
                  <Box
                    ml={2}
                    flexGrow={1}
                    display="flex"
                    alignItems="center"
                    position="relative"
                  >
                    {attachment.attachment_type &&
                      attachment.attachment_type !== "Other" && (
                        <Typography
                          sx={{
                            position: "absolute",
                            top: "-22px",
                            padding: "0.5px 3px 0.5px 3px",
                            backgroundColor: "white",
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#3F95EA",
                          }}
                        >
                          {attachment.attachment_type}
                        </Typography>
                      )}

                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body1"
                        title={attachment.original_filename}
                        sx={{
                          textTransform: "capitalize",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width: window.location.pathname.includes(
                            `/Manage-Candidates/${candidateId}`
                          )
                            ? "200px"
                            : "100%",
                          color: "#000000D9",
                        }}
                      >
                        {(() => {
                          let filename = attachment.original_filename;
                          let displayFilename = "";

                          if (filename) {
                            let parts = filename.split(".");
                            let extension = parts.pop();
                            let nameWithoutExtension = parts.join(".");
                            if (nameWithoutExtension.length > 16) {
                              displayFilename =
                                window.location.pathname.includes(
                                  `/Manage-Candidates/${candidateId}`
                                )
                                  ? nameWithoutExtension.substring(0, 16) +
                                    "..." +
                                    extension
                                  : filename.length > 25
                                  ? nameWithoutExtension.substring(0, 60) +
                                    "..." +
                                    extension
                                  : filename;
                            } else {
                              displayFilename = filename;
                            }
                          }

                          return <span>{displayFilename}</span>;
                        })()}
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={1}>
                    <LightTooltip
                      arrow
                      title={
                        <React.Fragment>
                          <Typography
                            sx={{
                              typography: "subtitle2",
                              color: "#000000A6",
                            }}
                            variant="body2"
                            color="inherit"
                          >
                            {attachment.description}
                          </Typography>
                          <Typography
                            display={"flex"}
                            gap={2}
                            sx={{
                              typography: "subtitle2",
                              color: "#000000A6",
                            }}
                          >
                            <Typography
                              variant="body2"
                              color="#00000040"
                              sx={{ textTransform: "capitalize" }}
                            >
                              Date:{" "}
                              {new Date(
                                attachment.date_created
                              ).toLocaleDateString()}
                            </Typography>
                            <Typography variant="body2" color="#00000040">
                              By: {attachment.created_by}
                            </Typography>
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon sx={{ ml: 1 }} />
                    </LightTooltip>
                  </Box>
                  <IconButton
                    onClick={() => handleDownload(attachment.stored_filename)}
                    color="primary"
                  >
                    <Typography variant="body2" color={"primary"}>
                      Download
                    </Typography>
                  </IconButton>
                  <IconButton
                    onClick={() => openDeleteDialog(attachment)}
                    color="error"
                    disabled={!isDataChanged}
                    sx={{
                      color: isDataChanged ? "error.main" : "grey",
                      "&.Mui-disabled": {
                        color: "grey",
                      },
                    }}
                  >
                    <Typography
                      variant="body2"
                      color={isDataChanged ? "error" : "inherit"}
                    >
                      Delete
                    </Typography>
                  </IconButton>
                </Box>
              </Box>
            </>
          ))}
          <Dialog open={openDialog} onClose={closeDeleteDialog}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this attachment?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteDialog} color="error">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>

      <div>
        <Box sx={{ mb: 5 }}>
          <h4>Basic Details</h4>
          <Grid container spacing={2}>
            <Grid item xs={4} sx={{ mt: 1 }}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
              >
                Name
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="fullname"
                size="small"
                fullWidth
                label="Full Name"
                variant="outlined"
                value={fullname}
                onFocus={(e) => setFullnameError("")}
                onChange={handleFullname}
                error={!!fullnameError}
                helperText={fullnameError}
                ref={errorFieldRefs.fullname}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Gender
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl size="small" fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  {" "}
                  Gender{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender}
                  size="small"
                  label="Gender"
                  onChange={handleGender}
                >
                  <MenuItem value="M">Male</MenuItem>
                  <MenuItem value="F">Female</MenuItem>
                  <MenuItem value="O">Prefer not to respond</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Date of Birth
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth"
                  maxDate={eighteenYearsAgo}
                  value={selectedDate ? dayjs(selectedDate) : null}
                  onChange={(date) => handleDateChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Email
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="email"
                size="small"
                type="email"
                fullWidth
                label="Email"
                disabled
                variant="outlined"
                value={email?.contact}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Alternate Email 1
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="email"
                size="small"
                type="email"
                fullWidth
                label="Alternate Email 1"
                variant="outlined"
                value={alternateemail1 ? alternateemail1.contact : ""}
                onChange={handleSecondaryemail}
                error={!!AlternateEmail1Error}
                helperText={AlternateEmail1Error}
                onFocus={(e) => setAlternateEmail1Error("")}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Alternate Email 2
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="email"
                size="small"
                type="email"
                fullWidth
                label="Alternate Email 2"
                variant="outlined"
                value={alternateemail2 ? alternateemail2.contact : ""}
                onChange={handlealternateemail2}
                error={!!AlternateEmail2Error}
                helperText={AlternateEmail2Error}
                onFocus={(e) => setAlternateEmail2Error("")}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Mobile
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="Mobile"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PlusOneIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                label="Mobile Number"
                variant="outlined"
                value={mobile ? mobile.contact : ""}
                onChange={handleMobile}
                type="tel"
                placeholder="Enter mobile number"
                helperText={mobileError}
                error={!!mobileError}
                ref={errorFieldRefs.mobile}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Alternate Mobile
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="Mobile"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PlusOneIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                label="Mobile Number"
                variant="outlined"
                value={alternatemobile ? alternatemobile.contact : ""}
                onChange={handlealternateMobile}
                type="tel"
                placeholder="Enter Alternate mobile number"
                helperText={AlternateMobileError}
                error={!!AlternateMobileError}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Current Address
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="currentAddress"
                label="Current Address"
                multiline
                size="small"
                fullWidth
                // rows={4}
                value={currentAddress}
                onChange={handleCurrentAddress}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Work Authorization
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl size="small" fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  {" "}
                  Work Authorization{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedworkAuthorization.work_authorization || ""}
                  size="small"
                  label="Highest Education"
                  onChange={handleworkAuthorization}
                >
                  {workAuthorizationOptions.map((options) => (
                    <MenuItem
                      className="fw-bold"
                      key={options.work_authorization_id}
                      value={options.work_authorization}
                    >
                      {options.work_authorization}
                    </MenuItem>
                  ))}
                </Select>
                {(selectedworkAuthorization.work_authorization === "Others" ||
                  isOtherSelected) && (
                  <TextField
                    id="otherWorkauthorization"
                    size="small"
                    fullWidth
                    label="Other Work Authorization"
                    value={customWorkAuthorization}
                    onChange={handleCustomWorkAuthorizationChange}
                    margin="normal"
                    error={customeworkError}
                    helperText={
                      customeworkError ? "This field is required" : ""
                    }
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 5 }}>
          <h4>Professional Details</h4>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Total Experience
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              sx={{ display: "flex", alignItems: "center", gap: 2 }}
            >
              <TextField
                id="years"
                size="small"
                fullWidth
                value={years}
                label="Years"
                variant="outlined"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  // Check if the input is empty or a valid number within the range [0, 40]
                  if (
                    inputValue === "" ||
                    /^0$/.test(inputValue) ||
                    (/^[1-9]\d*$/.test(inputValue) &&
                      parseInt(inputValue) >= 1 &&
                      parseInt(inputValue) <= 40)
                  ) {
                    setYears(inputValue);
                    setTempArray({ ...tempArray, year: inputValue });
                    // Check if inputValue is equal to 40
                    if (parseInt(inputValue) === 40) {
                      setMonths(""); // Set months to an empty string
                    }
                  }

                  // If not, you can set an error message or take another action
                  else {
                    // You can set an error state or display an error message to the user.
                    // For this example, I'll set an error message in the state:
                  }
                }}
              />
              <FormControl size="small" fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  {" "}
                  Months{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={months}
                  size="small"
                  label="Months"
                  onChange={handleMonths}
                  disabled={years === "40"}
                >
                  {monthss.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Current Employer
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="currentEmployer"
                size="small"
                fullWidth
                label="Current Employer"
                variant="outlined"
                value={currentEmployer}
                onChange={(e) => {
                  if (e.target.value.length <= 60) {
                    setCurrentEmployer(e.target.value);
                    setTempArray({
                      ...tempArray,
                      current_employer: e.target.value,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Current Salary
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-amount">
                  Current Salary
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  label="Current Salary"
                  placeholder="Current Salary"
                  size="small"
                  value={currentSalary}
                  onChange={handleCurrentSalary}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Expected Salary
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-amount">
                  Expected Salary
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  label="Expected Salary"
                  placeholder="Expected Salary"
                  size="small"
                  value={expectedSalary}
                  onChange={handleExpectedSalary}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Current Location
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                fullWidth
                value={getCity}
                // name={Math.random().toString(36).replace(/[^a-z]+/g, '')}
                size="small"
                onInputChange={(event, newInputValue) => {
                  if (newInputValue.length >= 3) {
                    setTimeout(() => {
                      setCityLoc(newInputValue);
                    }, 100);
                  } else {
                    setCityLoc("");
                    // Clear the options or perform any other desired action
                  }
                }}
                onChange={handleCitySelect}
                id="controllable-states-demo"
                options={
                  (currrentLocation &&
                    currrentLocation?.map((ele) => ele.city)) ||
                  []
                }
                renderInput={(params) => (
                  <TextField {...params} label="Current Location" />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 2 }}
              >
                Preferred Location
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                multiple
                value={preferredArr}
                disabled
                // onChange={handleLocationChange}
                // onInputChange={(event, newInputValue) => {
                //   hanldePreferredInputChange(newInputValue);
                // }}
                options={preferredArr}
                renderTags={(value, getTagProps) =>
                  value?.map((option, index) => (
                    <Chip
                      variant="filled"
                      {...getTagProps({ index })}
                      label={option.label}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder={
                      preferredArr?.length > 0 ? "" : "Preferred Location"
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Notice Period
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                fullWidth
                label="Notice Period"
                value={NoticePeriod}
                onChange={handleNoticePeriod}
                id="outlined-start-adornment"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">Days</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Last working Day
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Last Working Day"
                  value={lastworkingday ? dayjs(lastworkingday) : null}
                  onChange={(date) => handlelastworkingday(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Expected Joining Date
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Expected Joining Date"
                  // maxDate={eighteenYearsAgo}
                  minDate={dayjs()}
                  value={DateAvailable ? dayjs(DateAvailable) : null}
                  onChange={(date) => handleDateAvailable(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      readOnly: true,
                      size: "small",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 5 }}>
          <h4>Other Details</h4>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Highest Education
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl size="small" fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  {" "}
                  Highest Education{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={highEducation == 1 ? null : highEducation}
                  size="small"
                  label="Highest Education"
                  onChange={handleHighEducation}
                >
                  {HighEducationOptions.map((options) => (
                    <MenuItem
                      className="fw-bold"
                      value={options.highest_education_id}
                    >
                      {options.highest_education}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Linkedin
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="linkedin"
                size="small"
                fullWidth
                label="LinkedIn URL"
                variant="outlined"
                value={linkedin}
                onChange={handleLinkedin}
                error={Boolean(linkedinError)}
                helperText={linkedinError}
                onFocus={() => {
                  setLinkedinError("");
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 1 }}
              >
                Portfolio Link
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="portfolio"
                size="small"
                fullWidth
                label="Portfolio URL"
                placeholder="Portfolio Link/Personal Website"
                variant="outlined"
                value={portfolio}
                error={portfolioError}
                helperText={profileErrorHelperText}
                onChange={handlePortfolio}
                onFocus={() => {
                  setPortfolioError("");
                  setPortfolioErrorHelperText("");
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                color="text.secondary"
                className="fw-500"
                sx={{ mt: 3 }}
              >
                Miscellaneous Notes
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="Miscellaneous"
                size="small"
                fullWidth
                label="Miscellaneous Notes"
                placeholder="Miscellaneous Notes"
                variant="outlined"
                rows={Miscellaneous?.length > 85 ? 15 : 3}
                multiline
                value={Miscellaneous}
                onChange={handlemiscellaneous}
                error={!!miscellaneousError}
                helperText={miscellaneousError}
                onFocus={() => {
                  setMiscellaneousError("");
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"flex-end"}
              mt={2}
            >
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle>
                    Attachment
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    {!attachment ? (
                      <div
                        style={{
                          border: "2px dashed #ccc",
                          padding: "20px",
                          textAlign: "center",
                          marginTop: "20px",
                        }}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                      >
                        <CloudUploadIcon
                          style={{ fontSize: 50, color: "#0F91D2" }}
                        />
                        <Typography variant="h6">
                          Select a file here or drop it here
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            marginRight: "4px",
                            gap: "3px",
                          }}
                        >
                          File size not more than
                          <Typography variant="body2" color={"error"}>
                            {" "}
                            10MB*
                          </Typography>
                        </Typography>
                        <Button
                          variant="contained"
                          component="label"
                          style={{ marginTop: 10 }}
                        >
                          Select File
                          <input
                            type="file"
                            hidden
                            onChange={handleFileUpload}
                          />
                        </Button>
                        {fileError && (
                          <Typography py={2} color="error">
                            {fileError}
                          </Typography>
                        )}
                        {selectedFile && (
                          <div>
                            <Typography variant="body2">
                              File Name: {fileName}
                            </Typography>
                            <Typography variant="body2">
                              Upload Date: {uploadDate}
                            </Typography>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div style={{ padding: "50px" }}>
                        <Typography
                          variant="body1"
                          style={{ marginTop: "5px", fontWeight: "bold" }}
                        >
                          Attachment File
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <InsertDriveFileOutlinedIcon
                              style={{ marginRight: "10px" }}
                            />
                            <Typography
                              title={attachment.name}
                              sx={{
                                textTransform: "capitalize",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                width: "500px",
                              }}
                              variant="body1"
                            >
                              {attachment.name}
                            </Typography>
                          </div>
                          <Button
                            onClick={handleDeleteAttachment}
                            color="error"
                            disabled={!isDataChanged}
                          >
                            Delete
                          </Button>
                        </div>

                        <div>
                          {uploadProgress > 0 && (
                            <div style={{ marginTop: "20px" }}>
                              <LinearProgress
                                variant="determinate"
                                value={uploadProgress}
                              />
                              <Typography
                                variant="body2"
                                color="textSecondary"
                              >{`${uploadProgress}%`}</Typography>
                            </div>
                          )}
                        </div>
                        <Divider style={{ margin: "20px 0" }} />
                        <div
                          style={{
                            marginBottom: "20px",
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "bold",
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: "bold",
                              paddingRight: "30px",
                            }}
                          >
                            Document Type
                          </Typography>
                          <FormControl
                            variant="outlined"
                            style={{ minWidth: 600 }}
                          >
                            <Select
                              value={attachmentsType}
                              onChange={handleAttachmentTypeChange}
                            >
                              {/* <MenuItem value="">any default Value which you want to show</MenuItem> */}
                              {Object.keys(attachment_type).map((key) => (
                                <MenuItem key={key} value={key}>
                                  {attachment_type[key]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              marginRight: "20px",
                              fontWeight: "bold",
                              paddingRight: "40px",
                            }}
                          >
                            Description
                          </Typography>
                          <TextField
                            fullWidth
                            margin="normal"
                            placeholder="Description"
                            value={description}
                            onChange={handleDescriptionChange}
                            style={{ flexGrow: 1 }}
                          />
                        </div>
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="error">
                      Cancel
                    </Button>
                    {attachment && (
                      <Button onClick={handleSubmitAttachment} color="primary">
                        Attach
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"flex-end"}
              mt={2}
            >
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={!isDataChanged}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity} // Adjust severity as needed
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default CandidateEditableProfile;
