import React from "react";
import { Sunburst } from "@ant-design/plots";

const customLabel = (_, datum) => {
  return (
    <div
      style={{
        marginLeft:
          datum.name === "Total Active Jobs" ||
          datum.name === "Total Inactive jobs"
            ? "-25px"
            : "",
        width:
          datum.name === "Total Active Jobs" ||
          datum.name === "Total Inactive jobs"
            ? "40px"
            : "",
        fontSize: "12px",
      }}
    >
      {datum.name}
    </div>
  );
};

const SunburstChart = ({ jobCount }) => {
  const config = {
    data: {
      value: {
        name: "Jobs",
        children: [
          {
            name: "Total Active Jobs",
            value: jobCount?.activeJobs?.totalActiveJobs,
            children: [
              {
                name: "Existing Jobs",
                value: jobCount?.activeJobs?.existingJobs,
              },
              {
                name: "New Jobs",
                value: jobCount?.activeJobs?.newJobs,
              },
            ],
          },
          {
            name: "Total Inactive jobs",
            value: jobCount?.inactiveJobs?.totalInactiveJobs,
            children: [
              {
                name: "Closed Jobs",
                value: jobCount?.inactiveJobs?.closedJobs,
              },
              {
                name: "Cancelled Jobs",
                value: jobCount?.inactiveJobs?.cancelledJobs,
              },
            ],
          },
        ],
      },
    },
    animate: {
      enter: { type: "waveIn" },
    },
    innerRadius: 0.5,
    style: {
      fill: (v) => {
        console.log(v.path);
        if (v.path === "Total Active Jobs") return "#27BB2E";
        if (v.path === "Total Inactive jobs") return "#006CB7";
        if (v.path === "Total Active Jobs / Existing Jobs")
          return "rgba(39, 187, 46, 0.40)";
        if (v.path === "Total Active Jobs / New Jobs") return "#4AEC52";
        if (v.path === "Total Inactive jobs / Closed Jobs")
          return "rgba(0, 108, 183, 0.40)";
        if (v.path === "Total Inactive jobs / Cancelled Jobs") return "#3F95EA";
      },
    },
    // label: {
    //   text: 'name',
    //   // transform: [
    //   //   {
    //   //     type: 'overflowHide',
    //   //   },
    //   // ],
    //   // render: customLabel,
    // },
    state: {
      active: { zIndex: 2, stroke: "#fff" },
      inactive: { zIndex: 1, stroke: "#fff" },
    },
    interaction: {
      tooltip: {
        render: (e, { title, items }) => {
          return (
            <div>
              <div>{title}</div>
              <div>{items[0].value}</div>
            </div>
          );
        },
      },
    },
  };
  return <Sunburst {...config} />;
};

export default SunburstChart;
