import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
  token: null,
  refreshToken: null,
  permissions: null,
  isEmployer: false,
  user_id: null,
  role: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getTokenID: (state, action) => {
      return {
        ...state,
        token: action.payload,
      };
    },
    getRefreshToken: (state, action) => {
      return {
        ...state,
        refreshToken: action.payload,
      };
    },
    logoutSuccess(state) {
      return {
        ...state,
        isVerified: undefined,
        token: null,
        role: null,
      };
    },

    permissionStatus(state, action) {
      return {
        ...state,
        permissions: action.payload,
      };
    },

    isEmployerLogin(state, action) {
      return {
        ...state,
        isEmployer: action.payload,
      };
    },
    userEdit(state, action) {
      return {
        ...state,
        ...action.payload,
        user_id: action.payload,
      };
    },
    role(state, action) {
      return {
        ...state,
        role: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});
export const {
  getTokenID,
  getRefreshToken,
  logoutSuccess,
  permissionStatus,
  isEmployerLogin,
  userEdit,
  role,
} = userSlice.actions;
export const selectUser = (state) => state.user;
export const userReducer = userSlice.reducer;
