import React, { useEffect, useState } from "react";
import Header from "../components/header";
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom'; // Import Link for navigation

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(0.5),
}));

const CompanyLists = () => {
  
  const columns = [
    {
      field: "name",
      headerName: "Company Name",
      width: 350,
      renderCell: (params) => (
        <a
          href={`/CompanyDetails/${params.row.id}?tab=companyInfo`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <Typography
            title={params.row.name}
            variant="body1"
            sx={{
              textTransform: "capitalize",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params.row.name ? params.row.name : "NA"}
          </Typography>
        </a>
      ),
    },
    {
      field: "branch_count",
      headerName: "Branch",
      width: 150,
      renderCell: (params) => (
        <a
          href={`/CompanyDetails/${params.row.id}?tab=companyInfo`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          {params.row.is_branch_managed === 0
            ? "NA"
            : params.row.branch_count
              ? params.row.branch_count
              : "NA"}
        </a>
      ),
    },
    {
      field: "city",
      headerName: "City",
      width: 250,
      renderCell: (params) => (
        <a
          href={`/CompanyDetails/${params.row.id}?tab=companyInfo`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          {params.row.city ? params.row.city : "NA"}
        </a>
      ),
    },
    {
      field: "state",
      headerName: "State",
      width: 250,
      renderCell: (params) => (
        <a
          href={`/CompanyDetails/${params.row.id}?tab=companyInfo`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          {params.row.state ? params.row.state : "NA"}
        </a>
      ),
    },
    {
      field: "phone1",
      headerName: "Mobile",
      width: 200,
      renderCell: (params) => (
        <a
          href={`/CompanyDetails/${params.row.id}?tab=companyInfo`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <Typography
            title={params.row.phone1}
            variant="body2"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params.row.phone1 ? "+1 " + params.row.phone1 : "NA"}
          </Typography>
        </a>
      ),
    },
    {
      field: "date_created",
      headerName: "Created On",
      width: 200,
      renderCell: (params) => (
        <a
          href={`/CompanyDetails/${params.row.id}?tab=companyInfo`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          {new Date(params.row.date_created).toLocaleDateString("en-US")}
        </a>
      ),
    },
  ];
  

  const [showLoader, setShowLoader] = useState(false);
  const [companyListRows, setCompanyListRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [queryOptions, setQueryOptions] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [companyId, setCompanyId] = useState(null);
  // const [pageSize, setPageSize] = useState(5);
  const [paginationModel, setPaginationModel] = useState({
    page: Number(searchParams.get("page")) - 1,
    pageSize: 25,
  });

  const navigate = useNavigate();

  const HandleRowClick = (params) => {
    const currentUrl = window.location.href;
    sessionStorage.setItem('previousUrl', currentUrl);
    navigate(`/CompanyDetails/${params.row.id}?tab=companyInfo`);
    window.scrollTo(0, 0);
  };

  const handleSortModelChange = React.useCallback((sortModel) => {
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Update the URL with the search query and reset page to 1
    setSearchParams({ page: 1, search: query });
  };

  const handlePaginationModelChange = (model) => {
    setPaginationModel({ ...paginationModel, ...model });

    // Update the URL with the new page and existing search query
    setSearchParams({
      page: model.page + 1,
      search: searchQuery,
    });
    setQueryOptions({
      ...queryOptions,
      pageSize: model.pageSize,
    })
  };

  const listofComapniesApi = async () => {
    setShowLoader(true);

    // Extract page and search parameters from the URL
    const page = searchParams.get("page") || 1;
    const searchQueryParam = searchParams.get("search") || "";

    // Construct the API URL including the search query
    let apiUrl = `/list-of-companies/${page}`;
    if (searchQueryParam) {
      apiUrl += `?search=${encodeURIComponent(searchQueryParam)}`;
    }

    const payload = {
      pageSize: queryOptions.pageSize ? queryOptions.pageSize : 25,
    };
    if (queryOptions.sortModel && queryOptions.sortModel.length > 0) {
      payload.sortField = queryOptions.sortModel[0].field;
      payload.sortBy = queryOptions.sortModel[0].sort;
    }

    try {
      const response = await axios.post(apiUrl, payload, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
      setCompanyListRows(response.data.message.companyListResponse);
      setTotalCount(response.data.message?.total);
    } catch (error) {
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
      console.log(error);
    }
  };


  useEffect(() => {
    const query = searchParams.get("search") || "";
    const page = Number(searchParams.get("page")) || 1;


    setSearchQuery(query);
    setPaginationModel({
      ...paginationModel,
      page: page - 1
    });

    const fetchData = async () => {
      setShowLoader(true);
      await listofComapniesApi();
    };

    fetchData();
  }, [searchParams, queryOptions]);

  // extract the value for manage_permission from redux store here
  const managePermissions = useSelector((state) => state.user.permissions);
  const AddCompanyPermissions = managePermissions["200"];

  return (
    <Box className="pageWrapper">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          id="standard-search"
          type="search"
          variant="standard"
          placeholder="Search"
          autoComplete="off"
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            width: "25%"
          }}
        />
        {AddCompanyPermissions?.add === 1 && (
          <Button
            startIcon={<AddIcon />}
            // onClick={toggleDrawer(true)}
            onClick={() => {
              navigate(`/addCompany`);
              // console.log("Add Company")
            }}
            variant="contained"
          >
            Add Company
          </Button>
        )}
      </Box>
      {/* <Box sx={{ flexGrow: 1 }}></Box>
        <Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 1 }}
          >
            <Grid item xs={6}>
              <Item sx={{ py: 2.5 }}>
                <TextField
                  id="standard-search"
                  type="search"
                  variant="standard"
                  placeholder="Search"
                  autoComplete="off"
                  value={searchQuery}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    maxWidth: "100%",
                    width: { xs: "100%", sm: "65%", md: "44%", lg: "44%" },
                  }}
                />
              </Item>
            </Grid>
            {AddCompanyPermissions?.add === 1 && (
              <Grid item xs={6}>
                <Item>
                  <Typography
                    align="right"
                    variant="h3"
                    component="h1"
                    gutterBottom
                  >
                    <Button
                      startIcon={<AddIcon />}
                      // onClick={toggleDrawer(true)}
                      onClick={() => {
                        navigate(`/addCompany`);
                        // console.log("Add Company")
                      }}
                      variant="contained"
                    >
                      Add Company
                    </Button>
                  </Typography>
                </Item>
              </Grid>
            )}
          </Grid>
        </Box> */}
      <Box sx={{ py: 2.5 }}>
        <DataGrid
          rows={showLoader ? [] : companyListRows}
          columns={columns}
          autoHeight={showLoader ? true : true}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          loading={showLoader}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[25, 50, 75, 100]}
          pageSize={paginationModel.pageSize}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          rowCount={totalCount}
          onRowClick={HandleRowClick}
          className="data-grid-row-hover"
        />
      </Box>
    </Box>
  );
};

export default CompanyLists;
