import React, { useEffect, useState } from "react";
import Header from "../components/header";
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { userEdit } from "../redux/modules/userSlice";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(0.5),
}));

const UsersLists = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [usersListRows, setUsersListRows] = useState([]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [queryOptions, setQueryOptions] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const [paginationModel, setPaginationModel] = useState({
    page: Number(searchParams.get("page")) - 1,
    pageSize: 25,
  });
  const [showLoading, setShowLoading] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 100,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <Typography variant="body1">{params.row.id}</Typography>
        </a>
      ),
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 300,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <Typography
            variant="body1"
            title={params.row.first_name}
            sx={{
              textTransform: "capitalize",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params.row.first_name ? params.row.first_name : "NA"}
          </Typography>
        </a>
      ),
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 300,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <Typography
            variant="body1"
            sx={{
              textTransform: "capitalize",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params.row.last_name ? params.row.last_name : "NA"}
          </Typography>
        </a>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 350,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <Typography variant="body1">{params.row.email}</Typography>
        </a>
      ),
    },
    {
      field: "companies_associated",
      headerName: "Company Associated",
      width: 200,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <div style={{ textAlign: "center", width: "100%" }}>
            {params.row.companies_associated ? params.row.companies_associated : "NA"}
          </div>
        </a>
      ),
    },
  ];
  
  const HandleRowClick = (params, event) => {
    // console.log(params, "params");
    // console.log(params.row, "params.row");
    const currentUrl = window.location.href;
    sessionStorage.setItem('UserpreviousUrl', currentUrl);
    dispatch(userEdit(params.row.id));
    navigate(`/UsersDetails/${params.row.id}?tab=b_info`);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setSearchParams({ ...searchParams, search: query, page: 1 }); // Reset to page 1 on search
  };


  const handleSortModelChange = React.useCallback((sortModel) => {
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const listofUsersApi = async () => {
    let payload = {};
    setShowLoading(true);
    if (queryOptions.sortModel && queryOptions.sortModel.length > 0) {
      payload.sortField = queryOptions?.sortModel[0].field;
      payload.sortBy = queryOptions?.sortModel[0].sort;
    }
    if(queryOptions.pageSize){
      payload.pageSize = queryOptions.pageSize ? queryOptions.pageSize : 25
    }  
    try {
      const response = await axios.post(
        `list-of-users/${searchParams.get("page")}${
          searchQuery ? "?search=" + searchQuery : ""
        }`,
        payload,
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );
      // console.log(response);
      // console.log(response.data.message.userListResponse);
      setTimeout(() => {
        setShowLoading(false);
      }, 500);
      setTotalCount(response.data.message.total);
      setUsersListRows(response.data.message.userListResponse);
      dispatch(userEdit(null));
    } catch (error) {
      setShowLoading(false);
      console.log(error);
    }
  };

  const handleAddUser = () => {
    navigate("/addUser?tab=basicInfo");
    dispatch(userEdit(null));
  };

  // console.log(paginationModel, "paginationModel");
  const handlePaginationModelChange = (model) => {

    window.scrollTo({ top: 0, behavior: "smooth" });
    setPaginationModel({ ...paginationModel, ...model });
    setSearchParams({ page: model.page + 1 });
    setQueryOptions({
      ...queryOptions,
      pageSize: model.pageSize, 
    })
  };

  useEffect(() => {
    setSearchQuery(searchParams.get("search") || "");
    setPaginationModel({
      page: Number(searchParams.get("page")) - 1 || 0,
      pageSize: queryOptions.pageSize || 25,
    });
  }, [searchParams]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    listofUsersApi();
  }, [searchQuery, queryOptions, paginationModel]);

  return (
    <Box className="pageWrapper">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          id="standard-search"
          type="search"
          variant="standard"
          placeholder="Search"
          autoComplete="off"
          onChange={handleSearch}
          value={searchQuery}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            width: '25%'
          }}
        />
        <Button
          startIcon={<AddIcon />}
          onClick={() => handleAddUser()}
          variant="contained"
        >
          Add User
        </Button>
      </Box>

      {/* <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h3" align="left" component="h1">
            Users
          </Typography>
        </Box>
        <Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 1 }}
          >
            <Grid item xs={6}>
              <Item sx={{ py: 2.5 }}>
                <TextField
                  id="standard-search"
                  type="search"
                  variant="standard"
                  placeholder="Search"
                  autoComplete="off"
                  onChange={handleSearch}
                  value={searchQuery}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    maxWidth: "100%",
                    width: { xs: "100%", sm: "65%", md: "44%", lg: "44%" },
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography
                  align="right"
                  variant="h3"
                  component="h1"
                  gutterBottom
                >
                  <Button
                    startIcon={<AddIcon />}
                    // onClick={toggleDrawer(true)}
                    // onClick={() => {
                    //   navigate("/addUser?tab=basicInfo");
                    //   // console.log("Add Company")
                    // }}
                    onClick={() => handleAddUser()}
                    variant="contained"
                  >
                    Add User
                  </Button>
                </Typography>
              </Item>
            </Grid>
          </Grid>
        </Box> */}
      <Box sx={{ py: 2.5 }}>
        <DataGrid
          rows={showLoading ? [] : usersListRows}
          className="data-grid-row-hover"
          columns={columns}
          pagination
          paginationMode="server"
          loading={showLoading}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[25, 50,75, 100]}
          rowCount={totalCount}
          autoHeight={showLoading ? true : true}
          onSortModelChange={handleSortModelChange}
          onRowClick={HandleRowClick}
        />
      </Box>
    </Box>
  );
};

export default UsersLists;
