import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { styled } from "@mui/material/styles";
import { Container, Box, Grid, Paper, Typography, Button } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ActiveJobs from "../components/activeJobs";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { permissionStatus } from "../redux/modules/userSlice";
// import { PermissionsStatusManage } from "../constants/defaultValue";

// import Cookies from "js-cookie";

// const baseURL = process.env.REACT_APP_BASEURL;
const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(1),
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const JobOrders = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(() => {
    // Check if there is a stored active tab in localStorage
    const storedValue = localStorage.getItem("jobOrdersTab");
    return storedValue ? parseInt(storedValue, 10) : 0;
  });
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ page: 1 });
  };

  useEffect(() => {
    // Store the active tab in localStorage when it changes
    localStorage.setItem("jobOrdersTab", value.toString());
  }, [value]);

  // const handlepostjob = () => {
  //   console.log("himanshu");
  //   navigate("/Postjob");
  // };

  // const PermissionStatus = useSelector((state) => state.user.permissions);
  // const AddJobPermissionStatus = PermissionStatus["400"];
  // console.log(AddJobPermissionStatus, "AddJobPermissionStatus");
  // const dispatch = useDispatch();
  // const isEmployerManage = useSelector((state) => state.user.isEmployer);
  // if (isEmployerManage === true) {
  //   dispatch(permissionStatus(PermissionsStatusManage));
  // }

  // console.log(isEmployerManage,"isEmployerManage");

  // console.log("PermissionStatus",PermissionStatus)
  // console.log("AddJobPermissionStatus",AddJobPermissionStatus)

  return (
    <Box className='pageWrapper'>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* {AddJobPermissionStatus?.add === 1 && (
            <Button
              startIcon={<AddIcon />}
              onClick={handlepostjob}
              variant="contained"
            >
              Post a Job
            </Button>
          )} */}
      </Box>
      {/* <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          >
            <Grid item xs={6}>
              <Typography variant="h3" align="left" component="h1">
                Job Orders
              </Typography>
            </Grid>
            {AddJobPermissionStatus?.add === 1 && (
              <Grid item xs={6}>
                <Typography align="right" variant="h3" component="h1">
                  <div>
                    <Button
                      startIcon={<AddIcon />}
                      // onClick={toggleDrawer(true)}
                      onClick={handlepostjob}
                      variant="contained"
                    >
                      Post a Job
                    </Button>
                    {/* <Createjobdrawer
                      isDrawerOpen={isDrawerOpen}
                      setIsSuccess={setIsSuccess}
                      setDrawerOpen={setDrawerOpen}
                      toggleDrawer={toggleDrawer}
                      isEdit={isEdit}
                    /> */}
      {/* </div>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box> */}
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Active"
              sx={{ letterSpacing: "1.25px", px: 4 }}
              {...a11yProps(0)}
            />
            <Tab
              label="Inactive"
              sx={{ letterSpacing: "1.25px", px: 4 }}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ActiveJobs isActive={1} isSuccess={isSuccess} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ActiveJobs isActive={0} isSuccess={isSuccess} />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default JobOrders;
