import React, { useState } from "react";
import {
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import "../App.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import Header from "../components/header";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
  getTokenID,
  isEmployerLogin,
  permissionStatus,
  role,
} from "../redux/modules/userSlice";
import Cookies from "js-cookie";

// const baseURL = process.env.REACT_APP_BASEURL;
const defaultTheme = createTheme();

const Changepassword = () => {
  const nav = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const getToken = useSelector((state) => state.user.token);
  const getRefreshToken = useSelector((state) => state.user.refreshToken);

  const [password, setPassword] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [oldPassworderror, setoldPassworderror] = useState("");
  const combinedCurrentPasswordError = currentPasswordError || oldPassworderror;

  const dispatch = useDispatch();
  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const [passwordError, setPasswordError] = useState("");
  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;
    setPasswordError("");
    setConfirmPasswordError("");
    setCurrentPasswordError("");

    if (password.length < 6) {
      setPasswordError("Password must be more than 5 letters.");
      isValid = false;
      return;
    }
    if (password === currentPassword) {
      setPasswordError(
        "New password must not be the same as the current password."
      );
      isValid = false;
      return;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match!");
      isValid = false;
      return;
    }
    axios
      .post(
        `change-password`,
        {
          newPassword: confirmPassword,
          oldPassword: currentPassword,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          handleSnackbarOpen("Password Changed Succesfully");
          setTimeout(() => {
            nav("/login");
          }, 1500);
          // Clear localStorage
          setTimeout(() => {
            dispatch(getTokenID(null));
            // dispatch(getRefreshToken(null));
            dispatch(permissionStatus(null));
            dispatch(isEmployerLogin(false));
            dispatch(role(null));
            const d = new Date();
            let expires = "expires=" + d.toUTCString();
            document.cookie =
              "_secure_ARJ_" + "=" + "" + ";" + expires + ";path=/";
            document.cookie =
              "_secure_ref" + "=" + "" + ";" + expires + ";path=/";
            document.cookie =
              "cookiesAccepted" + "=" + "" + ";" + expires + ";path=/";
            window.location.href = "/login";
            localStorage.clear();
          }, 500);
        } else {
          handleSnackbarOpen(response.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        handleSnackbarOpen(err.response.data.error);
        setoldPassworderror(true);
      });
  };
  return (
    <Box
      sx={{
        marginTop: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={
              snackbarMessage === 'Password Changed Succesfully'
                ? 'success'
                : 'error'
            }
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Box sx={{ width: '50%', transform: 'translateY(-50%)' }}>

          <Box component="form" onSubmit={handleSubmit}>
            <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
              <InputLabel size="small" htmlFor="current-password">
                Current Password
              </InputLabel>
              <OutlinedInput
                margin="dense"
                id="current-password"
                size="small"
                type={showCurrentPassword ? 'text' : 'password'}
                value={currentPassword}
                error={!!currentPasswordError || !!combinedCurrentPasswordError}
                onFocus={() => setCurrentPasswordError('')}
                onChange={handleCurrentPasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle current password visibility"
                      onClick={handleClickShowCurrentPassword}
                      edge="end"
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Current Password"
              />
              <FormHelperText error>
                {combinedCurrentPasswordError}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
              <InputLabel size="small" htmlFor="new-password">
                New Password
              </InputLabel>
              <OutlinedInput
                margin="dense"
                id="new-password"
                size="small"
                type={showNewPassword ? 'text' : 'password'}
                value={password}
                error={!!passwordError}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle new password visibility"
                      onClick={handleClickShowNewPassword}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
              <FormHelperText error>{passwordError}</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
              <InputLabel size="small" htmlFor="confirm-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                margin="dense"
                id="confirm-password"
                size="small"
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                error={!!confirmPasswordError}
                onChange={(e) => setConfirmPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
              <FormHelperText error>{confirmPasswordError}</FormHelperText>
            </FormControl>
            <Grid container sx={{ mt: 2 }}>
              <Grid xs>
                <Button
                  href="/forgetpassword"
                  variant="text"
                  sx={{
                    textTransform: 'capitalize',
                    textDecoration: 'underline',
                  }}
                  component="button"
                >
                  Forgot password
                </Button>
              </Grid>
            </Grid>
            <Stack sx={{ width: '100%', my: 4 }} spacing={2}>
              <Alert severity="warning">
                You will be logged out of all active sessions.
              </Alert>
            </Stack>
            <Button
              type="button"
              variant="text"
              sx={{ py: 1 }}
              onClick={() => {
                nav(-1);
              }}
            >
              Go Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ mx: 3, py: 1, px: 5, borderRadius: '50rem' }}
              disabled={
                currentPassword === '' ||
                password === '' ||
                confirmPassword === ''
              }
            >
              Save
            </Button>
          </Box>

        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default Changepassword;
