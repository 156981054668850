import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const DeleteJobDialog = ({
  Deletename,
  id,
  handleClose,
  open,
  setOpenSnackbar,
  setSnackbarMessage,
  setSnackbarServerity,
}) => {
  const [deleteInput, setDeleteInput] = useState("");
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleDeleteInputChange = (event) => {
    const { value } = event.target;
    setDeleteInput(value);
    setDeleteButtonDisabled(value !== Deletename);
  };

  const handleDeleteJob = () => {
    const payload = {
      id: parseInt(id), // Convert the id to integer
      entity:
        searchParams.get("tab") === "companyInfo"
          ? "company"
          : searchParams.get("tab") === "BranchInfo"
            ? "companyBranch"
            : "jobOrder",
    };

    axios
      .post(`/delete-entities`, payload, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSnackbarMessage(response.data.message); //show the message from the backend
          setSnackbarServerity("success");
          setOpenSnackbar(true);
          handleClose(); // Close the dialog after successful deletion
          setTimeout(() => {
            const redirectPath =
              searchParams.get("tab") === "companyInfo" ||
                searchParams.get("tab") === "BranchInfo"
                ? "/Company?page=1"
                : "/jobOrders?page=1";
            navigate(redirectPath);
          }, 600);
        }
      })
      .catch((err) => {
        setSnackbarMessage(err.response.data.error);
        setSnackbarServerity("error");
        setOpenSnackbar(true);
        // Handle error
      });
  };

  const getImgsource = () => {
    if (searchParams.get("tab") === "companyInfo") {
      return (
        <img
          src={"/Company.svg"}
          alt="Delete Icon"
          style={{ display: "block", margin: "0 auto 16px" }}
        />
      );
    } else if (searchParams.get("tab") === "BranchInfo") {
      return (
        <img
          src={"/Company.svg"}
          alt="Delete Icon"
          style={{ display: "block", margin: "0 auto 16px" }}
        />
      );
    } else {
      return (
        <img
          src={"/Job.svg"}
          alt="Delete Icon"
          style={{ display: "block", margin: "0 auto 16px" }}
        />
      );
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          padding: "24px",
          borderRadius: "8px",
          width: "100%",
        },
      }}
    >
      <span
        style={{
          position: "absolute",
          top: "15px",
          right: "40px",
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </span>
      <DialogTitle style={{ fontWeight: 700, textAlign: "center" }}>
        Are you sure, you want to delete{" "}
        <span style={{ color: "#007bff" }}>
          {Deletename} {"?"}
        </span>
      </DialogTitle>
      <DialogContent>
        {getImgsource()}
        <Typography
          style={{
            color: "black",
            background: "#FFE1E1",
            padding: "10px 20px",
            marginTop: "24px",
            borderRadius: "10px",
          }}
        >
          <Typography
            style={{
              fontWeight: 500,
              color: "#FF4D4F",
              marginBottom: "16px",
            }}
          >
            {searchParams.get("tab") === "companyInfo"
              ? "If you delete this Company"
              : searchParams.get("tab") === "BranchInfo"
                ? "If you delete this Branch"
                : "If you delete this Job"}
          </Typography>
          {searchParams.get("tab") === "companyInfo" && (
            <>
              <Typography
                style={{
                  letterSpacing: "0.8px",
                  marginBottom: "5px",
                  fontSize: "14px",
                  paddingLeft: "16px",
                  textIndent: "-12px",
                }}
              >
                • Deleting this company will remove all its associated branches
                permanently.
              </Typography>
              <Typography
                style={{
                  letterSpacing: "0.8px",
                  marginBottom: "5px",
                  fontSize: "14px",
                  paddingLeft: "16px",
                  textIndent: "-12px",
                }}
              >
                • All active and inactive jobs under this company will be
                deleted.
              </Typography>
              <Typography
                style={{
                  letterSpacing: "0.8px",
                  marginBottom: "5px",
                  fontSize: "14px",
                  paddingLeft: "4px",
                  paddingLeft: "16px",
                  textIndent: "-12px",
                }}
              >
                • Employee records associated with this company will be
                permanently erased.
              </Typography>
            </>
          )}
          {searchParams.get("tab") === "BranchInfo" && (
            <>
              <Typography
                style={{
                  letterSpacing: "0.8px",
                  marginBottom: "5px",
                  fontSize: "14px",
                  paddingLeft: "16px",
                  textIndent: "-12px",
                }}
              >
                <span> •</span> All active and inactive jobs under this branch
                will be deleted.
              </Typography>
              <Typography
                style={{
                  letterSpacing: "0.8px",
                  marginBottom: "5px",
                  fontSize: "14px",
                  paddingLeft: "16px",
                  textIndent: "-12px",
                }}
              >
                • Employee records associated with this branch will be
                permanently erased.
              </Typography>
            </>
          )}
          {!searchParams.get("tab") && (
            <Typography
              style={{
                letterSpacing: "0.8px",
                marginBottom: "5px",
                fontSize: "14px",
                paddingLeft: "16px",
                textIndent: "-12px",
              }}
            >
              • All records related to this Job will be deleted.
            </Typography>
          )}
        </Typography>
        <Typography style={{ margin: "16px 0" }}>
          <b style={{ color: "red" }}>＊</b> Please type <b>{Deletename}</b> to
          confirm.
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          label={
            searchParams.get("tab") === "companyInfo"
              ? "Company Name"
              : searchParams.get("tab") === "BranchInfo"
                ? "Branch Name"
                : "Job Name"
          }
          type="text"
          fullWidth
          value={deleteInput}
          onChange={handleDeleteInputChange}
        />
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "center",
          display: "flex",
          gap: "30px",
          padding: "0 25px",
        }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          fullWidth
          sx={{
            textTransform: "capitalize",
            fontWeight: 700,
            borderRadius: "8px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDeleteJob}
          variant="contained"
          color="error"
          disabled={deleteButtonDisabled}
          fullWidth
          sx={{
            textTransform: "capitalize",
            fontWeight: 700,
            borderRadius: "8px",
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteJobDialog.propTypes = {
  Deletename: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpenSnackbar: PropTypes.func.isRequired,
  setSnackbarMessage: PropTypes.func.isRequired,
  setSnackbarServerity: PropTypes.func.isRequired,
};

export default DeleteJobDialog;
