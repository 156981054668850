import React, { useEffect, useState } from "react";
import "./header.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useLocation } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { ListItemIcon } from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import {
  getRefreshToken,
  getTokenID,
  isEmployerLogin,
  permissionStatus,
  role,
} from "../redux/modules/userSlice";
import { useDispatch, useSelector } from "react-redux";

const settings = [
  { label: "Change Password", icon: <LockOpenIcon />, link: "/changepassword" },
  { label: "Logout", icon: <LogoutIcon />, link: "/login" },
];

const headerText = {
  dashboard: "Dashboard",
  mydashboard: "Dashboard",
  jobOrders: "Jobs",
  Postjob: "New Job",
  Company: "Companies",
  CompanyDetails: "Company Details",
  addCompany: "New Company",
  Users: "Users",
  addUser: "New User",
  "Find-talents": "Find Talent",
  searchCandidate: "Find Talent",
  "Manage-Candidates": "Manage Candidates",
  changepassword: "Change Password",
};

const getRandomColor = () => {
  const getRandomHexDigit = () => {
    return Math.floor(Math.random() * 16).toString(16);
  };

  return `#${getRandomHexDigit()}${getRandomHexDigit()}${getRandomHexDigit()}${getRandomHexDigit()}${getRandomHexDigit()}${getRandomHexDigit()}`;
};

const Header = () => {
  const isEmployer = useSelector((state) => state.user.isEmployer);
  const roles = useSelector((state) => state.user.role);
  const storedColor = localStorage.getItem("userColor");
  const initialRandomColor = storedColor || getRandomColor();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(getTokenID(null));
    dispatch(getRefreshToken(null));
    dispatch(permissionStatus(null));
    dispatch(isEmployerLogin(false));
    dispatch(role(null));
    const d = new Date();
    let expires = "expires=" + d.toUTCString();
    document.cookie = "_secure_ARJ_" + "=" + "" + ";" + expires + ";path=/";
    document.cookie = "_secure_ref" + "=" + "" + ";" + expires + ";path=/";
    document.cookie = "cookiesAccepted" + "=" + "" + ";" + expires + ";path=/";
    handleCloseUserMenu();
    window.location.href = "/login";
    sessionStorage.clear();
  };

  const [randomColor, setRandomColor] = useState(initialRandomColor);

  useEffect(() => {
    if (!storedColor) {
      localStorage.setItem("userColor", randomColor);
    }
  }, [randomColor, storedColor]);

  const handleHeaderText = () => {
    const { pathname, search } = window.location;
    const pathArr = pathname.split("/").filter(Boolean);
    const mainPath = pathArr[0];
    const isEditing = search.includes("edit");
    const isSubPath = pathArr.length > 1;
    const lastPath = pathArr[pathArr.length - 1];

    const editHeaders = {
      Postjob: "Edit Job",
      addUser: "Edit User",
      addCompany: "Edit Company Details",
    };

    if (!isSubPath) {
      return isEditing
        ? editHeaders[mainPath] || "Edit Company"
        : headerText[mainPath];
    }

    if (mainPath === "jobOrders") return "Job Details";
    if (lastPath === "addBranch")
      return isEditing ? "Edit Branch Details" : "Add Branch";

    const detailHeaders = {
      CompanyDetails: "Company Details",
      UsersDetails: "User Details",
      "Manage-Candidates": "Candidate Details",
    };

    return detailHeaders[mainPath] || headerText[mainPath] || "JOBEZE";
  };
  return (
    <AppBar color="inherit" position="static">
      <Container maxWidth="xxl" sx={{ p: 2.5 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{ color: "#151D48", fontSize: "24px", fontWeight: "500" }}
          >
            {handleHeaderText()}
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Avatar
                alt={localStorage.getItem("email_id")}
                src="/static/images/avatar/2.jpg"
                variant="rounded"
                sx={{ bgcolor: randomColor, width: 48, height: 48 }}
              />{" "}
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{
                    px: 1,
                    py: 0.2,
                    cursor: "pointer",
                    textTransform: "capitalize",
                    borderRadius: "0",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#151D48",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {localStorage.getItem("email_id")}
                  </Typography>
                  <KeyboardArrowDownIcon sx={{ ml: 1 }} />
                </IconButton>
                <Typography
                  sx={{
                    color: "#737791",
                    fontSize: "12px",
                    mx: 1,
                  }}
                >
                  {roles}
                </Typography>
              </Box>
            </Box>
            <Menu
              sx={{
                mt: "45px",
                "& .MuiPaper-root": {
                  borderRadius: "0px 0px 20px 20px",
                  top: "42px !important",
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                sx={{
                  cursor: "default",
                  ":hover": { backgroundColor: "white" },
                  pointerEvents: "none",
                  marginBottom: "8px",
                }}
              >
                <ListItemIcon>
                  <Person2OutlinedIcon />
                </ListItemIcon>
                {/* {localStorage.getItem("username") && isEmployer === true
                  ? "Employer"
                  : "User"} */}
                {roles}
              </MenuItem>
              <MenuItem
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  cursor: "default",
                  ":hover": { backgroundColor: "white" },
                  pointerEvents: "none",
                  marginBottom: "14px",
                }}
              >
                <ListItemIcon>
                  <MailOutlineOutlinedIcon />
                </ListItemIcon>
                <Typography textAlign="start">
                  <div className="fs-14  ">
                    {localStorage.getItem("username")}
                  </div>
                </Typography>
              </MenuItem>
              {settings.map((setting, index) => (
                <MenuItem
                  sx={{ marginBottom: "8px" }}
                  key={index}
                  component={Link}
                  to={setting.link}
                  onClick={
                    setting.label === "Logout"
                      ? handleLogout
                      : handleCloseUserMenu
                  }
                >
                  <ListItemIcon>{setting.icon}</ListItemIcon>
                  <Typography textAlign="center">{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
        {/* </Toolbar> */}
      </Container>
    </AppBar>
  );
};

export default Header;
