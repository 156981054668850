import { Box, FormControl, IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";

const BasicInfo = ({
  formValues,
  handleInputChane,
  formErrors,
  handleFocus,
  handleClickShowPassword,
  handleClickShowConfirmPassword,
  showConfirmPassword,
  showPassword,
}) => {

  const user_id = useSelector((state) => state.user.user_id);

  return (
    <FormControl fullWidth>
      <Box display={"flex"} flexDirection={"column"} gap={2.5}>
        <Box display={"flex"} justifyContent={"space-between"} gap={2}>
          <TextField
            name="firstName"
            variant="outlined"
            size="small"
            placeholder="First Name"
            fullWidth
            autoComplete="off"
            autoSave="off"
            value={formValues.firstName}
            onChange={handleInputChane}
            onFocus={handleFocus}
            error={!!formErrors.firstName}
            helperText={formErrors.firstName}
            required
            InputProps={{
              inputProps: {
                maxLength: 60
              }
            }}
          />
          <TextField
            name="lastName"
            placeholder="Last Name"
            variant="outlined"
            size="small"
            fullWidth
            autoComplete="off"
            autoSave="off"
            value={formValues.lastName}
            onChange={handleInputChane}
            onFocus={handleFocus}
            error={!!formErrors.lastName}
            helperText={formErrors.lastName}
            required
            InputProps={{
              inputProps: {
                maxLength: 60
              }
            }}
          />
        </Box>
        <Box>
          <TextField
            name="email"
            placeholder="Email"
            variant="outlined"
            size="small"
            fullWidth
            autoComplete="off"
            autoSave="off"
            value={formValues.email}
            onChange={handleInputChane}
            onFocus={handleFocus}
            error={!!formErrors.email}
            helperText={formErrors.email}
            required
            InputProps={{
              inputProps: {
                maxLength: 90
              }
            }}
          />
        </Box>
        {!user_id && (
          <Box display={"flex"} justifyContent={"space-between"} gap={2}>
            <TextField
              name="password"
              variant="outlined"
              size="small"
              placeholder="Password"
              fullWidth
              autoComplete="off"
              autoSave="off"
              type={showPassword ? "text" : "password"}
              value={formValues.password}
              onChange={handleInputChane}
              onFocus={handleFocus}
              error={!!formErrors.password}
              helperText={formErrors.password}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                inputProps: {
                  maxLength: 60
                }
              }}
            />
            <TextField
              name="confirmPassword"
              placeholder="Confirm Password"
              variant="outlined"
              size="small"
              fullWidth
              autoComplete="off"
              autoSave="off"
              type={showConfirmPassword ? "text" : "password"}
              value={formValues.confirmPassword}
              onChange={handleInputChane}
              onFocus={handleFocus}
              error={!!formErrors.confirmPassword}
              helperText={formErrors.confirmPassword}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                inputProps: {
                  maxLength: 60
                }
              }}
            />
          </Box>
        )}
      </Box>
    </FormControl>
  );
};

export default BasicInfo;
