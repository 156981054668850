import axios from "axios";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTokenID, permissionStatus } from "../redux/modules/userSlice";
import Cookies from "js-cookie";

const baseURL = process.env.REACT_APP_BASEURL;
axios.defaults.baseURL = `${baseURL}`;

const useAxiosInterceptor = () => {
  const dispatch = useDispatch();

  const EmployerStatus = useSelector((state) => state.user.isEmployer);
  // console.log(EmployerStatus, "EmployerStatus");

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (
          error.response.status === 401 &&
          !originalRequest._retry &&
          originalRequest.url !== "refresh"
        ) {
          originalRequest._retry = true;
          try {
            // Request to refresh the token
            const response = await axios.get("refresh", { withCredentials: true });

            // Check if the refresh was successful
            if (
              response.status === 200 &&
              response.data &&
              response.data.message.token
            ) {
              // Get the new access token from the response
              const newToken = response.data.message.token;
              const ManagePermissions = response.data.message?.manage_permissions;
              if (!EmployerStatus){
                // dispatch(getTokenID(newToken));
                dispatch(permissionStatus(ManagePermissions));
              }

              // Update Redux state with new token
              dispatch(getTokenID(newToken));
              

              // Set the new token in cookies
              const d = new Date();
              d.setTime(d.getTime() + 2 * 24 * 60 * 60 * 1000);
              const expires = "expires=" + d.toUTCString();
              document.cookie =
                "_secure_ARJ_" + "=" + newToken + ";" + expires + ";path=/";

              // Retry the original request with the new token
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              return axios(originalRequest);
            }
          } catch (err) {
            console.error("Error refreshing token", err);
          }
        }

        // If the refresh token request fails, handle it accordingly
        // Uncomment the following block if you want to redirect to the login page on failure
        /*
        if (error.response.status === 401) {
          // Clear local storage and cookies
          localStorage.clear();
          const d = new Date();
          const expires = "expires=" + d.toUTCString();
          document.cookie = "_secure_ARJ_=;" + expires + ";path=/";
          document.cookie = "_secure_ref=;" + expires + ";path=/";
          document.cookie = "cookiesAccepted=;" + expires + ";path=/";

          // Redirect to the login page
          window.location.href = "/login";
        }
        */

        return Promise.reject(error);
      }
    );

    // Cleanup function to remove the interceptor when the component unmounts
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [dispatch, EmployerStatus]);
};

export default useAxiosInterceptor;
